import { useParams, useSearchParams } from 'react-router-dom'
import { useMemo } from 'react'

export function useAuthData() {
	const { email, company } = useParams<{ email: string; company: string }>()
	const [searchParams] = useSearchParams()

	const initialValues = useMemo(
		() => ({
			company: searchParams.get('companyName') || '',
			name: '',
			email: email || localStorage.getItem('email') || '',
			password: '',
			validation: !email && !localStorage.getItem('email'),
		}),
		[email, company],
	)

	return {
		email,
		company,
		initialValues,
	}
}
