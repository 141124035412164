import { styled, Typography } from '@mui/material'

export const StyledLineClamp = styled(Typography, { name: 'StyledLineClamp' })({
	fontFamily: 'GilroyMedium',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: '-webkit-box',
	WebkitLineClamp: 3,
	WebkitBoxOrient: 'vertical',
})
