import { RequestService } from '@Services'
import type { RegistrationData } from '../typings'
import type { User } from '@Types'
import { StoreUser, storeUserActionCreator } from '@State'
import { URLS } from '@Utils'

export async function registrationEffect(
	body: RegistrationData,
): Promise<{ status: 'success' | 'error'; action: StoreUser }> {
	const response = await RequestService.post<RegistrationData, User>(URLS.registration, {
		...body,
		host: window.location.origin,
	})

	if (response.status === 200) {
		const result = response.data
		localStorage.setItem('user', JSON.stringify(result))
		return { action: storeUserActionCreator(result), status: 'success' }
	}

	return { action: storeUserActionCreator(), status: 'error' }
}
