import { PublicClientApplication } from "@azure/msal-browser";
import { useCallback } from "react";

export function useMsalLogout(mobile: boolean, msalInstance: PublicClientApplication) {
    const msalLogout = useCallback(async () => {
        if (mobile) {
            await msalInstance.logoutRedirect();
        } else {
            await msalInstance.logoutPopup();
            localStorage.removeItem('user')
        }
    }, [mobile, msalInstance])

    return msalLogout
}
