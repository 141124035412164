import { Field, useFormikContext } from 'formik'
import { TextField } from '@Components'
import { Box, Typography } from '@mui/material'
import type { DownloadInvoiceContext } from '../DownloadInvoiceDialog'

export function AddressField() {
	const { errors, touched, values } = useFormikContext<DownloadInvoiceContext>()

	return (
		<Box position='relative' pb={2} mb={1}>
			<Field
				id='address'
				name='address'
				label='STREET ADDRESS'
				textArea={true}
				placeholder=''
				component={TextField}
				defaultValue={values.address}
			/>
			{errors.address && touched.address ? (
				<Typography variant='caption' color='red' position='absolute' left={0} bottom={0}>
					{errors.address}
				</Typography>
			) : null}
		</Box>
	)
}
