import { SharedSearch } from '@Components'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { StyledStack } from './StyledStack'
import { StyledBox } from './StyledBox'
import { useData, useHandlers } from './hooks'
import { RolesService } from '../../../../shared/services/roles.service'
import { RolesEnum } from '@Enums'
import { TrainingTypeEnum } from '../../utils/enums'
import { useLocation, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import {StyledOverviewMenuItem} from "./StyledOverviewMenuItem";
import {CourseStatisticItem} from "./CourseStatisticItem";

export function CourseStatistic() {
	const theme = useTheme()
	const { newTrainings, all, inProgress, completed, withOculus, filterStatus, filterType, physics, chemistry, biology } = useData()
	const showByStatuses = RolesService.availableForRole([
		RolesEnum.SuperAdmin,
		RolesEnum.Admin,
		RolesEnum.SuperUser,
		RolesEnum.User,
	])
	const { id } = useParams<{ id: string }>()
	const typeRoute = useLocation()?.state?.type
	const { handleFilterChange, handleSearchChange, handleStatusChange, handleTypeChange } = useHandlers(id || '')

	useEffect(() => {
		return handleFilterChange({ search: '', type: typeRoute })
	}, [handleFilterChange])

	return (
		<StyledStack>
			<>
				<Box mb={2}>
					<SharedSearch onChange={handleSearchChange} name='courses' placeholder='Search courses …' id='courses'/>
				</Box>


				<Stack>
					{showByStatuses && withOculus && (
						<>
							<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary} mb={1.75}>
								Status
							</Typography>
							<CourseStatisticItem title='All'
												 value={all}
												 isActive={typeof filterStatus !== 'number'}
												 handleStatusChange={() => handleStatusChange()}
							/>
							<CourseStatisticItem title='New'
												 value={newTrainings}
												 isActive={filterStatus === 0}
												 handleStatusChange={() => handleStatusChange(0)}
							/>
							<CourseStatisticItem title='In Progress'
												 value={inProgress}
												 isActive={filterStatus === 1}
												 handleStatusChange={() => handleStatusChange(1)}
							/>
							<CourseStatisticItem title='Completed'
												 value={completed}
												 isActive={filterStatus === 2}
												 handleStatusChange={() => handleStatusChange(2)}
							/>

							<Box border='1px solid #D2D2D2' mt={1.7} mb={2}></Box>
						</>
					)}

					<Typography fontFamily='GilroySemiBold' color={theme.palette.text.secondary} mb={1.75}>
						Types
					</Typography>
					<CourseStatisticItem title='All'
										 value={all}
										 isActive={typeof filterType !== 'number'}
										 handleStatusChange={() => handleTypeChange()}
					/>
					<CourseStatisticItem title={TrainingTypeEnum[0]}
										 value={physics}
										 isActive={filterType === 0}
										 handleStatusChange={() => handleTypeChange(0)}
					/>
					<CourseStatisticItem title={TrainingTypeEnum[1]}
										 value={biology}
										 isActive={filterType === 1}
										 handleStatusChange={() => handleTypeChange(1)}
					/>
					<CourseStatisticItem title={TrainingTypeEnum[2]}
										 value={chemistry}
										 isActive={filterType === 2}
										 handleStatusChange={() => handleTypeChange(2)}
					/>
				</Stack>
			</>
		</StyledStack>
	)
}
