import { useCallback } from 'react'
import { SubscriptionPlansEnum } from '@Enums'
import { fetchPaymentSecretEffect } from '../../../../effects'
import { usePaymentContext } from '../../../../context'

export function useIntent(setError: any, setLoading: any) {
	const [, dispatch] = usePaymentContext()

	const handleVerify = useCallback(
		(values: { email: string }) => {
			let mounted = true
			localStorage.setItem('email', values.email)

			const execute = async () => {
				const plan = localStorage.getItem('selectedPlan') || SubscriptionPlansEnum.Standard
				setLoading(true)
				const { action, status, error, data } = await fetchPaymentSecretEffect({
					email: values.email,
					subscriptionPlan: +plan,
					host: window.location.origin,
				})
				setLoading(false)

				if (!mounted) return

				if (status === 'success') {
					setError('')
					localStorage.setItem('email', values.email)
					window.location.href = data
					// dispatch(action)
				} else {
					setError(error)
				}
			}

			try {
				execute()
			} catch (error) {
				setError(error)
			}
		},
		[dispatch],
	)

	return {
		handleVerify,
	}
}
