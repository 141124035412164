import { useContext } from 'react'
import { PaymentContext } from '../context'

export function usePaymentContext() {
	const context = useContext(PaymentContext)

	if (context === undefined) throw Error('Must be descendant of PaymentContextProvider')

	return context
}
