import { useCallback } from 'react'
import { useReportsContext } from '../context'
import { setParticipantsFilterActionCreator, setTrainingsFilterActionCreator } from '../state/Actions'
import { Actions } from '../state'

const actions = {
	trainings: setTrainingsFilterActionCreator,
	participants: setParticipantsFilterActionCreator,
}

export function useFilterChange(entity: 'participants' | 'trainings') {
	const [, dispatch] = useReportsContext()
	return useCallback(
		(value: number | string, name: string) => {
			dispatch(actions[entity]({ value, name }) as Actions)
		},
		[dispatch, entity],
	)
}
