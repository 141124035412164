import { Typography, useTheme } from '@mui/material'

export function UnitedStatesLegalCompliance() {
	const theme = useTheme()

	return (
		<>
			<Typography fontFamily='GilroyBold' variant='h4' color={theme.palette.text.primary}>
				<u>United States Legal Compliance</u>
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				You represent and warrant that (i) You are not located in a country that is subject to the United States
				government embargo, or that has been designated by the United States government as a "terrorist supporting"
				country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.
			</Typography>
		</>
	)
}
