import * as Yup from 'yup'

export const RegisterValidationSchema = Yup.object().shape({
	email: Yup.string()
		.email('Email address must consist of a local part, the "@" symbol, and the domain.')
		.required('This field is required, please fill in the information')
		.min(3, 'Value is too short, please enter minimum 3 characters')
		.max(255, 'Value is too long, maximum length allowed is 255 characters'),
	name: Yup.string()
		.required('This field is required, please fill in the information')
		.min(3, 'Value is too short, please enter minimum 3 characters')
		.max(255, 'Value is too long, maximum length allowed is 255 characters'),
	company: Yup.string()
		.required('This field is required, please fill in the information')
		.min(3, 'Value is too short, please enter minimum 3 characters')
		.max(255, 'Value is too long, maximum length allowed is 255 characters'),
	password: Yup.string()
		.required('This field is required, please fill in the information')
		.min(3, 'Value is too short, please enter minimum 3 characters')
		.max(255, 'Value is too long, maximum length allowed is 255 characters'),
})
