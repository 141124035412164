import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Form, Formik } from 'formik'
import { useInitialValues } from './hooks'

import SafeLock from '@Assets/lock.svg'

import { CustomTextField, DividerGray, IconImage } from '@Components'
import { useIntent } from './hooks/useIntent'
import { VerifyButton } from './Button'
import { useGoToSubscriptions, useIsLoggedIn, useToken } from '@Hooks'
import { useEffect, useState } from 'react'
import { DisplayEnum } from 'shared/enums/display.enum'
import { fetchPaymentSecretActionCreator } from '../../../state/Actions/fetchPaymentSecret'
import { ValidationSchema } from '../../fields/validationSchema'
import { Circles } from 'react-loader-spinner'

export function CardBox() {
	const theme = useTheme()
	const user = useToken()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)
	const initialValues = useInitialValues()
	const { handleVerify } = useIntent(setError, setLoading)
	const goToSubscriptions = useGoToSubscriptions()
	const isLoggedin = useIsLoggedIn()
	const defaultValue = isLoggedin ? user.email : ''

	console.log(error)

	useEffect(() => {
		return () => {
			fetchPaymentSecretActionCreator('')
		}
	}, [])

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleVerify}
			validationSchema={ValidationSchema}
			validateOnChange={true}
			validateOnBlur={true}
		>
			<Stack>
				{loading && (
					<Stack
						position='absolute'
						top={0}
						left={0}
						width='100%'
						height='100%'
						bgcolor='rgba(0, 0, 0, .2)'
						zIndex={9999}
						alignItems='center'
						justifyContent='center'
					>
						<Circles height={120} width={120} color={theme.palette.grey.A200} ariaLabel='loading' />
					</Stack>
				)}
				<Form>
					<Stack mb={1.8}>
						<CustomTextField<{ email: string }>
							id='email'
							name='email'
							label='PAYMENT EMAIL'
							placeholder='EMAIL'
							type='email'
							error={error}
							disabled={isLoggedin}
							defaultValue={defaultValue}
						/>

						<Box mt={1}>
							<VerifyButton />
						</Box>
						<Stack
							direction={mobile ? 'column' : 'row'}
							mx='auto'
							mt={1}
							mb={2}
							alignItems='center'
							justifyContent='center'
							spacing={1.2}
						>
							<IconImage src={SafeLock} alt='Safe Lock' width='24px' />

							<Typography
								fontSize={mobile ? 14 : 16}
								color={theme.palette.text.secondary}
								fontFamily='GilroyMedium'
								textAlign={mobile ? 'center' : 'left'}
							>
								You will be redirected to the payment operator's website to complete the transaction. <br /> This is an
								SSL-encrypted payment. You're safe.
							</Typography>
						</Stack>
						<DividerGray />
						<Box mt={1.6}>
							<Button variant='rounded_transparent' fullWidth onClick={goToSubscriptions}>
								<Typography
									color={theme.palette.text.secondary}
									fontSize={mobile ? 14 : 16}
									fontFamily='GilroySemiBold'
								>
									CANCEL
								</Typography>
							</Button>
						</Box>
						{/* {!blockEmail && <VerifyButton />} */}
					</Stack>
				</Form>
			</Stack>
		</Formik>
	)
}
