import { Route, Routes } from 'react-router-dom'
import { ForbiddenPage } from './forbidden/ForbiddenPage'
import { MissingPage } from './missing/MissingPage'

export function ErrorsRoutes() {
    return (
        <Routes>
            <Route path='/errors/forbidden' element={< ForbiddenPage />} />
            <Route path='/errors/missing' element={< MissingPage />} />
        </Routes>
    )
}