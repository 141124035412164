import { DisplayEnum } from '@Enums'
import { Stack, styled } from '@mui/material'

export const PricingBackStack = styled(Stack, { name: 'PricingBackStack' })({
	width: '384px',
  height: '503px',
  borderRadius: '10px',
  backgroundColor: '#fff',
  opacity: 0.75,
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 2,
  [`@media (max-width: ${DisplayEnum.mobile}px)`]: {
    width: '100%',
    height: '381px'
	}
})