import { Stack, styled } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'

export const ModalWrapper = styled(Stack, { name: 'ModalWrapper' })({
	// background: '#1e1c30',
	boxShadow: '0 12px 99px #00000029',
	border: '1px solid #ffffff57',
	borderRadius: '10px',
	boxSizing: 'border-box',
	overflow: 'hidden',
	[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
		overflowY: 'auto'
	},
})
	
