import { Dispatch, SetStateAction, useCallback } from 'react'
import type { RegistrationData } from '../../typings'
import { useSharedContext } from '@Context'
import { registrationEffect } from '../../effects/registration.effect'
import { useNavigate, useParams } from 'react-router-dom'
import type { StoreUser } from '@State'
import { RolesEnum } from '@Enums'

function getRedirectionUrl(role: RolesEnum = RolesEnum.Guest) {
	const startPages = {
		[RolesEnum.User]: '/trainings',
		[RolesEnum.Guest]: '/trainings',
		[RolesEnum.SuperUser]: '/trainings',
		[RolesEnum.SuperAdmin]: '/subscriptions',
		[RolesEnum.Admin]: '/subscriptions',
	}

	return startPages[role]
}

export function useHandlers(setShowError: Dispatch<SetStateAction<any>>) {
	const [, dispatch] = useSharedContext()
	const navigate = useNavigate()
	const { company, email } = useParams<{ company: string; email: string }>()

	const handleSubmit = useCallback(
		async (values: RegistrationData) => {
			let mounted = true

			try {
				const { action, status } = await registrationEffect({ ...values })

				if (!mounted) return

				if (status === 'success') {
					dispatch(action as StoreUser)
					localStorage.removeItem('email')

					navigate(getRedirectionUrl(action.payload?.role))
				}
			} catch (err: any) {
				setShowError(err?.response?.data?.errors)
			}

			return () => {
				mounted = false
			}
		},
		[dispatch],
	)

	const handleMainClick = useCallback(() => {
		navigate('/')
	}, [navigate])

	return {
		handleSubmit,
		handleMainClick,
	}
}
