import { styled, Stack } from '@mui/material'

export const StyledStack = styled(Stack, { name: 'StyledStack' })({
	background: '#FFFFFF 0% 0% no-repeat padding-box',
	backdropFilter: 'blur(16px)',
	boxShadow: '0px 24px 76px #0000001C',
	border: '1px solid #FFFFFF57',
	borderRadius: '10px',
	width: '309px',
	padding: '21px',
})
