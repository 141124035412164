import { Footer } from '@Components'
import { Stack, useTheme } from '@mui/material'
import { ForbiddenPageComponent } from './components'

export function ForbiddenPage() {
    const theme = useTheme()

    return (
        <Stack bgcolor={theme.palette.background.default}>
            <ForbiddenPageComponent />
            <Footer onMainPage={false} />
        </Stack>
    )
}
