import { Box, Stack, styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useCallback } from 'react'
import type { ChangeEvent } from 'react'
import { useFormikContext } from 'formik'
import { theme } from 'theme'

export const StyledSearchStack = styled(Stack, { name: 'StyledSearchStack' })({
	border: '1px solid #D2D2D2',
	boxShadow: '0px 12px 99px #00000029',
	borderRadius: '5px',
	height: '100%'
})

export const StyledSearchInput = styled('input', { name: 'StyledSearchInput' })({
	padding: '13px 15px',
	paddingRight: '64px',
	borderRadius: '4px',
	outline: 'none',
	boxShadow: 'none',
	border: 'none',
	background: '#FFFFFF12',
	color: '#000',
	fontSize: '18px',
	"::placeholder": {
		color: 'rgba(0, 0, 0, .5)'
	}
})

type Props = {
	bgColor?: string
	onChange?: (value: string, name: string) => void
	placeholder?: string
	name: string
	id: string
}

export function SharedSearch(props: Props) {
	const { bgColor = '#FFFFFF12', onChange, placeholder = 'Search courses...', name, id } = props
	const formik = useFormikContext()

	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			if (onChange) {
				onChange(event.target.value, name)
			} else {
				formik?.setFieldValue(name || id, event.target.value)
			}
		},
		[onChange, name, formik, id],
	)

	return (
		<StyledSearchStack
			position='relative'
			minWidth={305}>
			<StyledSearchInput
				id={name}
				name={name}
				type='text'
				onChange={handleChange}
				placeholder={placeholder}
			/>
			<Box position='absolute' top={11} right={20} color='#000'>
				<SearchIcon />
			</Box>
		</StyledSearchStack>
	)
}
