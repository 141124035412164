import type { ReactElement } from 'react'
import { ReportingContext } from './context'
import { useData } from './hooks'

type Props = {
	children: ReactElement
}

export function ReportingContextProvider(props: Props) {
	const { children } = props
	const { value } = useData()

	return <ReportingContext.Provider value={value}>{children}</ReportingContext.Provider>
}
