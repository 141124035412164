import { RequestService } from '@Services'
import { URLS } from '@Utils'
import { SubscriptionsEffectResponse } from '../typings'
import { unsubscribeActionCreator } from '../state'

export async function unsubscribeEffect(): Promise<SubscriptionsEffectResponse> {
	try {
		const response = await RequestService.remove(URLS.unsubscribe, undefined, true)

		if (response.status === 200) {
			return { status: 'success', action: unsubscribeActionCreator() }
		}

		return { status: 'error', action: unsubscribeActionCreator(), error: response.data }
	} catch (err: any) {
		return { status: 'error', action: unsubscribeActionCreator(), error: err }
	}
}
