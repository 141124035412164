import { IconImage, SharedFiltersArrows } from '@Components'
import { Box, Stack, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { StyledTableCell } from '../../../components/StyledTable'

type Props = {
	tableHeadingTitles: {
		title: string
		icon: string
		hasFilters: boolean
	}[]
}

export function TrainingsReportingCategoriesRow(props: Props) {
	const { tableHeadingTitles } = props
	const theme = useTheme()

	return (
		<TableHead>
			<TableRow>
				{tableHeadingTitles.map((headingTitle, index) => (
					<StyledTableCell align='left' key={`cell_header_${index}`}>
						<Stack direction='row' columnGap={1} alignItems='center'>
							<Stack direction='row' alignItems='center' color={theme.palette.text.disabled}>
								{headingTitle.icon && (
									<Box mt={0.5} mr={1}>
										<IconImage src={headingTitle.icon} alt={headingTitle.title} height='24px' width='24px' />
									</Box>
								)}
								<Typography py={1.5} fontFamily='GilroySemiBold' textTransform='uppercase' fontSize={14}>
									{headingTitle.title}
								</Typography>
							</Stack>
							{headingTitle.hasFilters && <SharedFiltersArrows up={() => {}} down={() => {}} />}
						</Stack>
					</StyledTableCell>
				))}
			</TableRow>
		</TableHead>
	)
}
