import { useMemo, useReducer } from 'react'
import { sharedReducer, initState } from '@State'
import type { Actions, SharedState } from '@State'
import { AsyncDispatch, wrapAsync } from '@Utils'

export function useData() {
	const [state, dispatch] = useReducer(sharedReducer, initState)

	const asyncDispatch = useMemo(() => wrapAsync(dispatch), [dispatch])

	const value: [SharedState, AsyncDispatch<Actions>] = [state, asyncDispatch]

	return {
		value,
	}
}
