import { SubscriptionPlansEnum } from '@Enums'
import { useMemo } from 'react'
import { useSubscriptionsCardsData } from '@Hooks'

export function useSubscriptionData(): { users: string; price: number; title: string; description: string } {
	const plan = +(localStorage.getItem('selectedPlan') || SubscriptionPlansEnum.Standard)
	const cards = useSubscriptionsCardsData()

	return useMemo(() => {
		const entry = Object.entries(cards).find(
			(entry) => entry[0].toLowerCase() === SubscriptionPlansEnum[plan].toLowerCase(),
		)

		if (entry) {
			return entry[1]
		}

		return { users: '0', title: '', description: '', price: 0 }
	}, [plan, cards])
}
