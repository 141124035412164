import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useSubscriptionsContext } from '../context'
import { SubscriptionsTableEmpty } from './SubscriptionsTableEmpty'
import { SubscriptionsTableRow } from './SubscriptionsTableRow/SubscriptionsTableRow'
import { SubscriptionsTableRowMobile } from './SubscriptionsTableRow/SubscriptionsTableRowMobile'
import { SubscriptionsTableHeader } from './SubscriptionsTableHeader'
import { StyledTableGrid } from './StyledTableGrid'
import { DisplayEnum } from 'shared/enums/display.enum'

export function SubscriptionsTable() {
	const [{ payments }] = useSubscriptionsContext()
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	return (
		<StyledTableGrid container maxWidth={1180} bgcolor='inherit' borderRadius='0 0 10px 10px' mb={mobile ? -9 : 24}>
			<Grid item xs={12}>
				<Typography fontSize={mobile ? 14 : 20} fontFamily='GilroySemiBold' color={theme.palette.text.primary} px={3.5} py={2.5}>
					ARCHIVE SUBSCRIPTIONS & PAYMENT HISTORY
				</Typography>
				{!payments.length && <SubscriptionsTableEmpty />}
				{!mobile && !!payments.length && <SubscriptionsTableHeader />}
				{!mobile &&
					!!payments.length &&
					payments.map((payment, index) => <SubscriptionsTableRow payment={payment} key={`row_${index}`} />)}
				{mobile &&
					!!payments.length &&
					payments.map((payment, index) => <SubscriptionsTableRowMobile payment={payment} key={`row_${index}`} />)}
			</Grid>
		</StyledTableGrid>
	)
}
