import { Footer, Header } from '@Components'
import {Box, Stack} from '@mui/material'
import { CourseContentStack } from '../components'
import { CourseWrapperComponent } from './components/CourseWrapperComponent'
import { useEffect, useState } from 'react'
import { DataManager } from '../DataManager'
import {useToken} from "@Hooks";

export function CourseListPage() {
	const user = useToken()

	const isLoggedIn = Object.keys(user || {}).length
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<DataManager setLoading={setLoading}>
			<Stack>
				<CourseContentStack>
					<Box position='fixed' top={0} left={0} right={0} zIndex={999}>
						<Header hideNavigationMenu={!!isLoggedIn} />
					</Box>
					<CourseWrapperComponent loading={loading}/>
					<Footer showAdditionalInfo={true} onMainPage={false} />
				</CourseContentStack>
			</Stack>
		</DataManager>
	)
}
