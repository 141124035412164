import { useContext } from 'react'
import { SharedContext } from './context'

export function useSharedContext() {
	const context = useContext(SharedContext)

	if (context === undefined) throw Error('Must be descendant of SharedContextProvider')

	return context
}
