import type { ReactElement } from 'react'
import { useData } from './hooks'
import { SubscriptionsContext } from './context'

type Props = {
	children: ReactElement
}

export function SubscriptionsContextProvider(props: Props) {
	const { children } = props
	const { value } = useData()

	return <SubscriptionsContext.Provider value={value}>{children}</SubscriptionsContext.Provider>
}
