import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { StyledGrid } from 'features/subscriptions/components'
import { DisplayEnum } from 'shared/enums/display.enum'

export function TableColumnsNames() {
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	return (
		<StyledGrid container width={mobile ? '800px' : '100%'} p={2}>
			<Grid item xs={5}>
				<Typography variant='body1' fontFamily='GilroyRegular' color={theme.palette.text.secondary}>
					Email
				</Typography>
			</Grid>
			<Grid item xs={2}>
				<Typography variant='body1' fontFamily='GilroyRegular' color={theme.palette.text.secondary}>
					Oculus User
				</Typography>
			</Grid>
			<Grid item xs={2}>
				<Typography variant='body1' fontFamily='GilroyRegular' color={theme.palette.text.secondary}>
					Role
				</Typography>
			</Grid>
			<Grid item xs={2}>
				<Typography variant='body1' fontFamily='GilroyRegular' color={theme.palette.text.secondary}>
					Added date
				</Typography>
			</Grid>
			<Grid item xs={1}></Grid>
		</StyledGrid>
	)
}
