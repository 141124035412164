import type { SubscriptionUser } from '../../../typings'
import type { FetchSubscriptionUsers } from './typings'

export const fetchSubscriptionUsersActionCreator = (payload: {
	users: SubscriptionUser[]
	total: number
}): FetchSubscriptionUsers => ({
	type: 'subscriptions/fetchSubscriptionUsers',
	payload,
})
