import { loadStripe, Stripe } from '@stripe/stripe-js'

export const API_URL = 'https://schoolvr-dev-we-api.azurewebsites.net/api'

export const stripePromise: PromiseLike<Stripe | null> | Stripe | null = loadStripe(
	'pk_test_51KlztwBwc90fGgLvH2Pax4zlZhJ7fGBZKUo17qky45dxYRwjv5mkSNuoXtpslF7P4dquiRBdWEqtG4gqW8i0F0ZY00GdHzqeFN',
)

export const msalConfigKeys = {
	clientId: 'b6467d0f-5a3d-4417-945c-ddc6cc520da3',
	authority: 'https://login.microsoftonline.com/common',
	redirectUri: 'http://localhost:3000/login',
	postLogoutRedirectUri: 'http://localhost:3000/login'
};
