import { alpha, createTheme, darken, lighten, Theme } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		rounded_white: true
		rounded_contained: true
		rounded_success: true
		rounded_contained__secondary: true
		rounded_contained__microsoft: true
		rounded_contained__black_text: true
		rounded_contained__secondary_darken: true
		rounded_contained__alert: true
		rounded_contained__success: true
		rounded_transparent: true
		price_card: true
		price_card_green: true
		buy_now: true
		contact_us: true
		contact_us_purple: true
		courses_back: true
		contact_us_green_text: true
		contact_us_white_text: true
		text_button: true
		rounded_errors_page: true
		rounded_errors_page_red: true
		rounded_errors_page_purple: true
		confirmation_ok: true
		confirmation_cancel: true
		rounded_contained__auth_secondary: true
		rounded_contained__auth_success: true
		courses_back_small: true
		cookies_accept: true
		description_white: true
		filter_arrow_up: true
		filter_arrow_down: true
		get_started: true
		primary_btn: true
		purple_btn: true
		primary_btn_large: true
		back_btn: true
	}
}

declare module '@mui/material/Pagination' {
	interface PaginationPropsVariantOverrides {
		outlined_dark: true
	}
}

export const theme: Theme = createTheme({
	palette: {
		primary: {
			main: '#fff',
			dark: '#2F2D47',
			light: '#2C2A3D',
			contrastText: '#E08D9A',
		},
		secondary: {
			main: '#3E275A',
			dark: '#403F5E',
			light: '#4C4A59',
			contrastText: '#FE9936',
		},
		success: {
			main: '#13F094',
			dark: '#21A76F',
			contrastText: '#7A6CF4',
			light: '#C065B9',
		},
		info: {
			main: '#9945FF',
			contrastText: '#BDB6FF',
			light: '#21A76F',
			dark: '#303030',
		},
		text: {
			primary: '#1E1C30',
			secondary: alpha('#1E1C30', 0.7),
			disabled: alpha('#fff', 0.5),
		},
		background: {
			default: '#fff',
			// paper: '#2F2E46',
		},
		grey: {
			A100: '#3C3857',
			A200: '#4F4E5D',
			A400: '#62616F',
			A700: '#26243A',
		},
		error: {
			main: '#AA1D1D42',
			light: '#FD2C2C',
			contrastText: '#C065B9',
		},
		common: {
			white: alpha('#fff', 0.2),
			black: alpha('#000', 0.2),
		},
		warning: {
			main: '#FF6943',
			light: '#529FC6',
		},
	},
	spacing: 10,
	components: {
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: '#9945FF',
					color: 'white',
					fontFamily: 'GilroyMedium',
					fontSize: 16,
					padding: '14px',
				},
				arrow: {
					color: '#9945FF',
				},
			},
		},
		MuiButton: {
			variants: [
				{
					props: {
						variant: 'rounded_white',
					},
					style: {
						borderRadius: 82,
						border: '3px solid rgba(255, 255, 255, .2)',
						textTransform: 'none',
						color: '#fff',
						fontFamily: 'GilroySemiBold',
						fontSize: '18px',
						':hover': {
							background: darken('#fff', 0.9),
							color: 'primary',
						},
						[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
							fontSize: '14px',
						},
					},
				},
				{
					props: {
						variant: 'description_white',
					},
					style: {
						borderRadius: 82,
						border: '3px solid rgba(255, 255, 255, .2)',
						textTransform: 'none',
						color: '#fff',
						padding: '16px 34px',
						fontFamily: 'GilroySemiBold',
						fontSize: '18px',
						':hover': {
							background: '#fff',
							color: '#9945FF',
							border: '3px solid #fff',
						},
						[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
							fontSize: '14px',
							padding: '12px 20px',
						},
					},
				},
				{
					props: {
						variant: 'rounded_errors_page',
					},
					style: {
						borderRadius: 64,
						border: '1px solid #FFFFFF57',
						background: '#F4F4F3',
						textTransform: 'none',
						color: '#fff',
						fontFamily: 'GilroySemiBold',
						fontSize: '16px',
						padding: '15px 38px',
						boxSizing: 'border-box',
						opacity: '0.62',
						':hover': {
							background: darken('#F4F4F3', 0.9),
							color: 'primary',
						},
						[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
							fontSize: '14px',
							padding: '10px 30px',
						},
					},
				},
				{
					props: {
						variant: 'rounded_errors_page_red',
					},
					style: {
						borderRadius: 133,
						background: '#CB374B',
						textTransform: 'none',
						color: '#fff',
						fontFamily: 'GilroySemiBold',
						fontSize: '16px',
						padding: '15px 38px',
						boxSizing: 'border-box',
						':hover': {
							background: darken('#CB374B', 0.5),
							color: 'primary',
						},
						[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
							fontSize: '14px',
							padding: '10px 30px',
						},
					},
				},
				{
					props: {
						variant: 'rounded_errors_page_purple',
					},
					style: {
						borderRadius: 133,
						background: '#21A76F',
						textTransform: 'none',
						color: '#fff',
						fontFamily: 'GilroySemiBold',
						fontSize: '16px',
						padding: '15px 38px',
						boxSizing: 'border-box',
						':hover': {
							background: darken('#21A76F', 0.5),
							color: 'primary',
						},
						[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
							fontSize: '14px',
							padding: '10px 30px',
						},
					},
				},
				{
					props: {
						variant: 'text_button',
					},
					style: {
						color: '#fff',
						fontFamily: 'GilroyBold',
						fontSize: '24px',
						display: 'inline-block',
						padding: 0,
						textTransform: 'capitalize',
						lineHeight: 1.2,
					},
				},
				{
					props: {
						variant: 'cookies_accept',
					},
					style: {
						borderRadius: 82,
						color: '#fff',
						backgroundColor: 'rgba(122, 108, 244, 1)',
						fontFamily: 'GilroySemiBold',
						fontSize: '18px',
						display: 'inline-block',
						padding: '17px',
						textTransform: 'capitalize',
						lineHeight: 1.2,
						':hover': {
							backgroundColor: 'rgba(122, 108, 244, 0.7)',
						},
						[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
							fontSize: '14px',
							padding: '10px 30px',
						},
					},
				},
				{
					props: {
						variant: 'rounded_success',
					},
					style: {
						borderRadius: '64px',
						color: '#fff',
						fontFamily: 'GilroySemiBold',
						height: '55px',
						fontSize: '18px',
						background: '#13F094',
						':hover': {
							background: darken('#13F094', 0.2),
						},
					},
				},
				{
					props: {
						variant: 'rounded_transparent',
					},
					style: {
						borderRadius: '64px',
						color: '#F4F4F3',
						fontFamily: 'GilroySemiBold',
						height: '55px',
						fontSize: '18px',
						background: 'transparent',
						':hover': {
							background: darken('#F4F4F3', 0.2),
						},
					},
				},

				{
					props: {
						variant: 'rounded_contained',
					},
					style: {
						borderRadius: 82,
						background: '#fffff',
						color: '#9945FF',
						fontSize: '18px',
						fontFamily: 'GilroySemiBold',
						textTransform: 'none',
					},
				},
				{
					props: {
						variant: 'rounded_contained__black_text',
					},
					style: {
						borderRadius: 82,
						background: '#fff',
						color: '#1E1C30',
						fontSize: '18px',
						fontFamily: 'GilroyBold',
						textTransform: 'none',
						padding: '16px 50px',
						':hover': {
							background: darken('#fff', 0.2),
						},
						[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
							fontSize: '14px',
						},
					},
				},
				{
					props: {
						variant: 'price_card',
					},
					style: {
						borderRadius: 82,
						background: '#fff',
						color: '#1E1C30',
						fontSize: '18px',
						height: '55px',
						boxSizing: 'border-box',
						fontFamily: 'GilroyExtraBold',
						textTransform: 'none',
						padding: '16px 50px',
						':hover': {
							background: darken('#fff', 0.2),
						},
					},
				},
				{
					props: {
						variant: 'price_card_green',
					},
					style: {
						borderRadius: 82,
						background: '#21A76F',
						color: '#fff',
						fontSize: '18px',
						height: '55px',
						boxSizing: 'border-box',
						fontFamily: 'GilroyBold',
						textTransform: 'uppercase',
						padding: '16px 50px',
						':hover': {
							background: darken('#21A76F', 0.2),
						},
					},
				},
				{
					props: {
						variant: 'courses_back',
					},
					style: {
						background: '#F4F4F3',
						border: '1px solid',
						borderColor: darken('#F4F4F3', .2),
						borderRadius: '6px',
						width: '74px',
						height: '74px',
						boxSizing: 'border-box',
						':hover': {
							background: darken('#F4F4F3', 0.2),
						},
					},
				},
				{
					props: {
						variant: 'courses_back_small',
					},
					style: {
						background: '#F4F4F3',
						border: '1px solid',
						borderColor: darken('#F4F4F3', .2),
						borderRadius: '6px',
						minWidth: '0px',
						height: '50px',
						width: '50px',
						boxSizing: 'border-box',
						':hover': {
							background: darken('#F4F4F3', 0.2),
						},
					},
				},
				{
					props: {
						variant: 'contact_us',
					},
					style: {
						borderRadius: 82,
						background: '#fff',
						color: '#21A76F',
						fontSize: '18px',
						height: '55px',
						boxSizing: 'border-box',
						fontFamily: 'GilroySemiBold',
						textTransform: 'none',
						padding: '16px 50px',
						':hover': {
							background: darken('#fff', 0.2),
						},
					},
				},
				{
					props: {
						variant: 'contact_us_green_text',
					},
					style: {
						borderRadius: 64,
						background: '#fff',
						color: '#21A76F',
						fontSize: '18px',
						height: '55px',
						boxSizing: 'border-box',
						fontFamily: 'GilroySemiBold',
						textTransform: 'none',
						padding: '18px 39px',
						':hover': {
							background: darken('#fff', 0.2),
						},
					},
				},
				{
					props: {
						variant: 'contact_us_white_text',
					},
					style: {
						borderRadius: 64,
						background: '#transparent',
						border: '1px solid',
						borderColor: '#ffff',
						color: '#ffff',
						fontSize: '18px',
						height: '55px',
						boxSizing: 'border-box',
						fontFamily: 'GilroySemiBold',
						textTransform: 'none',
						padding: '18px 29px',
						':hover': {
							background: darken('#21A76F', 0.2),
						},
					},
				},
				{
					props: {
						variant: 'buy_now',
					},
					style: {
						borderRadius: 82,
						background: '#2F2E46',
						color: alpha('#fff', 0.6),
						width: '100%',
						fontSize: '18px',
						height: '55px',
						fontFamily: 'GilroyBold',
						textTransform: 'uppercase',
						padding: '16px 50px',
						':hover': {
							background: darken('#2F2E46', 0.2),
						},
					},
				},
				{
					props: {
						variant: 'rounded_contained__secondary',
					},
					style: {
						borderRadius: '135px',
						background: 'rgba(255, 255, 255, .2)',
						color: '#fff',
						fontSize: '18px',
						fontFamily: 'GilroySemiBold',
						textTransform: 'none',
						padding: '18px 44px',
						':hover': {
							background: darken('rgba(255, 255, 255, .2)', 0.2),
						},
					},
				},
				{
					props: {
						variant: 'rounded_contained__auth_secondary',
					},
					style: {
						borderRadius: 6,
						background: 'rgb(233,233,233)',
						color: '#000',
						fontSize: 16,
						fontFamily: 'GilroySemiBold',
						padding: '16px 44px',
						textTransform: 'none',
						':hover': {
							background: darken('rgba(233,233,233, .2)', 0.2),
						},
					},
				},
				{
					props: {
						variant: 'rounded_contained__auth_success',
					},
					style: {
						borderRadius: 6,
						background: '#25CCAC 0% 0% no-repeat padding-box',
						color: '#fff',
						fontSize: 16,
						fontFamily: 'GilroySemiBold',
						padding: '16px 44px',
						backdropFilter: 'blur(9px)',
						textTransform: 'none',
						':hover': {
							background: darken('#13F094', 0.2),
						},
					},
				},
				{
					props: {
						variant: 'rounded_contained__microsoft',
					},
					style: {
						borderRadius: 6,
						backgroundColor: 'rgba(37, 109, 204, .78)',
						color: '#fff',
						fontSize: 16,
						fontFamily: 'GilroySemiBold',
						padding: '16px 44px',
						backdropFilter: 'blur(9px)',
						textTransform: 'none',
						':hover': {
							background: darken('#256DCC', 0.2),
						},
						'@media screen and (max-width: 767px)': {
							padding: '16px 20px',
						}
					},
				},
				{
					props: {
						variant: 'rounded_contained__secondary_darken',
					},
					style: {
						border: '1px solid #FFFFFF57',
						borderRadius: '54px',
						background: '#21A76F',
						boxShadow: '0px 12px 99px #00000029',
						color: '#fff',
						fontSize: '16px',
						fontFamily: 'GilroySemiBold',
						textTransform: 'none',
						paddingTop: '18px',
						paddingBottom: '18px',
						lineHeight: 1,
						':hover': {
							background: darken('#21A76F', 0.2),
						},
					},
				},
				{
					props: {
						variant: 'rounded_contained__alert',
					},
					style: {
						border: '1px solid #FF6943',
						borderRadius: '54px',
						background: '#FF694314',
						boxShadow: '0px 12px 99px #00000029',
						color: '#000',
						fontSize: '16px',
						fontFamily: 'GilroySemiBold',
						textTransform: 'none',
						paddingTop: '18px',
						paddingBottom: '18px',
						lineHeight: 1,
						':hover': {
							background: darken('#00000029', 0.2),
						},
					},
				},
				{
					props: {
						variant: 'rounded_contained__success',
					},
					style: {
						border: '1px solid #13F094',
						borderRadius: '54px',
						background: '#13F09414',
						boxShadow: '0px 12px 99px #00000029',
						color: '#fff',
						fontSize: '16px',
						fontFamily: 'GilroySemiBold',
						textTransform: 'none',
						paddingTop: '18px',
						paddingBottom: '18px',
						lineHeight: 1,
						':hover': {
							background: darken('#13F09414', 0.2),
						},
					},
				},
				{
					props: {
						variant: 'confirmation_ok',
					},
					style: {
						border: '1px solid #fff',
						borderRadius: '64px',
						background: '#fff',
						boxShadow: '0px 12px 99px #00000029',
						color: '#FF6943',
						fontSize: '16px',
						fontFamily: 'GilroySemiBold',
						textTransform: 'none',
						padding: '18px 27px',
						boxSizing: 'border-box',
						lineHeight: 1,
						cursor: 'pointer',
						':hover': {
							background: '#FF6943',
							color: '#fff',
						},
					},
				},
				{
					props: {
						variant: 'confirmation_cancel',
					},
					style: {
						border: '1px solid #FF6943',
						borderRadius: '64px',
						background: '#FF6943',
						color: '#fff',
						fontSize: '16px',
						fontFamily: 'GilroySemiBold',
						textTransform: 'none',
						padding: '18px 27px',
						boxSizing: 'border-box',
						lineHeight: 1,
						marginLeft: '30px',
						cursor: 'pointer',
						':hover': {
							borderColor: '#fff',
						},
					},
				},
				{
					props: {
						variant: 'get_started',
					},
					style: {
						border: 'none',
						borderRadius: '6px',
						background: 'rgba(37, 204, 172, 1)',
						color: '#fff',
						fontSize: '16px',
						fontFamily: 'GilroySemiBold',
						textTransform: 'none',
						padding: '12px 20px',
						boxSizing: 'border-box',
						lineHeight: 1,
						marginLeft: '30px',
						cursor: 'pointer',
						':hover': {
							backgroundColor: 'rgba(37, 204, 172, 0.7)'
						},
					},
				},
				{
					props: {
						variant: 'primary_btn',
					},
					style: {
						border: 'none',
						borderRadius: '6px 6px 0 0',
						background: 'rgba(37, 204, 172, 1)',
						color: '#fff',
						fontSize: '18px',
						fontFamily: 'GilroySemiBold',
						textTransform: 'none',
						padding: '25px 45px',
						boxSizing: 'border-box',
						lineHeight: 1,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						cursor: 'pointer',
						':hover': {
							backgroundColor: 'rgba(37, 204, 172, 0.7)'
						},
					},
				},
				{
					props: {
						variant: 'primary_btn_large',
					},
					style: {
						border: 'none',
						borderRadius: '6px 6px 0 0',
						background: 'rgba(37, 204, 172, 1)',
						color: '#fff',
						fontSize: '18px',
						fontFamily: 'GilroySemiBold',
						textTransform: 'none',
						padding: '25px 45px',
						boxSizing: 'border-box',
						lineHeight: 1,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						cursor: 'pointer',
						':hover': {
							backgroundColor: 'rgba(37, 204, 172, 0.7)'
						},
					},
				},
				{
					props: {
						variant: 'purple_btn',
					},
					style: {
						border: 'none',
						borderRadius: '6px 6px 0 0',
						background: 'rgba(122, 108, 244, 1)',
						color: '#fff',
						fontSize: '18px',
						fontFamily: 'GilroySemiBold',
						textTransform: 'none',
						padding: '25px 45px',
						boxSizing: 'border-box',
						lineHeight: 1,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						cursor: 'pointer',
						':hover': {
							backgroundColor: 'rgba(122, 108, 244, 0.7)'
						},
					},
				},
				{
					props: {
						variant: 'contact_us_purple',
					},
					style: {
						borderRadius: 6,
						background: '#7A6CF4',
						color: '#fff',
						fontSize: '16px',
						height: '55px',
						boxSizing: 'border-box',
						fontFamily: 'GilroySemiBold',
						textTransform: 'none',
						padding: '18px 50px',
						textAlign: 'center',
						':hover': {
							backgroundColor: 'rgba(122, 108, 244, 0.7)',
						},
					},
				},
				{
					props: {
						variant: 'filter_arrow_up',
					},
					style: {
						width: 0,
						height: 0,
						minWidth: 0,
						padding: 0,
						borderLeft: '7px solid transparent',
						borderRight: '7px solid transparent',
						borderBottom: '7px solid #2E2C3E',
						':hover': {
							background: darken('#2E2C3E', 0.2),
						},
						':active': {
							borderBottom: '7px solid #fff',
						},
					},
				},
				{
					props: {
						variant: 'filter_arrow_down',
					},
					style: {
						width: 0,
						height: 0,
						minWidth: 0,
						padding: 0,
						borderLeft: '7px solid transparent',
						borderRight: '7px solid transparent',
						borderTop: '7px solid #2E2C3E',
						':hover': {
							background: darken('#2E2C3E', 0.2),
						},
						':active': {
							borderTop: '7px solid #fff',
						},
					},
				},
				{
					props: {
						variant: 'back_btn',
					},
					style: {
						width: '38px',
						height: '38px',
						minWidth: '38px',
						padding: 0,
						display: 'flex',
						alignItem: 'center',
						justifyContent: 'center',
						borderRadius: '4px',
						backgroundColor: 'rgba(226,226,226,1)',
						':hover': {
							backgroundColor: 'rgba(226,226,226,.7)',
						}
					},
				},
			],
		},
		MuiPagination: {
			variants: [
				{
					props: {
						variant: 'outlined_dark',
					},
					style: {
						li: {
							background: 'rgba(255, 255, 255, .1)',
							boxShadow: '0px 12px 99px #00000029',
							border: '1px solid #FFFFFF57',
							borderRadius: '4px',
							marginLeft: '10px',
							minWidth: '40px',
							minHeight: '40px',
							display: 'flex',
							alignItems: 'center',

							button: {
								margin: 0,
								'&.Mui-selected': {
									background: 'rgba(255, 255, 255, .2)',
								},
							},
						},
					},
				},
			],
		},
	},
})
