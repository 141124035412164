import {
	Box,
	Button,
	ListItemIcon,
	Menu,
	MenuItem,
	Stack,
	Typography,
	useTheme,
	ListItemText,
	useMediaQuery,
} from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import { DividerGray, IconButtonSecondary, IconImage } from '@Components'
import type { Subscription } from '../../typings'
import { useData, useHandlers } from './hooks'
import { SubscriptionPlansEnum } from '@Enums'
import infinity from '@Assets/clvr_infinity.png'
import { useState } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove'
import { ConfirmationDialogRaw } from './components/DownloadInvoiceDialog'

type Props = {
	subscription: Subscription
}

export function MobileCard(props: Props) {
	const { subscription } = props
	const theme = useTheme()
	const { period, price } = useData(subscription)
	const { handleManageClick, handleUnsubscribeClick, handleUpgradeClick } = useHandlers()
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const [openDialog, setOpenDialog] = useState(false)

	const handleCloseDialog = (newValue?: string) => {
		setOpenDialog(false)
	}

	const downloadInvoice = () => {
		setAnchorEl(null)
		setOpenDialog(true)
		console.log('Download invoice')
	}

	const upgrade = () => {
		console.log('Upgrade')
	}

	const unsubscribe = () => {
		console.log('Unsubscribe')
	}

	return (
		<Stack direction='column'>
			<Stack flexGrow={1} mb={2}>
				<Typography variant='h4' color={theme.palette.text.primary} fontFamily='GilroyBold' mb={1} fontSize={28}>
					{subscription.name === 'Standart' ? 'Standard' : subscription.name}
				</Typography>

				<Typography maxWidth={640} variant='body1' color={theme.palette.text.secondary} mb={1.5}>
					{subscription.description}
				</Typography>
				{subscription.subscriptionType !== SubscriptionPlansEnum.Enterprise && (
					<Stack mr={2.8}>
						<Stack direction='row' alignItems='baseline' justifyContent={'center'}>
							<Typography
								color={theme.palette.text.secondary}
								fontFamily='GilroyBold'
								fontSize={26}
								lineHeight={1}
								mr={1}
							>
								$
							</Typography>
							<Typography component='span' fontSize={60} color={theme.palette.text.primary} fontFamily='GilroyBold' lineHeight={1}>
								{price * 12}
							</Typography>{' '}
							<Typography
								color={theme.palette.text.secondary}
								fontFamily='GilroyBold'
								fontSize={26}
								lineHeight={1}
								ml={1}
							>
								GBP
							</Typography>
						</Stack>
						<Typography
							fontSize={15}
							color={theme.palette.text.secondary}
							fontFamily='GilroySemiBold'
							textAlign='center'
						>
							PER YEAR
						</Typography>
					</Stack>
				)}
				{subscription.subscriptionType !== SubscriptionPlansEnum.Enterprise && (
					<Stack mt={2}>
						<Typography fontSize={15} color={theme.palette.text.secondary} fontFamily='GilroySemiBold'>
							OCULUS USERS
						</Typography>
						<Typography mt={0.7} color={theme.palette.text.primary} fontFamily='GilroyBold' fontSize={26} lineHeight={1}>
							{subscription.maxUserLimit} SEATS
						</Typography>
					</Stack>
				)}
				{subscription.subscriptionType === SubscriptionPlansEnum.Enterprise && (
					<Stack mt={2}>
						<Typography fontSize={15} color={theme.palette.text.secondary} fontFamily='GilroySemiBold'>
							MINIMUM USERS
						</Typography>
						<Typography
							color={theme.palette.text.primary}
							fontFamily='GilroyBold'
							fontSize={26}
							lineHeight={1}
							alignItems='center'
							display='flex'
						>
							<IconImage src={infinity} width='36px' height='53px' /> SEATS
						</Typography>
					</Stack>
				)}
			</Stack>
			<DividerGray />
			<Stack direction='column' justifyContent='space-between' mt={2}>
				<Typography variant='h6' color={theme.palette.text.primary} fontFamily='GilroyRegular' textAlign='center' mb={2}>
					{period}
				</Typography>
				<Box display='flex' justifyContent='space-between'>
					<Box width='100%' mr={2.3}>
						<Button variant='rounded_contained__secondary_darken' fullWidth onClick={handleManageClick}>
							MANAGE
						</Button>
					</Box>
					<IconButtonSecondary
						aria-controls={open ? 'long-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-haspopup='true'
						onClick={handleClick}
					>
						<MoreVert color="secondary" />
					</IconButtonSecondary>
					<Menu
						id='long-menu'
						MenuListProps={{
							'aria-labelledby': 'long-button',
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						PaperProps={{
							style: {
								maxHeight: 216,
								width: '20ch',
							},
						}}
					>
						<MenuItem onClick={downloadInvoice}>
							<ListItemIcon>
								<FileDownloadIcon style={{ color: '#9945FF' }} fontSize='small' />
							</ListItemIcon>
							<ListItemText>
								<Typography fontSize={16} color={theme.palette.text.secondary} fontFamily='GilroySemiBold'>
									Download Invoice
								</Typography>
							</ListItemText>
						</MenuItem>
						<MenuItem onClick={handleUpgradeClick}>
							<ListItemIcon>
								<FileUploadIcon style={{ color: '#0FF194' }} fontSize='small' />
							</ListItemIcon>
							<ListItemText>
								<Typography fontSize={16} color={theme.palette.text.secondary} fontFamily='GilroySemiBold'>
									Upgrage
								</Typography>
							</ListItemText>
						</MenuItem>
						<MenuItem onClick={handleUnsubscribeClick}>
							<ListItemIcon>
								<PlaylistRemoveIcon style={{ color: '#FF6943' }} fontSize='small' />
							</ListItemIcon>
							<ListItemText>
								<Typography fontSize={16} color={theme.palette.text.secondary} fontFamily='GilroySemiBold'>
									Unsubscribe
								</Typography>
							</ListItemText>
						</MenuItem>
					</Menu>
					<ConfirmationDialogRaw id='ringtone-menu' keepMounted open={openDialog} onClose={handleCloseDialog} />
				</Box>
			</Stack>
		</Stack>
	)
}
