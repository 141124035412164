import { useContext } from 'react'
import { SubscriptionsContext } from '../context'
import type { TSubscriptionsContext } from '../context'

export function useSubscriptionsContext(): TSubscriptionsContext {
	const context = useContext(SubscriptionsContext)

	if (context === undefined) throw Error('Must be descendant of SubscriptionsContextProvider')

	return context
}
