import { subscriptionsReducer } from '../../state'
import type { State, Actions } from '../../state'
import { useMemo, useReducer } from 'react'
import { initValues } from '../../state/initValues'
import { AsyncDispatch, wrapAsync } from '@Utils'

export function useData() {
	const [state, dispatch] = useReducer(subscriptionsReducer, initValues)

	const asyncDispatch = useMemo(() => wrapAsync(dispatch), [dispatch])

	const value: [State, AsyncDispatch<Actions>] = [state, asyncDispatch]

	return {
		value,
	}
}
