import { ReportingTable } from '../../components/Table'
import {
	ReportingCards,
	ReportingFiltersWithTable,
	StyledReportingsGrid,
	StyledReportingsTableHeaderStack,
} from '../../components'
import { useData } from '../hooks'

export function ParticipantsReportsContent() {
	const { cards, noTabs, tableHeadingTitles, tableRows, searchPlaceholder, totalPages } = useData()

	return (
		<StyledReportingsGrid>
			<StyledReportingsTableHeaderStack>
				<ReportingCards cards={cards} />
			</StyledReportingsTableHeaderStack>
			<ReportingFiltersWithTable
				filtersTitles={[]}
				filtersSubTitles={[]}
				heading='TRAINING PARTICIPANTS'
				searchPlaceholder={searchPlaceholder}
				noTabs={noTabs}
				totalPages={totalPages}
				entity='participants'
			>
				<ReportingTable tableHeadingTitles={tableHeadingTitles} tableRows={tableRows} entity='participants' />
			</ReportingFiltersWithTable>
		</StyledReportingsGrid>
	)
}
