import { RequestService } from '@Services'
import { URLS } from '@Utils'
import type { ReportingEffectResponse } from '../types'
import type { TrainingsFilters, TrainingStatuses } from '../TrainingsReports/typings'
import { fetchTrainingsStatusesReportingActionCreator } from '../state/Actions'
import { initialStatuses } from '../state'

export async function fetchTrainingsStatuses(filters: TrainingsFilters): Promise<ReportingEffectResponse> {
	const preparedFilters = Object.fromEntries(Object.entries(filters).filter((entry) => !!entry[1])) as TrainingsFilters

	try {
		const response = await RequestService.get<TrainingsFilters, TrainingStatuses>(
			URLS.trainingsStatusesReports,
			preparedFilters,
			true,
		)

		if (response.status === 200) {
			return { status: 'success', action: fetchTrainingsStatusesReportingActionCreator(response.data) }
		}

		return { status: 'error', action: fetchTrainingsStatusesReportingActionCreator(initialStatuses) }
	} catch (err) {
		return { status: 'error', action: fetchTrainingsStatusesReportingActionCreator(initialStatuses) }
	}
}
