import { useMemo } from 'react'
import type { CardOptions } from '@Types'
import { useTheme } from '@mui/material'
import { SubscriptionPlansEnum } from '@Enums'

export function useSubscriptionsCardsData() {
	const theme = useTheme()
	return useMemo(
		() => ({
			standard: {
				users: '',
				title: 'Demo',
				description: 'Try out one of our VR learning activities in its entirety to test how useful VR learning can be at home or in class.',
				bottomText: '',
				price: 0,
				seats: 0,
				seatPrice: 0,
				type: 'common',
				bgcolor: 'transparent',
				width: '384px',
				height: '503px',
				marginY: '',
				marginTop: '',
				buttonVariant: 'primary_btn_large',
				showContactInfo: true,
				showLabel: false,
				plan: SubscriptionPlansEnum.Standard,
			} as CardOptions,
			pro: {
				users: '',
				title: 'Personal',
				description: 'Unlimited access to all our VR lessons for biology, chemistry and physics.',
				bottomText: 'PER MONTH, BILLED ANNUALLY',
				price: 18,
				seats: 1,
				seatPrice: 18,
				type: 'bestseller',
				width: '384px',
				height: '503px',
				marginY: '',
				marginTop: '',
				buttonVariant: 'primary_btn_large',
				showContactInfo: false,
				showLabel: true,
				plan: SubscriptionPlansEnum.Pro,
			} as CardOptions,
			enterprise: {
				users: '',
				title: 'School',
				description: 'Get unlimited VR access for any number of students, from one class to the full year group.',
				subDescription: '',
				bottomText: '',
				price: 0,
				seats: 0,
				seatPrice: 0,
				bgcolor: 'transparent',
				width: '384px',
				height: '503px',
				marginTop: '',
				buttonVariant: 'primary_btn_large',
				showContactInfo: true,
				showLabel: false,
				plan: SubscriptionPlansEnum.Enterprise,
			} as CardOptions,
		}),
		[],
	)
}
