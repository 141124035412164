import { ReportingState } from '../../typings'
import { SetTrainingsFilterPayload } from './typings'

export const setTrainingsFilterProducer = (
	state: ReportingState,
	payload: SetTrainingsFilterPayload,
): ReportingState => ({
	...state,
	trainingsFilters: { ...state.trainingsFilters, [payload.name]: payload.value },
})
