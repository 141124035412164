import type { SubscriptionUser } from '../../../typings'
import type { State } from '../../typings'

export const fetchSubscriptionUsersProducer = (
	state: State,
	payload: { users: SubscriptionUser[]; total: number },
): State => ({
	...state,
	subscriptionUsers: payload.users,
	subscriptionUsersTotal: payload.total,
})
