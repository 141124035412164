import { useTrainingsContext } from '../../../context'
import { useToken } from '@Hooks'

export function useData() {
	const [
		{
			trainingsInfo: { new: newTrainings, inProgress, all, completed, physics, chemistry, biology },
			filters: { status, type },
		},
	] = useTrainingsContext()
	const { withOculus } = useToken()

	return {
		newTrainings,
		inProgress,
		completed,
		all,
		physics,
		chemistry,
		biology,
		withOculus,
		filterStatus: status,
		filterType: type
	}
}
