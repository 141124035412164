import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import BusinessIcon from '@mui/icons-material/Business'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { Field, Form, Formik, useFormikContext } from 'formik'
import { TextField } from '@Components'
import { useState } from 'react'
import { useDownloadValidation, useHandlers, useInitialValues } from '../components/hooks'
import { DownloadInvoiceForm } from './DownloadInvoiceForm'
import { Circles } from 'react-loader-spinner'
import { DialogStyled } from './DialogStyled'

export type DownloadInvoiceContext = {
	companyName: string
	address: string
	city: string
	zipcode: string
	phoneNumber: string
	email: string
}

export interface ConfirmationDialogRawProps {
	id: string
	keepMounted: boolean
	open: boolean
	onClose: (value?: string) => void
}

export function ConfirmationDialogRaw(props: ConfirmationDialogRawProps) {
	const theme = useTheme()

	const initialValues = useInitialValues()

	const [loading, setLoading] = useState(false)
	const [serverError, setServerError] = useState(false)
	const { handleSubmit } = useHandlers(setServerError, setLoading)

	const { onClose, open, ...other } = props

	const handleCancel = () => {
		onClose()
		console.log('Hn calcel ')
	}

	const validationSchema = useDownloadValidation()

	return (
		<DialogStyled
			maxWidth='xs'
			disableScrollLock={true}
			open={open}
			{...other}
		>
			{loading && (
				<Stack
					position='absolute'
					top={0}
					right={0}
					bottom={0}
					left={0}
					alignItems='center'
					justifyContent='center'
					zIndex={1000}
				>
					<Circles height={120} width={120} color={theme.palette.grey.A200} ariaLabel='loading' />
				</Stack>
			)}
			<DialogTitle>
				<Stack direction='row' spacing={2} mb={1.5} alignItems='center' mt={2} mx={0.6}>
					<Stack color={theme.palette.success.main} alignItems='center' justifyContent='center'>
						<BusinessIcon></BusinessIcon>
					</Stack>
					<Typography variant='h5' color={theme.palette.text.primary} fontFamily='GilroyBold' flex={1}>
						Detail about your company
					</Typography>
					<Stack color={theme.palette.text.secondary} alignItems='center' justifyContent='center'>
						<CloseIcon fontSize='medium' onClick={handleCancel} cursor='pointer' />
					</Stack>
				</Stack>
			</DialogTitle>

			<DialogContent >
				<Box mx={0.6}>
					<Typography variant='body1' color={theme.palette.text.secondary} fontFamily='GilroyMedium' mb={3}>
						Please fill in your company details. This is necessary to fill in the details on the invoice.
					</Typography>
					<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
						<DownloadInvoiceForm handleCancel={handleCancel} showError={serverError} />
					</Formik>
				</Box>
			</DialogContent>
		</DialogStyled>
	)
}
