import { RequestService } from '@Services'
import type { SubscriptionsEffectResponse, SubscriptionUser, SubscriptionUsersFilters } from '../typings'
import { URLS } from '@Utils'
import { fetchSubscriptionUsersActionCreator } from '../state'

export async function fetchSubscriptionUsersEffect(
	filters: SubscriptionUsersFilters,
): Promise<SubscriptionsEffectResponse> {
	const result = await RequestService.get<SubscriptionUsersFilters, { users: SubscriptionUser[]; total: number }>(
		URLS.fetchSubscriptionUsers,
		filters,
		true,
	)

	if (result.status === 200) {
		return { status: 'success', action: fetchSubscriptionUsersActionCreator(result.data) }
	}

	return { status: 'error', action: fetchSubscriptionUsersActionCreator({ users: [], total: 0 }) }
}
