import { DisplayEnum } from '@Enums'
import { Stack, styled } from '@mui/material'

export const HowItWorksOpacityCard = styled(Stack, { name: 'HowItWorksOpacityCard' })({
	width: '1180px',
  borderRadius: '30px 30px 0 0',
  backgroundColor: '#fff',
  opacity: 0.9,
  paddingTop: '65px',
  margin: '-128px auto 0',
  textAlign: 'center',
  [`@media (max-width: ${DisplayEnum.tablet}px)`]: {
		width: '100%',
    padding: '40px 30px 0',
    boxSizing: 'border-box'
	},
  [`@media (max-width: ${DisplayEnum.mobile}px)`]: {
    padding: '20px 20px 0',
	}
})