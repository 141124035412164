import ArrowLeft from '@Assets/left-arrow.svg'

import { Footer, Header, IconImage } from '@Components'
import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import {useGoToPreviousPage, useIsLoggedIn, useSubscriptionsCardsData} from '@Hooks'
import { Card } from './components'
import { SubscriptionsPageStack } from '../components/SubscriptionsPageStack'
import { DisplayEnum } from 'shared/enums/display.enum'
import {useEffect} from "react";

export function AllSubscriptionsPage() {
	const cards = useSubscriptionsCardsData()
	const theme = useTheme()
	const goToPrevious = useGoToPreviousPage()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const isLoggedIn = useIsLoggedIn();

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	// useFetchSubscriptions()

	return (
		<Stack bgcolor={theme.palette.background.default}>
			<SubscriptionsPageStack>
				<Header hideNavigationMenu={isLoggedIn} />
				<Stack maxHeight={xlDisplay ? 1440 : 'auto'} mx='auto'>
					<Stack direction='row' mt={8} width='100%' mb={xlDisplay ? 11 : 3} px={mobile ? 3 : 0}>
						<Box mt={0.5}>
							<Button variant='courses_back' onClick={goToPrevious}>
								<IconImage src={ArrowLeft} alt='arrow' height='16px' />
							</Button>
						</Box>
						<Stack ml={2} direction='column'>
							<Typography fontSize={18} color={theme.palette.success.main} fontFamily='GilroySemiBold' textAlign='start'>
								PERSONAL SPACE
							</Typography>

							<Typography
								fontSize={mobile ? 24 : 48}
								color={theme.palette.text.primary}
								mt={-0.3}
								fontFamily='GilroyBold'
								textAlign='start'
							>
								Select Subscription
							</Typography>
						</Stack>
					</Stack>

					<Stack
						direction={xlDisplay ? 'row' : 'column'}
						spacing={2}
						alignItems='center'
						justifyContent='center'
						mb={xlDisplay ? 17 : 0}
						p={mobile ? '0 30px' : 0}
					>
						{Object.values(cards).map((card, index) => (
							<Card options={card} key={`card_${index}`} />
						))}
					</Stack>
				</Stack>
				<Footer onMainPage={false} />
			</SubscriptionsPageStack>
		</Stack>
	)
}
