import { Button, Stack, Typography } from '@mui/material'
import Arrow from '@Assets/360_black.svg'
import { useHandlers } from './hooks'
import { IconImage } from '@Components'

export function EmptySubscriptions() {
	const { handleBuyClick } = useHandlers()

	return (
		<Stack alignContent='center' justifyContent='center' alignItems='center' mt={8} mb={14.5}>
			<IconImage src={Arrow} alt='arrow' width='100px' />
			<Typography variant='h6' mt={2} mb={3} color='primary' fontFamily='GilroySemiBold'>
				NO ACTIVE SUBSCRIPTION
			</Typography>
			<Button variant='rounded_contained__black_text' onClick={handleBuyClick}>
				SELECT & BUY
			</Button>
		</Stack>
	)
}
