import { RolesEnum } from '@Enums'

export class RolesService {
	static availableForRole(allowedRoles: RolesEnum[]) {
		const currentRole = JSON.parse(localStorage.getItem('user') || '{}').role

		return allowedRoles.includes(currentRole)
	}

	static getRedirectionUrl(role: RolesEnum = RolesEnum.Guest) {
		const startPages = {
			[RolesEnum.User]: '/trainings',
			[RolesEnum.Guest]: '/trainings',
			[RolesEnum.SuperUser]: '/trainings',
			[RolesEnum.SuperAdmin]: '/subscriptions',
			[RolesEnum.Admin]: '/subscriptions',
		}

		return startPages[role]
	}
}
