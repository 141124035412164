import { Field, useFormikContext } from 'formik'
import { TextField } from '@Components'
import { Box, Typography } from '@mui/material'
import type { DownloadInvoiceContext } from '../DownloadInvoiceDialog'

export function CityField() {
	const { errors, touched, values } = useFormikContext<DownloadInvoiceContext>()

	return (
		<Box flex={1} position='relative' pb={4}>
			<Field
				id='city'
				name='city'
				label='CITY'
				type='text'
				placeholder=''
				component={TextField}
				defaultValue={values.city}
			/>
			{errors.city && touched.city ? (
				<Typography variant='caption' color='red' position='absolute' left={0} bottom={0}>
					{errors.city}
				</Typography>
			) : null}
		</Box>
	)
}
