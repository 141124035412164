import { Route, Routes } from 'react-router-dom'
import { ReportingContextProvider } from './context'
import { ParticipantsReportsPage } from './ParticipantsReports/ParticipantsReportsPage'
import { TrainingsReportsPage } from './TrainingsReports/TrainingsReportsPage'
import { RolesEnum } from '@Enums'
import { ProtectedRoute } from '@Components'
import { useIsLoggedIn } from '@Hooks'
import { TrainingsDetailsPage } from './TrainingsDetailsPage/TrainingsDetailsPage'
import { ParticipantsDetailsPage } from './ParticipantsDetailsPage/ParticipantsDetailsPage'

export function ReportingRoutes() {
	const isLoggedIn = useIsLoggedIn()

	return (
		<ReportingContextProvider>
			<Routes>
				<Route
					path='/reporting'
					element={
						<ProtectedRoute roles={[RolesEnum.Admin, RolesEnum.SuperAdmin]} isLoggedIn={isLoggedIn}>
							<TrainingsReportsPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/reporting/participants'
					element={
						<ProtectedRoute roles={[RolesEnum.Admin, RolesEnum.SuperAdmin]} isLoggedIn={isLoggedIn}>
							<ParticipantsReportsPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/reporting/details'
					element={
						<ProtectedRoute roles={[RolesEnum.Admin, RolesEnum.SuperAdmin]} isLoggedIn={isLoggedIn}>
							<TrainingsDetailsPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/reporting/participants/details'
					element={
						<ProtectedRoute roles={[RolesEnum.Admin, RolesEnum.SuperAdmin]} isLoggedIn={isLoggedIn}>
							<ParticipantsDetailsPage />
						</ProtectedRoute>
					}
				/>
			</Routes>
		</ReportingContextProvider>
	)
}
