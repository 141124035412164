import { useSubscriptionsContext } from '../../../context'
import { SubscriptionPlansEnum, SubscriptionStatusEnum } from '@Enums'
import { format } from 'date-fns'
import { useToken } from '@Hooks'

export function useData() {
	const [{ subscriptionUsers, subscription, subscriptionUsersTotal }] = useSubscriptionsContext()
	const user = useToken()

	const percent = `${Math.floor((subscription?.activeUsersCount || 0) / (subscription?.maxUserLimit || 1))}%`
	const period = `${format(new Date(subscription?.from || 0), 'MM/dd/yyyy')} - ${format(
		new Date(subscription?.to || 0),
		'MM/dd/yyyy',
	)}`

	const price = subscription?.subscriptionType === SubscriptionPlansEnum.Pro ? 18 : 0

	const pagesCount = Math.floor(subscriptionUsersTotal / 10) + (subscriptionUsersTotal % 10 ? 1 : 0)

	const isNotEnterprice = user.subscriptionPlan !== SubscriptionPlansEnum.Enterprise

	const disableUnsubscribe = subscription?.subscriptionStatus === 5

	return {
		subscriptionUsers,
		name: subscription?.name,
		total: subscription?.maxUserLimit ?? 0,
		type: subscription?.subscriptionType,
		description: subscription?.description,
		percent,
		period,
		price,
		pagesCount,
		isNotEnterprice,
		disableUnsubscribe,
	}
}
