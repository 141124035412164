import { Route, Routes } from 'react-router-dom'

import { SubscriptionPage } from './SubscriptionPage'
import { PaymentContextProvider } from './context'
import { OrderDetailsPage } from './OrderDetailPage'

export function PaymentRoutes() {
	return (
		<PaymentContextProvider>
			<Routes>
				<Route path='/payment' element={<OrderDetailsPage />} />
				<Route path='/payment/success' element={<SubscriptionPage />} />
			</Routes>
		</PaymentContextProvider>
	)
}
