import { useCallback } from 'react'
import type { ContactUs } from '../../../shared/typings/ContactUs'
import { contactusEffect } from '../../../shared/effects'

export function useHandlers(setServerError: (value: boolean) => void) {
	const handleSubmit = useCallback(async (values: ContactUs) => {
		if (!values.email || !values.name || !values.organization) return

		let mounted = true
		const response = await contactusEffect(values)

		if (!mounted) return

		if (response.status === 'error') {
			setServerError(true)
		} else {
			setServerError(false)
		}

		return () => {
			mounted = false
		}
	}, [setServerError])

	return {
		handleSubmit,
	}
}
