import { RequestService } from '@Services'
import type { PdfInfo } from '../typings'
import { URLS } from '@Utils'
import { fetchPdfInfoActionCreator } from '../state'

export async function fetchUserPdfInfo() {
	try {
		const response = await RequestService.get<undefined, PdfInfo>(URLS.fetchPdfInfo, undefined, true)

		if (response.status === 200) {
			return { status: 'success', action: fetchPdfInfoActionCreator(response.data) }
		}

		return { status: 'error', action: fetchPdfInfoActionCreator({}) }
	} catch (err: any) {
		return {
			status: 'error',
			error: Object.entries(err.response.data.errors)[0][1] as string,
			action: fetchPdfInfoActionCreator({}),
		}
	}
}
