import { DividerGray, IconImage } from '@Components'
import { Box, Button, CircularProgress, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import RotateImage from '@Assets/360_white.svg'
import SpinnerBottom from '@Assets/spinnerBG.svg'
import ArrowRight from '@Assets/right-arrow.svg'
import ArrowLeft from '@Assets/left-arrow.svg'

import { CourseContentTopBlock } from './CourseContentTopBlock'
import { CourseContentCenterBlock } from './CourseContentCenterBlock'
import { CoursesListItem } from './CoursesListItem'
import { useData, useOculusCode } from '../hooks'
import { TrainingStatusEnum, TrainingTypeEnum } from '../../utils/enums'
import { useEffect, useMemo, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade } from 'swiper'
import 'swiper/scss'
import 'swiper/scss/autoplay'
import 'swiper/scss/effect-fade'
import { useGoToPreviousPage } from '@Hooks'
import { useTrainingsContext } from '../../context'
import { DisplayEnum } from 'shared/enums/display.enum'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { CourseStatus } from '../../CoursesListPage/components/CourseStatus'
import { OculusCard } from './OculusCard'
import { ImgBanner } from '../../../../shared/components/ImgBanner'
import { BackButton } from 'shared/components/BackButton'

export function CourseContentComponent() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet))
	const { training, canGenerateCode, withOculus } = useData()

	// const goToPrevious = useGoToPreviousPage()
	const [timer, setTimer] = useState(0)
	const [
		{
			trainingsInfo: { trainings },
		},
	] = useTrainingsContext()
	const typeTitle = useMemo(() => {
		switch (training?.type) {
			case TrainingTypeEnum.Physics:
				return 'Physics'
			case TrainingTypeEnum.Biology:
				return 'Biology'
			case TrainingTypeEnum.Chemistry:
				return 'Chemistry'
		}
	}, [training?.type])

	useEffect(() => {
		const interval = setInterval(() => {
			setTimer(Date.now())
		}, 900000)

		return () => {
			clearInterval(interval)
		}
	}, [setTimer, timer])

	return (
		<Stack padding={0} boxSizing='border-box' mx='auto' mb={xlDisplay ? 14 : 8} width='100%' position='relative'>
			{training?.images && training.images && training.images[0] && (
				<Box>
					<ImgBanner img={training.images[0]} height={mobile ? '255px' : '533px'} />
				</Box>
			)}

			<Stack
				direction={mobile ? 'column' : 'row'}
				mt={mobile ? '-70px' : 0}
				pl={mobile ? 0 : tablet ? '55%' : '42%'}
				position='relative'
				width={mobile ? '100vw' : tablet ? 'fit-content' : 730}
			>
				<Box
					position={mobile ? 'initial' : 'absolute'}
					left={mobile ? 0 : tablet ? 10 : 130}
					borderRadius={10}
					bgcolor='#fff'
					boxShadow='0px 24px 76px #0000001C'
					py={5}
					px={mobile ? 3 : 6}
					width={270}
					top={canGenerateCode ? -277 : -100}
					mx={'auto'}
				>
					{canGenerateCode && <OculusCard timer={timer} canGenerateCode={canGenerateCode} />}
					<Typography fontFamily='gilroySemiBold' fontSize={22} color={theme.palette.text.primary} mb={1}>
						Learning Objectives:
					</Typography>
					{(training?.objectives || []).map((objective, index) => (
						<Typography fontSize={18} color={theme.palette.text.secondary} mt={2} fontFamily='GilroySemiBold'>
							{objective}
						</Typography>
					))}
				</Box>

				<Stack mt={4} maxWidth={mobile ? '100vw' : tablet ? '42vw' : '90vw'}>
					<Stack direction='row' alignItems='center' spacing={1} px={mobile ? 1.5 : 0}>
						<Box py={0.5} px={0.7} borderRadius='4px' border='1px solid #D2D2D2'>
							<Typography
								fontFamily='GilroyMedium'
								color={theme.palette.text.secondary}
								fontSize={14}
								lineHeight='16px'
							>
								{typeTitle}
							</Typography>
						</Box>
						{withOculus && (
							<CourseStatus showStatus={true} status={training?.status || 0} timeComplete={training?.timeComplete} />
						)}
					</Stack>
					<Stack direction='row' alignItems='center' mt={withOculus ? 1 : 4} px={mobile ? 1.5 : 0}>
						<BackButton path='/trainings' />
						<Typography
							fontSize={mobile ? 24 : 44}
							fontFamily='GilroyBold'
							color='#000000'
							ml={1}
						>
							{training?.title}
						</Typography>
					</Stack>

					<Typography
						fontSize={18}
						mt={4}
						mb={3}
						fontFamily='GilroySemiBold'
						lineHeight={1.8}
						color={theme.palette.text.secondary}
						px={mobile ? 1.5 : 0}
					>
						<div dangerouslySetInnerHTML={{ __html: training?.description || '' }}></div>
					</Typography>

					<Stack direction='row' gap={3} flexWrap='wrap' justifyContent='center'>
						{(training?.images || []).map((image, idx) => (
							<IconImage src={image}
									   key={`training_img${idx}`}
									   height={ mobile ? '203px' : 'auto' }
									   width={ mobile || tablet ? '351px' : '702px' }
									   borderRadius='6px'
							/>
						))}
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	)
}
