import { Stack, styled } from '@mui/material'

export const LeftPartModalStack = styled(Stack, { name: 'LeftPartModalStack' })({
	// background: '#1e1c30',
	border: '1px solid #ffffff57',
	borderRadius: '10px',
	padding: '30px',
	boxSizing: 'border-box',
	[`@media (max-width: 767px)`]: {
		padding: 0,
	},
})
