import type { FetchTrainings } from './typings'
import type { TrainingsReportingTableRow } from '../../../TrainingsReports/components/TrainingsTableContent'

export const fetchTrainingsActionCreator = (payload: {
	total: number
	items: TrainingsReportingTableRow[]
}): FetchTrainings => ({
	type: 'reporting/fetchTrainings',
	payload,
})
