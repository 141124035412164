import { DisplayEnum } from "@Enums"
import { Stack, useMediaQuery, useTheme } from "@mui/material"
import { TrainingsPageContent, TrainingsPageHeader } from "."

export function TrainingsPageComponent() {
    const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	return (
		<Stack minWidth={mobile ? 'calc(100% - 60px)' : 1180} mx='auto' mb={9.7}>
			<TrainingsPageHeader />
            <TrainingsPageContent />
		</Stack>
	)
}
