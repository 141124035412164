import { Stack, styled } from '@mui/material'
import Background from '@Assets/buble1.svg'

export const Buble1 = styled(Stack, { name: 'Buble1' })({
	backgroundImage: `url('${Background}')`,
	backgroundSize: '100% 100%',
	position: 'absolute',
  width: '1000px',
  height: '1000px'
})
