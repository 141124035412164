import { Typography, useTheme } from '@mui/material'

export function LinksToOtherWebsites() {
	const theme = useTheme()

	return (
		<>
			<Typography fontFamily='GilroyBold' variant='h4' color={theme.palette.text.primary}>
				<u>Links to Other Websites</u>
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				Our Service may contain links to third-party web sites or services that are not owned or controlled by the
				Company.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices
				of any third party web sites or services. You further acknowledge and agree that the Company shall not be
				responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in
				connection with the use of or reliance on any such content, goods or services available on or through any such
				web sites or services.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or
				services that You visit.
			</Typography>
		</>
	)
}
