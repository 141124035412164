import { PublicClientApplication } from "@azure/msal-browser";
import { msalAuth } from "features/auth/effects/msalAuth.effect";
import {useNavigate} from "react-router-dom";
import {RolesService} from "../services/roles.service";
import type {User} from "@Types";

export function useMsalLogin(mobile: boolean, msalInstance: PublicClientApplication) {
    const navigate = useNavigate();
    const scopes = ['user.read'];
    const login = async () => {
        localStorage.setItem('login', 'msal')
        if (mobile) {
            msalInstance.loginRedirect({ scopes });
        } else {
            const loginResponse = await msalInstance.loginPopup({ scopes });
            if (loginResponse && loginResponse.account) {
                localStorage.setItem('user', JSON.stringify({ token: loginResponse.accessToken }))
                const { action, status } = await msalAuth();

                if (!action.payload?.subscriptionId) {
                    navigate('/subscriptions/all')
                    return;
                }

                if (status !== 'error') {
                    navigate(RolesService.getRedirectionUrl((action?.payload as User)?.role))
                }
            }
        }
    };
    return login;
}
