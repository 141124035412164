import { RequestService } from '@Services'
import { URLS } from '@Utils'
import { fetchSubscriptionsActionCreator } from '../state'
import type { Subscription, SubscriptionsEffectResponse } from '../typings'

export async function fetchSubscriptionsEffect(): Promise<SubscriptionsEffectResponse> {
	const result = await RequestService.get<null, Subscription>(URLS.fetchSubscription, null, true)

	if (result.status === 200) {
		return { status: 'success', action: fetchSubscriptionsActionCreator(result.data) }
	}

	return { status: 'error', action: fetchSubscriptionsActionCreator(null) }
}
