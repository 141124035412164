import { styled, AppBar } from '@mui/material'
import BgImage from '@Assets/clvr_header_bg_image.svg'

export const StyledBar = styled(AppBar, { name: 'StyledBar' })({
	background: '#fff',
	backgroundImage: `url('${BgImage}')`,
	backgroundRepeat: 'no-repeat',
	borderRadius: '0px 0px 30px 30px',
	backgroundPosition: '161% 72%',
	position: 'static',

	'@media screen and (max-width: 767px)': {
		backgroundPosition: '-35% 72%',
	}
})
