import { Button, Dialog, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import Trash from '@Assets/trash.svg'
import { DisplayEnum } from 'shared/enums/display.enum'
import { useSubscriptionsContext } from 'features/subscriptions/context'
import { useHandlers } from '../hooks'
import { StyledStack } from './StyledStack'
import { IconImage } from '@Components'

export function ConfirmationDeleteUserPopup() {
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const [{ deleteUserIsOpen, deleteUserCandidate }] = useSubscriptionsContext()
	const { handleDeleteUser, handleCloseConfirmationDeleteUser } = useHandlers()

	return (
		<Dialog open={deleteUserIsOpen} disableScrollLock={true}>
			<StyledStack>
				<Stack direction={mobile ? 'column' : 'row'} alignItems={mobile ? 'center' : 'start'} mb={3}>
					<IconImage src={Trash} alt='delete' width='47px' />
					<Stack ml={mobile ? 0 : 3} mt={mobile ? 1 : 0} textAlign={mobile ? 'center' : 'left'}>
						<Typography color='primary' fontFamily='GilroyBold' fontSize={24} lineHeight={1}>
							Are you sure you want to delete this user?
						</Typography>
						<Typography color={theme.palette.text.secondary} fontFamily='GilroyMedium' fontSize={16}>
							Once a user has been deleted, it cannot be reactivated.
						</Typography>
					</Stack>
				</Stack>
				<Stack direction={'row'} justifyContent={'center'}>
					<Button variant='confirmation_ok' onClick={() => handleDeleteUser(deleteUserCandidate)}>
						YES, DELETE
					</Button>
					<Button variant='confirmation_cancel' onClick={handleCloseConfirmationDeleteUser}>
						CANCEL
					</Button>
				</Stack>
			</StyledStack>
		</Dialog>
	)
}
