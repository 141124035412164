import { Field, useFormikContext } from 'formik'
import { TextField } from '@Components'
import { Box, Typography } from '@mui/material'
import type { DownloadInvoiceContext } from '../DownloadInvoiceDialog'

export function TelephoneField() {
	const { errors, touched, values } = useFormikContext<DownloadInvoiceContext>()

	return (
		<Box flex={1} position='relative' pb={4}>
			<Field
				id='phoneNumber'
				name='phoneNumber'
				label='TELEPHONE'
				type='text'
				placeholder=''
				component={TextField}
				defaultValue={values.phoneNumber}
			/>
			{errors.phoneNumber && touched.phoneNumber ? (
				<Typography variant='caption' color='red' position='absolute' left={0} bottom={0}>
					{errors.phoneNumber}
				</Typography>
			) : null}
		</Box>
	)
}
