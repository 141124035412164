export const initValues = {
	subscription: null,
	payments: [],
	addUserIsOpen: false,
	deleteUserIsOpen: false,
	deleteUserCandidate: null,
	subscriptionUsers: [],
	subscriptionUsersTotal: 0,
	current: '',
	pdfInfo: {},
}
