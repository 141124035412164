import { RequestService } from '@Services'
import type { Payment, SubscriptionsEffectResponse } from '../typings'
import { fetchPaymentsActionCreator } from '../state'
import { URLS } from '@Utils'

export async function fetchPaymentsEffect(): Promise<SubscriptionsEffectResponse> {
	const result = await RequestService.get<null, Payment[]>(URLS.fetchPayments, null, true)

	if (result.status === 200) {
		return { status: 'success', action: fetchPaymentsActionCreator(result.data) }
	}

	return { status: 'error', action: fetchPaymentsActionCreator([]) }
}
