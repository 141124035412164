import { msalAuth } from "features/auth/effects/msalAuth.effect";
import { useMsalInstance } from "./useMsalInstance";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {RolesService} from "../services/roles.service";
import type {User} from "@Types";

export function useHandleRedirectPromise() {
    const msalInstance = useMsalInstance();
    // const navigate = useNavigate()

    useEffect(() => {
        msalInstance.handleRedirectPromise().then(async (response) => {
            console.log(response)
            if (response) {
                console.log('User is authenticated.');
                localStorage.setItem('user', JSON.stringify({ token: response.accessToken }))
                const { action, status } = await msalAuth();
                // Redirect to the dashboard or homepage

                if (status !== 'error') {
                    // navigate(RolesService.getRedirectionUrl((action?.payload as User)?.role))
                    window.location.replace(`${window.location.origin}${RolesService.getRedirectionUrl((action?.payload as User)?.role)}`)
                }
            } else {
                console.log('User is not authenticated.');
                // Redirect to the login page
            }
        }).catch((error) => {
            console.error('Error during handleRedirectPromise:', error);
            // Handle error
        });
    }, [msalInstance])
}
