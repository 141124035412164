import PriceCardBG from '@Assets/PriceCardBG.png'
import MailForward from '@Assets/email_forward_mail.svg'

import { Box, Stack, Typography, Button, useTheme, useMediaQuery } from '@mui/material'
import { DividerGray, IconImage } from '@Components'
import { useHandlers } from './hooks'
import type { CardOptions } from '@Types'
import { SubscriptionPlansEnum } from '@Enums'
import { DisplayEnum } from 'shared/enums/display.enum'
import { CoverBox } from './components'
import { Fragment } from 'react'

type Props = {
	options: CardOptions
	disabled?: boolean
}

export function PriceCard(props: Props) {
	const { options, disabled } = props
	const showOverlay = disabled
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const bgcolor = options.bgcolor
	const showBestsellerLabel = options.showLabel
	const buttonVariant = options.buttonVariant
	const marginTop = options.marginTop
	const width = options.width
	const height = options.height
	const marginY = options.marginY
	const showContactLabel = options.showContactInfo
	const showPrices = !options.showContactInfo
	const enterpriseCard = options.plan === SubscriptionPlansEnum.Enterprise
	const standardCard = options.plan === SubscriptionPlansEnum.Standard
	const proCard = options.plan === SubscriptionPlansEnum.Pro

	const { handleBuyClick } = useHandlers(options.plan)

	return (
		<Fragment>
			<Stack
				bgcolor={bgcolor}
				width={mobile ? '100%' : width}
				height={mobile ? '381px' : height}
				borderRadius='16px'
				boxSizing='border-box'
				position='relative'
				overflow='hidden'
				p={mobile ? 2 : 4}
				zIndex={4}
				mb={xlDisplay ? 0 : 3}
			>
				<Stack>
					<Stack direction='column' justifyContent='space-between' height='100%'>
						<Stack>
							<Typography
								fontSize={30}
								fontFamily='GilroyBold'
								color={theme.palette.text.primary}
								mb={2}
								textAlign='center'
							>
								{options.title}
							</Typography>
							<Typography
								fontSize={18}
								fontFamily='GilroyMedium'
								color={theme.palette.text.secondary}
								textAlign='center'
								lineHeight={1.2}
							>
								{options.description}
							</Typography>
						</Stack>

						{standardCard && (
							<Stack mx='auto' mt={mobile ? 2 : 6} mb={mobile ? 5.6 : 9.6}>
								<Typography fontSize={mobile ? 35 : 50} fontFamily='GilroyBold' color={theme.palette.text.primary}>
									Free
								</Typography>
							</Stack>
						)}

						{proCard && (
							<Stack>
								<Stack mt={marginY} mb={mobile ? 0.5 : 4.5}>
									<Stack mx='auto' direction='row' alignItems='baseline' mt={mobile ? 3.9 : 7.9}>
										<Typography fontSize={mobile ? 35 : 50} mr={0.5} fontFamily='GilroyBold' color={theme.palette.text.primary}>
											£
										</Typography>
										<Typography fontSize={mobile ? 35 : 50} fontFamily='GilroyBold' color={theme.palette.text.primary}>
											{options.price}
										</Typography>
									</Stack>
									<Typography
										fontSize={18}
										fontFamily='GilroyMedium'
										color={theme.palette.text.secondary}
										textAlign='center'
									>
										PER MONTH
									</Typography>
									<Typography
										fontSize={18}
										fontFamily='GilroyMedium'
										color={theme.palette.text.secondary}
										textAlign='center'
									>
										BILLED ANNUALLY
									</Typography>
								</Stack>
							</Stack>
						)}

						{enterpriseCard && (
							<Stack mx='auto' mt={mobile ? 3.4 : 7.4} mb={mobile ? 6.4 : 10.4}>
								<Typography fontSize={mobile ? 35 : 50} fontFamily='GilroyBold' color={theme.palette.text.primary}>
									Contact us
								</Typography>
							</Stack>
						)}
					</Stack>

					<Stack mt={1.2}>
						<Button variant={buttonVariant} onClick={handleBuyClick}>
							{standardCard ? 'Try Demo' : enterpriseCard ? 'Contact us' : 'Select & Buy'}
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</Fragment>
	)
}
