import { Button, Stack } from "@mui/material"
import { MouseEventHandler } from "react"

type Props = {
    up: MouseEventHandler<HTMLButtonElement> | undefined,
    down: MouseEventHandler<HTMLButtonElement> | undefined,
}

export function SharedFiltersArrows(props: Props) {
    const { up, down } = props

    return (
        <Stack rowGap={0.3}>
            <Button variant="filter_arrow_up" onClick={up}>       
            </Button>
            <Button variant="filter_arrow_down" onClick={down}> 
            </Button>
        </Stack>
    )
}