import { Stack } from '@mui/material'
import { ReportingCard } from './Card'
import type { TrainingStatusCard } from '../TrainingsReports/typings'

type Props = {
	cards: TrainingStatusCard[]
}

export function ReportingCards(props: Props) {
	const { cards } = props

	return (
		<Stack columnGap={2} direction='row'>
			{cards.map((card, index) => (
				<ReportingCard card={card} count={card.count} totalCount={card.totalCount} key={`reporting_card_${index}`} />
			))}
		</Stack>
	)
}
