import type { ReportingState } from '../../typings'
import type { ParticipantsTypesData } from '../../../ParticipantsReports/typings'

export const fetchParticipantTypesProducer = (
	state: ReportingState,
	payload: ParticipantsTypesData,
): ReportingState => ({
	...state,
	participantsTypes: payload,
})
