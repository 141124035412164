import { Table, TableBody, TableContainer } from '@mui/material'
import { Fragment } from 'react'
import { TrainingsReportingCategoriesRow, TrainingsReportingRow } from '.'

import { TrainingsReportingTableRow } from './typings'

type Props = {
	tableHeadingTitles: {
		title: string
		icon: string
		hasFilters: boolean
	}[]
	tableRows: TrainingsReportingTableRow[]
}

export function TrainingsReportingTable(props: Props) {
	const { tableHeadingTitles, tableRows } = props

	return (
		<Fragment>
			<TableContainer>
				<Table aria-label='table'>
					<TrainingsReportingCategoriesRow tableHeadingTitles={tableHeadingTitles} />
					<TableBody>
						{(tableRows || []).map((tableRow, index) => (
							<TrainingsReportingRow tableRow={tableRow} key={`row_${index}`} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fragment>
	)
}
