import { Table, TableBody, TableContainer } from '@mui/material'
import { Fragment } from 'react'
import { ReportingCategoriesRow } from './ReportingCategoriesRow'
import { ReportingRow } from './ReportingRow'
import type { ReportingTableRow } from './typings'
import type { ParticipantTableRow } from '../../ParticipantsReports/typings'
import { ParticipantsTableRow } from '../../ParticipantsReports/components'

type Props =
	| {
			tableHeadingTitles: {
				title: string
				hasFilters?: boolean
			}[]
			tableRows: ReportingTableRow[]
			entity: 'trainings'
	  }
	| {
			tableHeadingTitles: {
				title: string
				hasFilters?: boolean
			}[]
			tableRows: ParticipantTableRow[]
			entity: 'participants'
	  }

export function ReportingTable(props: Props) {
	const { tableHeadingTitles, tableRows, entity } = props

	return (
		<Fragment>
			<TableContainer>
				<Table aria-label='table'>
					<ReportingCategoriesRow tableHeadingTitles={tableHeadingTitles} />
					<TableBody>
						{tableRows.map((tableRow) =>
							entity === 'trainings' ? (
								<ReportingRow tableRow={tableRow as ReportingTableRow}></ReportingRow>
							) : (
								<ParticipantsTableRow tableRow={tableRow as ParticipantTableRow} />
							),
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Fragment>
	)
}
