import { Pagination, Stack } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useCallback } from 'react'


type Props = {
	name: string
	onChange: (page: number, name: string) => void
	pagesCount?: number
}

export function SharedPagination(props: Props) {
	const { name, onChange, pagesCount } = props
	

	const handlePageChange = useCallback(
		async (event: ChangeEvent<unknown>, page: number) => {
			await onChange(page, name)
		},
		[name],
	)

	return (
		<Stack p={2} direction='row' justifyContent='end'>
			<Pagination
				count={pagesCount}
				variant='outlined_dark'
				shape='rounded'
				size='large'
				showFirstButton
				showLastButton
				onChange={handlePageChange}
			/>
		</Stack>
	)
}
