import Video from '@Assets/howItWorks.mp4'
import Computer from '@Assets/computer.png'
import Collaborative from '@Assets/collaborative.png'
import Safe from '@Assets/safe.png'
import ArrowRightCircle from '@Assets/arrow-right-circle.svg'
import videoImg from '@Assets/video_img.png'

import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { IconImage } from '@Components'
import { useHandlers } from './hooks'
import { DisplayEnum } from 'shared/enums/display.enum'
import { HowItWorksVideo } from './HowItWorksVideo'
import { HowItWorksOpacityCard } from './HowItWorksOpacityCard'
import { HowItWorksCard } from './HowItWorksCard'
import { Buble1 } from './bubles/buble1'
import { Buble3 } from './bubles/buble3'

export function HowItWorks() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { handleSubscribe, handleContactUs } = useHandlers()

	return (
		<Stack bgcolor='#F4F4F3' position='relative'>
			<Stack mt='-20px'>
				{xlDisplay && (
					<HowItWorksVideo autoPlay muted loop>
						<source src={Video} type='video/mp4' />
					</HowItWorksVideo>
				)}
				{!xlDisplay && (
					<Box>
						<IconImage src={videoImg} alt='picture' width='100%'/>
					</Box>
				)}
			</Stack>
			<HowItWorksOpacityCard id='how-it-works'>
				<Typography fontSize='16px' fontFamily='GilroyBold' variant='h3' color='rgba(30, 28, 48, 0.7)'>
					HOW IT WORKS
				</Typography>
				<Typography
					fontSize={mobile ? '26px' : '38px'}
					fontFamily='GilroyBold'
					variant='h3'
					color='rgba(30, 28, 48, 1)'
				>
					Explore our platform
				</Typography>
			</HowItWorksOpacityCard>
			<HowItWorksCard>
				<Typography fontSize='22px' fontFamily='GilroySemiBold' variant='h3' color='rgba(30, 28, 48, 1)' mb='30px'>
					It’s not a normal classroom…it's better.
				</Typography>
				<Typography
					fontSize='18px'
					fontFamily='GilroySemiBold'
					variant='h3'
					color='rgba(30, 28, 48, 0.7)'
					mx={xlDisplay ? '120px' : 'auto'}
					mb='30px'
				>
					Our lessons are made by teachers with both teachers and students in mind. We’ve looked at every limitation of
					the standard classroom and reinvented our lessons to combat them in an easy to use and entertaining way.
				</Typography>
				<Typography
					fontSize='18px'
					fontFamily='GilroySemiBold'
					variant='h3'
					color='rgba(30, 28, 48, 0.7)'
					mx={xlDisplay ? '120px' : 'auto'}
					mb='55px'
				>
					We don’t throw the whole guidebook out of the window, CleVR is designed in accordance with the most effective
					and up-to-date pedagogical practices to give you the perfect tool to take on your favourite STEM subjects.
				</Typography>
				<Stack
					direction={mobile ? 'column' : 'row'}
					justifyContent={xlDisplay ? 'space-between' : 'center'}
					mx={xlDisplay ? '90px' : 'auto'}
					flexWrap={xlDisplay ? 'nowrap' : 'wrap'}
					mb='50px'
				>
					<Box width='330px' textAlign='center' mr={xlDisplay || mobile ? 0 : 'auto'}>
						<IconImage src={Computer} alt='img' width='222px' />
						<Typography fontSize={22} fontFamily='GilroySemiBold' variant='h3' color='rgba(30, 28, 48, 1)' mb='20px'>
							Immersive Learning Experiences
						</Typography>
						<Typography fontFamily='GilroyMedium' fontSize={18} color='rgba(0, 0, 0, 0.7)'>
							VR experiences bring lessons to life by engaging students with exciting and interactive content by using
							active learning and inquiry based approaches.
						</Typography>
					</Box>
					<Box width='330px' textAlign='center'>
						<IconImage src={Collaborative} alt='img' width='222px' />
						<Typography fontSize={22} fontFamily='GilroySemiBold' variant='h3' color='rgba(30, 28, 48, 1)' mb='20px'>
							Collaborative learning
						</Typography>
						<Typography fontFamily='GilroyMedium' fontSize={18} color='rgba(0, 0, 0, 0.7)'>
							Immersive and interactive VR experiences allow students to collaborate and develop critical thinking,
							communication, and teamwork skills.
						</Typography>
					</Box>
					<Box width='330px' textAlign='center'>
						<IconImage src={Safe} alt='img' width='222px' />
						<Typography fontSize={22} fontFamily='GilroySemiBold' variant='h3' color='rgba(30, 28, 48, 1)' mb='20px'>
							Safe Learning
						</Typography>
						<Typography fontFamily='GilroyMedium' fontSize={18} color='rgba(0, 0, 0, 0.7)'>
							VR can create a safe and controlled environment for students to explore potentially hazardous scenarios or
							practice skills in a controlled environment.
						</Typography>
					</Box>
				</Stack>
				{/* <Stack direction='row' justifyContent='center'>
					<Button variant='purple_btn'>
						<Box mr={1} display='flex' alignItems='center' justifyContent='center'>
							<IconImage src={ArrowRightCircle} alt='View all cources' width='20px' />
						</Box>
						View complete guide
					</Button>
				</Stack> */}
			</HowItWorksCard>
			<Buble1 top='50%' left='-50%' />
			<Buble3 top='90%' left='75%' />
		</Stack>
	)
}
