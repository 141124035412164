import { Paper, styled } from '@mui/material'

export const DarkModal = styled(Paper, { name: 'DarkModal' })`
	background: #1e1c30;
	box-shadow: 0 12px 99px #00000029;
	border: 1px solid #ffffff57;
	border-radius: 10px;
	padding: 30px;
	box-sizing: border-box;
`
