import { RolesEnum } from '@Enums'
import type { SharedState } from '../../typings'

export const updateRoleProducer = (state: SharedState, payload: RolesEnum): SharedState => {
	if (state.user) {
		const user = { ...state.user, role: payload }

		return { ...state, user }
	}

	return { ...state }
}
