import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { resetPasswordEffect } from '../../effects/resetPassword.effect'

export function useHandlers() {
	const navigate = useNavigate()
	const { code } = useParams<{ code: string }>()

	const handleSubmit = useCallback(
		async ({ password }: { password: string }) => {
			let mount = true

			const response = await resetPasswordEffect({ code: code || '', password })

			if (response.status === 'error' || !mount) return

			navigate('/passcreated')

			return () => {
				mount = false
			}
		},
		[code, navigate],
	)

	return {
		handleSubmit,
	}
}
