import { Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import LinkedinLogo from '@Assets/linkedin.svg'
import InstagramLogo from '@Assets/instagram.svg'
import TicTokLogo from '@Assets/tiktok.svg'
import TwitterLogo from '@Assets/twitter.svg'
import YouTubeLogo from '@Assets/youtube.svg'

import { DividerGray } from './DividerGray'
import { IconImage } from './IconImage'
import { DisplayEnum } from 'shared/enums/display.enum'
import { useFooterData } from '../hooks/useFooterData'

type Props = {
	showAdditionalInfo?: boolean
	onMainPage: boolean
}

export function Footer(props: Props) {
	const { showAdditionalInfo, onMainPage } = props

	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { showInstruction, instructionLink } = useFooterData()

	return (
		<Stack
			width={xlDisplay ? '1180px' : '100%'}
			mt={xlDisplay ? 0 : 3}
			mx='auto'
			px={xlDisplay ? 0 : mobile ? 2 : 3}
			pb={3}
			boxSizing='border-box'
		>
			<DividerGray />

			<Stack
				direction={mobile ? 'column' : 'row'}
				justifyContent={xlDisplay ? 'space-between' : 'center'}
				flexWrap={xlDisplay ? 'nowrap' : 'wrap'}
				mt={3}
			>
				<Stack direction='row' spacing={2.8} justifyContent='space-between' mb={xlDisplay ? 0 : 3}>
					<Link href='https://twitter.com/bridgelearning1' target='_blank'>
						<IconImage src={TwitterLogo} alt='Twitter Logo' height='26px' />
					</Link>
					<Link href='https://www.youtube.com/channel/UC-zHgMRyo6EOBchJpeIzFZw' target='_blank'>
						<IconImage src={YouTubeLogo} alt='YouTubeLogo' height='26px' />
					</Link>
					<Link href='https://www.instagram.com/bridgelearningtechnologies/' target='_blank'>
						<IconImage src={InstagramLogo} alt='Instagram Logo' height='26px' />
					</Link>
					<Link href='https://www.linkedin.com/company/bridge-learning-technologies-ltd/' target='_blank'>
						<IconImage src={LinkedinLogo} alt='Linkedin Logo' height='26px' />
					</Link>
					<Link href='https://www.tiktok.com/@bridgelt' target='_blank'>
						<IconImage src={TicTokLogo} alt='TicTok Logo' height='26px' />
					</Link>
				</Stack>

				<Stack mb={xlDisplay ? 0 : 3}>
					<Link href='https://bridgelt.com/'>
						<Typography
							color={theme.palette.text.secondary}
							fontFamily='GilroyMedium'
							textAlign={mobile ? 'center' : 'start'}
						>
							© 2023 Bridge Learning Tech
						</Typography>
					</Link>
				</Stack>

				<Stack direction='row' spacing={1}>
					{/*{showInstruction && (*/}
					{/*	<Link color={theme.palette.text.secondary} target='_blank' href={instructionLink}>*/}
					{/*		Instructions for using the Oculus app*/}
					{/*	</Link>*/}
					{/*)}*/}
					<Link color={theme.palette.text.secondary} target='_blank' href='/privacy-policy'>
						Privacy Policy
					</Link>
					<Link color={theme.palette.text.secondary} target='_blank' href='/terms-and-conditions'>
						Terms & Conditions
					</Link>
					<Link color={theme.palette.text.secondary} target='_blank' href='/cookie'>
						Cookies
					</Link>
				</Stack>
			</Stack>
		</Stack>
	)
}
