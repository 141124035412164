import { useSharedContext } from '@Context'
import { cookiesAcceptedActionCreator } from '@State'
import { useCallback } from 'react'

export function useCookiesHandlers() {
	const [, dispatch] = useSharedContext()
	const cookiesAcceptedHandleClick = useCallback(() => {
		localStorage.setItem('cookiesAccepted', 'true')
		dispatch(cookiesAcceptedActionCreator())
	}, [dispatch])
	return { cookiesAcceptedHandleClick }
}
