import { styled } from '@mui/material'

export const StyledInput = styled('input')({
	background: '#FFF',
	padding: '15px 18px',
	borderRadius: '5px',
	outline: 'none',
	boxShadow: 'none',
	fontFamily: 'GilroySemiBold',
	border: '1px solid #D2D2D2',
	zIndex: 999,

	'&:disabled': {
		background: 'rgba(255, 255, 255, .8)',
	},
})
