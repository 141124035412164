import { useTrainingsContext } from '../../context'
import { useEffect } from 'react'
import { fetchTrainingsEffect } from '../../effects'
import { useLocation } from 'react-router-dom'
import { changeFiltersActionCreator } from 'features/courses/state'


export function useFetchTrainingsOnMount(setLoading: (value: boolean) => void) {
	const [, dispatch] = useTrainingsContext()
	const typeRoute = useLocation()?.state?.type

	useEffect(() => {
		let mounted = true
		
		dispatch(changeFiltersActionCreator({ type: typeRoute }))

		const execute = async () => {
			setLoading(true)
			const response = await fetchTrainingsEffect({type: typeRoute})
			setLoading(false)
			if (!mounted || response.status === 'error') return

			dispatch(response.action)
		}
		
		execute()

		return () => {
			mounted = false
		}
	}, [dispatch])
}
