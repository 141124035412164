import { SubscriptionUser } from 'features/subscriptions'
import type { ConfirmationDeleteUser, DeleteUserCandidate } from './typings'

export const confirmationDeleteUserActionCreator = (payload: boolean): ConfirmationDeleteUser => ({
	type: 'subscriptions/confirmationDeleteUser',
	payload,
})

export const setDeleteUserCandidateActionCreator = (payload: SubscriptionUser | null): DeleteUserCandidate => ({
	type: 'subscriptions/deleteUserCandidate',
	payload,
})
