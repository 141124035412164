import { Fragment } from 'react'
import type { ReactElement } from 'react'
import { useFetchTrainingsOnMount } from './CoursesListPage/hooks'

type Props = {
	children: ReactElement
	setLoading: (value: boolean) => void
}

export function DataManager(props: Props) {
	const { children, setLoading } = props
	
	useFetchTrainingsOnMount(setLoading)

	return <Fragment>{children}</Fragment>
}
