import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { SubscriptionPlansEnum } from '@Enums'

export function useHandlers(subscriptionPlan: SubscriptionPlansEnum) {
	const enterpriseCard = subscriptionPlan === SubscriptionPlansEnum.Enterprise
	const navigate = useNavigate()
	const handleBuyClick = useCallback(() => {
		localStorage.setItem('selectedPlan', subscriptionPlan.toString())
		navigate(enterpriseCard ? '/contact-us' : '/payment')
	}, [navigate])

	return {
		handleBuyClick,
	}
}
