import { Box, Button, Stack } from '@mui/material'
import { Form, useFormikContext } from 'formik'
import { useHandlers } from './hooks'
import { useEffect } from 'react'
import { AlertMsg, CustomTextField } from '@Components'
import LockOpenIcon from '@mui/icons-material/LockOpen'

type Props = {
	serverError: string
	setServerError: (value: string) => void
	setOpen: (value: boolean) => void
}

export function ResetForm(props: Props) {
	const { serverError, setServerError, setOpen } = props
	const { handleCancelClick } = useHandlers(setServerError, setOpen)
	const { setErrors, errors } = useFormikContext<{ email: string }>()

	useEffect(() => {
		if (serverError) {
			setErrors({ email: 'This email doesn`t exist.' })
		} else {
			if (errors.email === 'This email doesn`t exist.') {
				errors.email = undefined
			}
		}
	}, [serverError, setErrors])

	return (
		<Form>
			{!!serverError && <AlertMsg icon={<LockOpenIcon />} text={serverError} />}
			<Box mt={3}>
				<CustomTextField<{ email: string }>
					id='email'
					name='email'
					label='EMAIL'
					type='email'
					placeholder='Please enter your e-mail'
				/>
			</Box>

			<Stack direction='row' mt={3} spacing={2}>
				<Button variant='rounded_contained__auth_secondary' onClick={handleCancelClick}>
					CANCEL
				</Button>
				<Button variant='rounded_contained__auth_success' fullWidth type='submit'>
					RESET PASSWORD
				</Button>
			</Stack>
		</Form>
	)
}
