import { RequestService } from '@Services'
import type { DownloadInvoiceContext } from '../DownloadInvoiceDialog'
import { URLS } from '@Utils'

export async function downloadInvoiceEffect(body: DownloadInvoiceContext): Promise<{ status: 'success' | 'error' }> {
	try {
		const response = await RequestService.postBlob<DownloadInvoiceContext, undefined>(URLS.getInvoice, body);

		if (response.status === 200) {

			var rs = response.data as unknown;

			const blob = new Blob(
				[rs as string], { type: 'application/pdf' });
							
			var url = window.URL.createObjectURL(blob);

			window.open(url);

			return { status: 'success' }
		}

		return { status: 'error' }
	} catch (err) {
		return { status: 'error' }
	}
}
