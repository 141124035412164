import { DisplayEnum } from '@Enums'
import { Stack, styled } from '@mui/material'

export const MoreFeaturesStack = styled(Stack, { name: 'MoreFeaturesStack' })({
	backgroundColor: '#fff',
  borderRadius: '0 0 30px 30px',
  opacity: '0.6',
  width: '1180px',
  height: '648px',
  position: 'absolute',
  top: '-25px',
  left: '0',
  [`@media (max-width: ${DisplayEnum.tablet}px)`]: {
		width: '100%',
    height: '100%',
    boxSizing: 'border-box'
	}
})
