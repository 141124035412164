import { Route, Routes } from 'react-router-dom'
import { ContactUsPage } from './ContactUsPage'

export function ContactUsRoutes() {
	return (
		<Routes>
			<Route path='/contact-us' element={<ContactUsPage onMainPage={true} />} />
		</Routes>
	)
}
