import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { ResetPasswordData } from '../../typings'
import { resetPasswordEffect } from '../../effects/resetPassword.effect'

export function useHandlers() {
	const navigate = useNavigate()

	const handleSubmit = useCallback(async (values: ResetPasswordData) => {
		let mount = true
		const result = await resetPasswordEffect(values)

		if (!mount) return

		if (result.status === 'success') navigate('/codesent')

		return () => {
			mount = false
		}
	}, [])

	const handleCancelClick = useCallback(() => {
		navigate('/login')
	}, [navigate])

	return {
		handleCancelClick,
		handleSubmit,
	}
}
