import type { PdfInfo, SubscriptionsEffectResponse } from '../typings'
import { RequestService } from '@Services'
import { URLS } from '@Utils'
import { fetchPdfInfoActionCreator } from '../state'
import { useSubscriptionsContext } from '../context'
import { useEffect } from 'react'
import { fetchUserPdfInfo } from '../effects/fetchUserPdfInfo'

export function useFetchUserPdfInfo() {
	const [, dispatch] = useSubscriptionsContext()

	useEffect(() => {
		let mounted = true

		const execute = async () => {
			const response = await fetchUserPdfInfo()

			if (!mounted) return

			dispatch(response.action)
		}

		execute()

		return () => {
			mounted = false
		}
	}, [dispatch])
}
