import { Footer, Header } from "@Components"
import { Stack, useTheme } from "@mui/material"
import { ReportingStackBG } from "../components"
import { useFetchTrainingReporting } from "../TrainingsReports/hooks"
import { TrainingsDetailsComponent } from "./components"


export function TrainingsDetailsPage() {
    const theme = useTheme()

	useFetchTrainingReporting()

	return (
		<Stack bgcolor={theme.palette.background.default}>
			<ReportingStackBG>
				<Header hideNavigationMenu showReportingMenu />
				<TrainingsDetailsComponent />
				<Footer onMainPage={false} />
			</ReportingStackBG>
		</Stack>
	)
}
