import {
	Box,
	Button,
	ListItemIcon,
	Menu,
	MenuItem,
	Stack,
	Typography,
	useTheme,
	ListItemText,
	useMediaQuery,
	Chip,
} from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import { IconButtonSecondary, IconImage } from '@Components'
import type { Subscription } from '../../typings'
import { useData, useHandlers } from './hooks'
import { StyledStack } from './StyledStack'
import { SubscriptionPlansEnum } from '@Enums'
import infinity from '@Assets/clvr_infinity.png'
import { useState } from 'react'
import { ConfirmationDialogRaw } from './components/DownloadInvoiceDialog'
import { DisplayEnum } from 'shared/enums/display.enum'
import { MobileCard } from './MobileCard'
import { FileDownloadIconStyled } from './components/FileDownloadIconStyled'
import { FileUploadIconStyled, PlaylistRemoveIconStyled } from './components'

type Props = {
	subscription: Subscription
}

export function Card(props: Props) {
	const { subscription } = props
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { period, price, disableUnsubscribe, currentSubscriptionStatus, statusColor, enableOptions } =
		useData(subscription)
	const { handleManageClick, handleUnsubscribeClick, handleUpgradeClick } = useHandlers()
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const [openDialog, setOpenDialog] = useState(false)

	const handleCloseDialog = (newValue?: string) => {
		setOpenDialog(false)
	}

	const downloadInvoice = () => {
		setAnchorEl(null)
		setOpenDialog(true)
		console.log('Download invoice')
	}

	return (
		<StyledStack>
			{mobile && <MobileCard subscription={subscription} />}
			{!mobile && (
				<Stack direction='row'>
					{subscription.subscriptionType !== SubscriptionPlansEnum.Enterprise && (
						<Stack mr={2.8}>
							<Stack direction='row' alignItems='baseline'>
								<Typography
									component='span'
									fontSize={120}
									color={theme.palette.text.primary}
									fontFamily='GilroyBold'
									lineHeight={1}
									whiteSpace='nowrap'
								>
									£{price * 12}
								</Typography>{' '}
								<Typography color={theme.palette.text.primary} fontFamily='GilroyBold' fontSize={26} lineHeight={1}>
									GBP
								</Typography>
							</Stack>
							<Typography
								fontSize={15}
								color={theme.palette.text.secondary}
								fontFamily='GilroySemiBold'
								textAlign='center'
							>
								PER YEAR
							</Typography>
						</Stack>
					)}
					<Stack flexGrow={1}>
						<Stack direction='row' spacing={2}>
							<Chip
								label={
									<Typography fontWeight={700} color='white'>
										{currentSubscriptionStatus}
									</Typography>
								}
								color={statusColor}
							/>
							<Typography variant='h4' color={theme.palette.text.primary} fontFamily='GilroyBold' mb={1} fontSize={28}>
								{subscription.name === 'Standart' ? 'Standard' : subscription.name}
							</Typography>
						</Stack>

						<Typography maxWidth={640} variant='body1' color={theme.palette.text.secondary} mb={1.5}>
							{subscription.description}
						</Typography>
						
							<Stack>
								<Typography fontSize={15} color={theme.palette.text.secondary} fontFamily='GilroySemiBold'>
									MAXIMUM USERS
								</Typography>
								{subscription.subscriptionType !== SubscriptionPlansEnum.Enterprise && (
									<Typography mt={0.7} color={theme.palette.text.primary} fontFamily='GilroyBold' fontSize={26} lineHeight={1}>
										{subscription.maxUserLimit} SEATS
									</Typography>
								)}
								{subscription.subscriptionType === SubscriptionPlansEnum.Enterprise && (
									<Typography
										color={theme.palette.text.primary}
										fontFamily='GilroyBold'
										fontSize={26}
										lineHeight={1}
										alignItems='center'
										display='flex'
									>
										<Stack mr={1} alignItems='center'>
											<IconImage src={infinity} width='36px' height='53px' />
										</Stack > SEATS
									</Typography>
								)}
							</Stack>
					</Stack>
					<Stack direction='column' justifyContent='space-between'>
						<Typography
							variant='h6'
							color={theme.palette.text.primary}
							fontFamily='GilroyRegular'
							textAlign='right'
							whiteSpace={xlDisplay ? 'nowrap' : 'normal'}
						>
							{period}
						</Typography>
						<Box display='flex' justifyContent='space-between'>
							<Box width='100%' mr={2.3}>
								<Button variant='rounded_contained__success' fullWidth onClick={handleManageClick}>
									<Typography color={theme.palette.text.primary}>MANAGE USERS</Typography>
								</Button>
							</Box>
							{enableOptions && (
								<IconButtonSecondary
									aria-controls={open ? 'long-menu' : undefined}
									aria-expanded={open ? 'true' : undefined}
									aria-haspopup='true'
									onClick={handleClick}
								>
									<MoreVert color='secondary'/>
								</IconButtonSecondary>
							)}
							<Menu
								id='long-menu'
								disableScrollLock={true}
								MenuListProps={{
									'aria-labelledby': 'long-button',
								}}
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								PaperProps={{
									style: {
										maxHeight: 216,
										width: '25ch',
									},
								}}
							>
								<MenuItem onClick={downloadInvoice}>
									<ListItemIcon>
										<FileDownloadIconStyled fontSize='small' />
									</ListItemIcon>
									<ListItemText>
										<Typography fontSize={16} color={theme.palette.text.secondary} fontFamily='GilroySemiBold'>
											Download Invoice
										</Typography>
									</ListItemText>
								</MenuItem>
								<MenuItem onClick={handleUpgradeClick}>
									<ListItemIcon>
										<FileUploadIconStyled fontSize='small' />
									</ListItemIcon>
									<ListItemText>
										<Typography fontSize={16} color={theme.palette.text.secondary} fontFamily='GilroySemiBold'>
											Upgrade
										</Typography>
									</ListItemText>
								</MenuItem>
								<MenuItem onClick={handleUnsubscribeClick} disabled={disableUnsubscribe}>
									<ListItemIcon>
										<PlaylistRemoveIconStyled fontSize='small' />
									</ListItemIcon>
									<ListItemText>
										<Typography fontSize={16} color={theme.palette.text.secondary} fontFamily='GilroySemiBold'>
											Unsubscribe
										</Typography>
									</ListItemText>
								</MenuItem>
							</Menu>
							<ConfirmationDialogRaw id='ringtone-menu' keepMounted open={openDialog} onClose={handleCloseDialog} />
						</Box>
					</Stack>
				</Stack>
			)}
		</StyledStack>
	)
}
