import { Footer, Header } from '@Components'
import { Stack, useTheme } from '@mui/material'
import { ReportingStackBG } from '../components'
import { TrainingsPageComponent } from './components'
import { useFetchTrainingReporting } from './hooks'

export function TrainingsReportsPage() {
	const theme = useTheme()

	useFetchTrainingReporting()

	return (
		<Stack bgcolor={theme.palette.background.default}>
			<ReportingStackBG>
				<Header hideNavigationMenu showReportingMenu />
				<TrainingsPageComponent />
				<Footer onMainPage={false} />
			</ReportingStackBG>
		</Stack>
	)
}
