import { IconImage } from '@Components'
import { Box, Stack, Typography, useTheme } from '@mui/material'

import { StyledCardBoxBG } from '..'
import { useData } from './hooks'
import type { TrainingStatusCard } from '../../TrainingsReports/typings'

type Props = {
	count?: number
	totalCount?: number
	card: TrainingStatusCard
}

export function ReportingCard(props: Props) {
	const { card, count = 0, totalCount = 0 } = props
	const title = card.title
	const theme = useTheme()
	const { statusColor } = useData({ theme, title })

	return (
		<Box position='relative' borderRadius='10px' overflow='hidden' color={theme.palette.primary.main}>
			{card.isPrimary && <StyledCardBoxBG position='absolute' />}
			<Box p={2} bgcolor={!card.isPrimary ? theme.palette.grey.A700 : ''} zIndex={2} position='relative'>
				<Typography fontFamily='GilroySemiBold' fontSize={14} color={statusColor} textTransform='uppercase'>
					{card.title}
				</Typography>
				<Stack direction='row' alignItems='center' columnGap={2}>
					<IconImage src={card.icon} alt='icon' height='44px' />
					<Box mt={card.isTime ? 1 : ''} pb={card.isTime ? 0.5 : ''}>
						{!card.isTime && (
							<Stack>
								<Stack direction='row' alignItems='baseline'>
									<Typography component='span' fontFamily='GilroyBold' fontSize={40} color={theme.palette.primary.main}>
										{count}
									</Typography>
									{card.isTotal && (
										<Typography
											component='span'
											fontFamily='GilroyMedium'
											fontSize={20}
											color={theme.palette.text.secondary}
										>
											{`/ ${totalCount}`}
										</Typography>
									)}
								</Stack>
								{card.isTrainingsPage && (
									<Stack direction='row' color={theme.palette.text.disabled} flexWrap='nowrap'>
										<Typography component='span' fontFamily='GilroySemiBold' fontSize={10}>
											TRAININGS
										</Typography>
										{card.isPrimary && (
											<Typography component='span' fontFamily='GilroySemiBold' fontSize={10}>
												/ USERS
											</Typography>
										)}
									</Stack>
								)}
							</Stack>
						)}
						{card.isTime && (
							<Typography fontFamily='GilroyBold' fontSize={30} color={theme.palette.primary.main}>
								{count}
							</Typography>
						)}
					</Box>
				</Stack>
			</Box>
		</Box>
	)
}
