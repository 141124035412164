import { Stack, styled } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'

export const RightPartModalStack = styled(Stack, { name: 'RightPartModalStack' })({
	// background: '#1B192C',
	padding: '30px 20px',
	boxSizing: 'border-box',
	width: '100%',
	overflowY: 'auto',
	[`@media (max-width: 767px)`]: {
		overflowY: 'visible'
	},
})
	
