import { styled, Paper } from '@mui/material'

export const MenuModal = styled(Paper, { name: 'MenuModal' })({
	background: 'rgba(244, 244, 243, .6)',
  boxShadow: '0px 13px 99px #0000008A',
  backdropFilter: 'blur(6px)',
  width: '100%',
  height: '100%',
  padding: '18px 30px',
  justifyContent: 'space-between'
})
