import {
    Box,
    Link,
    Stack,
    Typography,
    useTheme
} from "@mui/material";

export function ParticipantsReportsHeader() {
    const theme = useTheme()

    return (
        <Stack mt={6} ml={2} >
            <Stack direction='row' alignItems={"end"} columnGap={6}>
                <Link href='/reporting' underline="none">
                    <Typography fontFamily='GilroyBold' mb={3} fontSize={48} color={theme.palette.text.disabled}>
                        Trainings
                    </Typography>
                </Link>
                <Box>
                    <Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.info.main}>
                        STATISTIC
                    </Typography>
                    <Box borderBottom='3px solid' borderColor={theme.palette.primary.main}>
                        <Typography fontFamily='GilroyBold' mb={3} fontSize={48} color={theme.palette.primary.main}>
                            Participants
                        </Typography>
                    </Box>
                </Box>
            </Stack>
        </Stack>
    )
}