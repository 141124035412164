import Logo from '@Assets/clvr_logo1.png'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import {Box, Button, Stack, Typography, useMediaQuery, useTheme} from '@mui/material'
import { AlertMsg, IconImage } from '@Components'
import { Formik } from 'formik'
import { RegisterForm } from './RegisterForm'
import { useHandlers } from './hooks'
import { RegisterValidationSchema } from './validationSchema'
import { useEffect, useState } from 'react'
import { BackgroundStackVariant, BoxBackgroundStack } from '../components'
import { DisplayEnum } from 'shared/enums/display.enum'
import { useAuthData } from '@Hooks'
import Microsoft from '@Assets/Microsoft_logo.png'
import {useMsalInstance} from "../../../shared/hooks/useMsalInstance";
import {useMsalLogin} from "../../../shared/hooks/useMsalLogin";

export function RegisterPage() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const [showError, setShowError] = useState({})
	const { handleSubmit, handleMainClick } = useHandlers(setShowError)
	const { initialValues } = useAuthData()
	const msalInstance = useMsalInstance();
	const msalLogin = useMsalLogin(mobile, msalInstance);

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<BackgroundStackVariant
			width='100%'
			height='100%'
			minHeight='100vh'
			pb={xlDisplay ? 14.5 : 0}
			px={mobile ? 3 : 0}
			boxSizing={mobile ? 'border-box' : 'content-box'}
		>
			<BoxBackgroundStack
				pb={mobile ? 3 : 3.5}
				borderRadius='10px'
				mt={mobile ? 5 : 7}
				mb={mobile ? 5 : 0}
				ml={xlDisplay ? 7 : mobile ? 0 : 'auto'}
				mr={xlDisplay || mobile ? 0 : 'auto'}
				width={mobile ? '100%' : 530}
				boxSizing='border-box'
			>
				<Box boxShadow='0px 24px 76px #0000001C' borderRadius='30px' bgcolor='#FFFFFF' pb={3} pt={mobile ? 3 : 5} px={mobile ? 3 : 5}>
					<Stack direction='row' spacing={2.5} mb={2}>
						<Box onClick={handleMainClick} sx={{ cursor: 'pointer'}}>
							<IconImage src={Logo} alt='logo' width='60.5px' />
						</Box>
						<Box>
							<Typography fontSize={mobile ? 24 : 28} variant='h4' color={theme.palette.text.primary} fontFamily='GilroyBold'>
								Welcome to CleVR
							</Typography>
							<Typography fontFamily='GilroySemiBold' color='rgba(0, 0, 0, .7)' lineHeight='19px' mt={1}>
								Use your Microsoft account for access our immersive virtual reality experiences.
							</Typography>
						</Box>
					</Stack>

					<Button variant='rounded_contained__microsoft' fullWidth onClick={msalLogin}>
						<Stack direction='row' spacing={1} alignItems='center'>
							<IconImage src={Microsoft} width='21px' />
							<Typography fontSize={mobile ? 12 : 16}>SIGN IN WITH MICROSOFT</Typography>
						</Stack>
					</Button>
				</Box>

				<Box px={mobile ? 3 : 5}>
					{false && <AlertMsg icon={<LockOpenIcon />} text='User wasnt registered' />}
				</Box>

				<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={RegisterValidationSchema}>
					<RegisterForm showError={showError} initialValues={initialValues} />
				</Formik>
			</BoxBackgroundStack>
		</BackgroundStackVariant>
	)
}
