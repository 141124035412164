import { RequestService } from '@Services'
import { URLS } from '@Utils'
import type { SubscriptionsEffectResponse, SubscriptionUser } from '../typings'
import { createSubscriptionUserActionCreator } from '../state/'

export async function createSubscriptionUserEffect(
	values: Omit<SubscriptionUser, 'id' | 'addedDate'> & { host: string },
): Promise<SubscriptionsEffectResponse> {
	try {
		const response = await RequestService.post<
			Omit<SubscriptionUser, 'id' | 'addedDate'> & { host: string },
			SubscriptionUser
		>(URLS.addSubscriptionUser, values, true)

		if (response.status === 200) {
			return { status: 'success', action: createSubscriptionUserActionCreator(response.data) }
		}

		return { status: 'error', action: createSubscriptionUserActionCreator() }
	} catch (err: any) {
		return {
			status: 'error',
			action: createSubscriptionUserActionCreator(),
			error: Object.entries(err.response.data.errors)[0][1] as string,
		}
	}
}
