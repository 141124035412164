import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useToken } from '@Hooks'

export function useHandlers() {
	const navigate = useNavigate()
	const user = useToken()

	const handleManageClick = useCallback(() => {
		navigate(`/subscriptions/manage/${user.subscriptionId}`)
	}, [navigate])

	const handleUnsubscribeClick = useCallback(() => {
		navigate(`/subscriptions/unsubscribe/${user.subscriptionId}`)
	}, [navigate, user])

	const handleUpgradeClick = useCallback(() => {
		const { subscriptionPlan } = user

		switch (subscriptionPlan) {
			case 1:
				navigate('/subscriptions/all')
				return
			case 2:
			case 3:
				navigate('/contact-us')
				return
		}
	}, [navigate, user])

	return {
		handleManageClick,
		handleUnsubscribeClick,
		handleUpgradeClick,
	}
}
