import { useSubscriptionsCardsData, useToken } from '@Hooks'

export function useData() {
	const cards = useSubscriptionsCardsData()
	const user = useToken()

	return {
		cards,
		currentSubscription: user.subscriptionPlan,
	}
}
