import { useCallback } from 'react'
import type { SubscriptionUsersFilters } from '../../typings'
import { useSubscriptionsContext } from '../../context'
import { fetchSubscriptionUsersEffect } from '../../effects/fetchSubscriptionUsers.effect'

export function useFilterChange(filters: SubscriptionUsersFilters) {
	const [, dispatch] = useSubscriptionsContext()

	const handleFilterChange = useCallback(
		async (value: string | number, name: string) => {
			let mounted = true
			// @ts-ignore
			filters[name] = value

			if (name === 'role' || name === 'email' || name === 'withOculus') {
				filters.page = 1
			}

			filters.page = filters.page || 1

			const preparedFilters = Object.fromEntries(Object.entries(filters).filter((entry) => !!entry[1]))

			const response = await fetchSubscriptionUsersEffect(preparedFilters as SubscriptionUsersFilters)

			if (!mounted) return

			dispatch(response.action)

			return () => {
				mounted = false
			}
		},
		[dispatch, filters],
	)

	return handleFilterChange
}
