import { useCallback } from 'react'
import { useSharedContext } from '@Context'
import { updateTokenEffect } from '../effects'

export function useUpdateUser() {
	const [, dispatch] = useSharedContext()
	return useCallback(async () => {
		let mounted = true

		const { status, action } = await updateTokenEffect()

		if (mounted && status === 'success') {
			localStorage.setItem('user', JSON.stringify(action.payload))
			dispatch(action)
		}

		return () => {
			mounted = false
		}
	}, [dispatch, updateTokenEffect])
}
