import { useSharedContext } from '@Context'
import { useMemo } from 'react'
import { storeUserActionCreator } from '@State'

export function useToken() {
	const [{ user }] = useSharedContext()
	const localStorageUser = JSON.parse(localStorage.getItem('user') || '{}')

	if (!Object.keys(user || {}).length) {
		storeUserActionCreator(localStorageUser)
	}

	return useMemo(() => Object.keys(user || {}).length ? user : localStorageUser, [user, localStorageUser])
}
