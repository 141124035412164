import { useCallback } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { useSubscriptionsContext } from '../../../../context'
import { toggleAddUserActionCreator } from '../../../../state'
import { createSubscriptionUserEffect } from '../../../../effects'
import { SubscriptionUser } from '../../../../typings'
import { useToken } from '@Hooks'
import { updateTokenEffect } from '../../../../../../shared/effects'
import { useSharedContext } from '@Context'

export function useHandlers(setShowError: Dispatch<SetStateAction<any>>) {
	const [, dispatch] = useSubscriptionsContext()
	const [, sharedDispatch] = useSharedContext()
	const [{ subscriptionUsers, subscriptionUsersTotal, subscription }] = useSubscriptionsContext()
	const user = useToken()

	const handlePopupClose = useCallback(() => {
		setShowError(false)
		dispatch(toggleAddUserActionCreator(false))
	}, [dispatch])

	const handleCreateUser = useCallback(
		async (values: Omit<SubscriptionUser, 'id' | 'addedDate'>, setSubmitting: Dispatch<SetStateAction<boolean>>) => {
			if (
				values.withOculus &&
				(subscription?.maxUserLimit || 0) <= (subscriptionUsers || []).filter((user) => user.withOculus).length
			) {
				setShowError({
					users: 'You have reached or exceeded the limit for the number of Users you can add to the Oculus APP.',
				})
				return
			} else {
				setShowError({})
			}

			let mounted = true

			try {
				setSubmitting(true)

				const response = await createSubscriptionUserEffect({ ...values, host: window.location.origin })

				setSubmitting(false)

				if (!mounted) return

				if (response.status === 'error') {
					console.log()
					setShowError({
						users:
							response.error ||
							'You have reached or exceeded the limit for the number of Users you can add to the Oculus APP.',
					})
					return
				} else {
					setShowError({})
					dispatch(response.action)
					dispatch(toggleAddUserActionCreator(false))

					if (values.withOculus && values.email === user.email) {
						const { action, status } = await updateTokenEffect()

						if (status === 'success') {
							sharedDispatch(action)
						}
					}
				}
			} catch (err: any) {
				setShowError(err?.response?.data)
			}

			return () => {
				mounted = false
			}
		},
		[dispatch, subscriptionUsers, subscriptionUsersTotal, subscription],
	)

	return {
		handlePopupClose,
		handleCreateUser,
	}
}
