import { useMemo } from 'react'
import { useToken } from '@Hooks'

export function useInitialValues() {
	const user = useToken()

	return useMemo(() => {
		if (!user) return { email: '' }

		return { email: user.email }
	}, [user])
}
