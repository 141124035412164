import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'
import USA from '@Assets/united-states.svg'
import UK from '@Assets/united-kingdom.svg'
import KOREA from '@Assets/south-korea.svg'
import { IconImage } from '@Components'
import { ContactUsHomeCard } from './ContactUsHomeStack'

export function ContactUsHome() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	return (
		<Stack
			width={xlDisplay ? 1080 : '100%'}
			mx='auto'
			mt={xlDisplay ? 9 : 3}
			mb={xlDisplay ? 6 : 3}
			boxSizing='border-box'
			px={xlDisplay ? 0 : mobile ? 2 : 3}
			id='contacts'
		>
			<Typography fontSize={38} fontFamily='GilroyBold' color={theme.palette.text.primary} textAlign='center' mb={3}>
				Contact us
			</Typography>
			<Stack
				direction='row'
				justifyContent={xlDisplay ? 'space-between' : 'center'}
				flexWrap={xlDisplay ? 'nowrap' : 'wrap'}
			>
				<Stack position='relative' mr={xlDisplay || mobile ? 0 : 'auto'}>
					<Box width={332} p={3} boxSizing='border-box' zIndex={4}>
						<IconImage src={UK} width={'30px'} height={'30px'} />
						<Box textAlign='left'>
							<Typography
								fontFamily='GilroySemiBold'
								fontSize={20}
								mt={2}
								mb={2}
								color={theme.palette.text.primary}
								lineHeight={1}
							>
								UK
							</Typography>
							<Typography fontFamily='GilroySemiBold' fontSize={16} mb={2} color={theme.palette.text.secondary}>
								+44 (0) 3330 119 683
							</Typography>
							<Typography fontFamily='GilroySemiBold' fontSize={16} color={theme.palette.text.secondary}>
								Cambridge House, Henry Street Bath BA1 1BT, United Kingdom
							</Typography>
						</Box>
					</Box>
					<ContactUsHomeCard />
				</Stack>

				<Stack position='relative' mt={mobile ? 3 : 0}>
					<Box width={332} p={3} boxSizing='border-box' zIndex={4}>
						<IconImage src={USA} width={'30px'} height={'30px'} />
						<Box textAlign='left'>
							<Typography
								fontFamily='GilroySemiBold'
								fontSize={20}
								mt={2}
								mb={2}
								color={theme.palette.text.primary}
								lineHeight={1}
							>
								USA
							</Typography>
							<Typography fontFamily='GilroySemiBold' fontSize={16} mb={2} color={theme.palette.text.secondary}>
								+1 206.407.3774
							</Typography>
							<Typography fontFamily='GilroySemiBold' fontSize={16} color={theme.palette.text.secondary}>
								2950 Buskirk Ave, Suite 300 Walnut Creek, CA 94597
							</Typography>
						</Box>
					</Box>
					<ContactUsHomeCard />
				</Stack>

				<Stack position='relative' mt={xlDisplay ? 0 : 3}>
					<Box width={332} p={3} boxSizing='border-box' zIndex={4}>
						<IconImage src={KOREA} width={'30px'} height={'30px'} />
						<Box textAlign='left'>
							<Typography
								fontFamily='GilroySemiBold'
								fontSize={20}
								mt={2}
								mb={2}
								color={theme.palette.text.primary}
								lineHeight={1}
							>
								Korea (South)
							</Typography>
							<Typography fontFamily='GilroySemiBold' fontSize={16} mb={2} color={theme.palette.text.secondary}>
								+82 2 3478 4488
							</Typography>
							<Typography fontFamily='GilroySemiBold' fontSize={16} color={theme.palette.text.secondary}>
								Kyobo Building, 15th Floor, 1 Jongno, Jongno-gu, Seoul 03154, Korea
							</Typography>
						</Box>
					</Box>
					<ContactUsHomeCard />
				</Stack>
			</Stack>
		</Stack>
	)
}
