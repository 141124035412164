import { useMemo, useReducer } from 'react'
import { initialState, reducer } from '../../state'
import { AsyncDispatch, wrapAsync } from '@Utils'
import type { Actions, State } from '../../state'

export function useData() {
	const [state, dispatch] = useReducer(reducer, initialState)

	const asyncDispatch = useMemo(() => wrapAsync(dispatch), [dispatch])

	const value: [State, AsyncDispatch<Actions>] = [state, asyncDispatch]

	return {
		value,
	}
}
