import type { PaymentState, Actions } from './typings'
import { fetchPaymentSecretProducer } from './Actions/fetchPaymentSecret'

export function reducer(state: PaymentState, action: Actions): PaymentState {
	switch (action.type) {
		case 'payment/fetchSecret':
			return fetchPaymentSecretProducer(state, action.payload)
		default:
			return { ...state }
	}
}
