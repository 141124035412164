import { useCallback } from 'react'
import type { DownloadInvoiceContext } from '../DownloadInvoiceDialog'
import { downloadInvoiceEffect } from '../effects/downloadInvoiceEffect'

export function useHandlers(setServerError: (value: boolean) => void, setLoading: (value: boolean) => void) {
	const handleSubmit = useCallback(async (values: DownloadInvoiceContext) => {
		if (
			!values.email ||
			!values.city ||
			!values.address ||
			!values.companyName ||
			!values.zipcode ||
			!values.phoneNumber
		) {
			return
		}

		let mounted = true

		try {
			setLoading(true)
			const response = await downloadInvoiceEffect(values)
			setLoading(false)

			if (!mounted) return

			if (response.status === 'error') {
				setServerError(true)
			} else {
				setServerError(false)
			}
		} catch (err: any) {
			setServerError(err?.response?.data)
		}

		return () => {
			mounted = false
		}
	}, [])

	return {
		handleSubmit,
	}
}
