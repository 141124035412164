import { Footer, Header } from "@Components"
import { Stack, useTheme } from "@mui/material"
import { ReportingStackBG } from "../components"
import { useFetchTrainingReporting } from "../TrainingsReports/hooks"
import { ParticipantsDetailsComponent } from "./components"


export function ParticipantsDetailsPage() {
    const theme = useTheme()

	useFetchTrainingReporting()

	return (
		<Stack bgcolor={theme.palette.background.default}>
			<ReportingStackBG>
				<Header hideNavigationMenu showReportingMenu />
				<ParticipantsDetailsComponent />
				<Footer onMainPage={false} />
			</ReportingStackBG>
		</Stack>
	)
}
