import { useEffect } from 'react'
import { fetchPaymentsEffect } from '../effects'
import { useSubscriptionsContext } from '../context'

export function useFetchPayments() {
	const [, dispatch] = useSubscriptionsContext()

	useEffect(() => {
		let mounted = true

		const execute = async () => {
			const response = await fetchPaymentsEffect()

			if (!mounted || response.status === 'error') return

			dispatch(response.action)
		}

		execute()

		return () => {
			mounted = false
		}
	}, [dispatch])
}
