import { useMemo } from 'react'
import * as Yup from 'yup'

export function useDownloadValidation() {
	return useMemo(
		() =>
			Yup.object().shape({
				companyName: Yup.string()
					.required('This field is required, please fill in the information')
					.max(255, 'Value is too long, maximum length allowed is 255 characters'),
				address: Yup.string()
					.required('This field is required, please fill in the information')
					.min(1, 'Value is too short, please enter minimum 3 characters')
					.max(255, 'Value is too long, maximum length allowed is 255 characters'),
				city: Yup.string()
					.required('This field is required, please fill in the information')
					.min(3, 'Value is too short, please enter minimum 3 characters')
					.max(255, 'Value is too long, maximum length allowed is 255 characters'),
				zipcode: Yup.string()
					.required('This field is required, please fill in the information ')
					.min(3, 'Value is too short, please enter minimum 3 characters')
					.max(255, 'Value is too long, maximum length allowed is 255 characters'),
				phoneNumber: Yup.string()
					.required('This field is required, please fill in the information')
					.min(1, 'Value is too short, please enter minimum 3 characters')
					.max(255, 'Value is too long, maximum length allowed is 255 characters'),
				email: Yup.string()
					.email('Email address must consist of a local part, the "@" symbol, and the domain')
					.required('This field is required, please fill in the information')
					.min(3, 'Value is too short, please enter minimum 3 characters')
					.max(255, 'Value is too long, maximum length allowed is 255 characters'),
			}),
		[],
	)
}
