import type { Actions, SharedState } from './typings'
import { updateRoleProducer, storeUserProducer, logoutProducer, openBurgerMenuProducer } from './Actions'

export function sharedReducer(state: SharedState, action: Actions) {
	switch (action.type) {
		case 'shared/storeUser':
			return storeUserProducer(state, action.payload)
		case 'shared/updateRole':
			return updateRoleProducer(state, action.payload)
		case 'shared/openBurgerMenu':
			return openBurgerMenuProducer(state, action.payload)
		case 'shared/logout':
			return logoutProducer(state)
		default:
			return { ...state }
	}
}
