import People from '@Assets/people.svg'
import Lamp from '@Assets/lamp.svg'
import Verified from '@Assets/verified1.svg'
import Start from '@Assets/start.svg'
import Hourglass from '@Assets/hourglass.svg'
import { TrainingStatusesEnum } from '../../TrainingsReports/enums'
import { useTrainingsContext } from '../../../courses/context'
import { useMemo } from 'react'
import { useReportsContext } from '../../context'

const participantsCard = {
	title: 'PARTICIPANTS',
	icon: People,
	isPrimary: true,
	status: TrainingStatusesEnum.All,
}

const inProgressCard = {
	title: 'In Progress',
	icon: Lamp,
	status: TrainingStatusesEnum.All,
}

const completedCard = {
	title: 'Completed',
	icon: Verified,
	status: TrainingStatusesEnum.All,
}

const averageAttemptsCard = {
	title: 'Average Attempts',
	icon: Start,
	status: TrainingStatusesEnum.All,
}

const averageTimeCard = {
	title: 'Average Time',
	icon: Hourglass,
	isTime: true,
	status: TrainingStatusesEnum.All,
}

const filtersTitles = ['Not Started', 'In Progress', 'Completed']
const filtersSubTitles = ['PARTICIPANTS', 'PARTICIPANTS', 'PARTICIPANTS']

const tableHeadingTitles = [
	{
		title: 'NAME & EMAIL',
		hasFilters: true,
	},
	{
		title: 'ATTEMPTS',
		hasFilters: false,
	},
	{
		title: 'START DATE',
		hasFilters: false,
	},
	{
		title: 'END DATE',
		hasFilters: false,
	},
	{
		title: 'EXECUTION TIME',
		hasFilters: false,
	},
]

const searchPlaceholder = 'SEARCH PARTICIPANT'

const tableRows = [
	{
		name: 'Amy Watson',
		email: 'amy.watson@example.com',
		trainingImage: '',
		attempts: '',
		startDate: '',
		endDate: '',
		executionTime: '',
	},
	{
		name: 'Amy Watson',
		email: 'amy.watson@example.com',
		trainingImage: '',
		attempts: '',
		startDate: '',
		endDate: '',
		executionTime: '',
	},
	{
		name: 'Amy Watson',
		email: 'amy.watson@example.com',
		trainingImage: '',
		attempts: '',
		startDate: '',
		endDate: '',
		executionTime: '',
	},
	{
		name: 'Amy Watson',
		email: 'amy.watson@example.com',
		trainingImage: '',
		attempts: '',
		startDate: '',
		endDate: '',
		executionTime: '',
	},
	{
		name: 'Amy Watson',
		email: 'amy.watson@example.com',
		trainingImage: '',
		attempts: '',
		startDate: '',
		endDate: '',
		executionTime: '',
	},
]

export function useData() {
	const [{ statuses }] = useReportsContext()
	const cards = useMemo(
		() =>
			[participantsCard, inProgressCard, completedCard, averageAttemptsCard, averageTimeCard].map((card) => ({
				...card,
				count: 0,
			})),
		[statuses],
	)

	return {
		cards,
		filtersTitles,
		filtersSubTitles,
		tableHeadingTitles,
		tableRows,
		searchPlaceholder,
		totalPages: 1,
	}
}
