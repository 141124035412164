import { Field, useFormikContext } from 'formik'
import { TextField } from '@Components'
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { ReactElement } from 'react'
import { DisplayEnum } from '@Enums'

type Props<T> = {
	id: keyof T
	name: string
	label: string
	type?: string
	placeholder?: string
	textArea?: boolean
	error?: string
	defaultValue?: string
	disabled?: boolean
	withTopPadding?: boolean
}

export function CustomTextField<T>(props: Props<T>) {
	const { id, name, label, type, placeholder, textArea, defaultValue, disabled, withTopPadding, error } = props
	const { errors, touched } = useFormikContext<T>()
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	const topPadding = withTopPadding ? (mobile? 110 : 100) : 82

	return (
		<Box position='relative'>
			<Field
				id={id}
				name={name}
				label={label}
				type={type}
				placeholder={placeholder}
				component={TextField}
				textArea={textArea}
				disabled={disabled}
				defaultValue={defaultValue}
			/>
			{error || (errors && errors[id] && touched[id]) ? (
				<Stack position='absolute' top={topPadding} left={0} >
					<Typography variant='caption' color='red' letterSpacing={mobile ? -0.2 : 0.1}  lineHeight={mobile ? 1 : ''} >
						{error || ((errors[id] ? errors[id] : <></>) as ReactElement)}
					</Typography>
				</Stack>
			) : null}
		</Box>
	)
}
