import { useSharedContext } from '@Context'
import { Fragment, useEffect, useState } from 'react'
import type { ReactElement } from 'react'
import { storeUserActionCreator } from '@State'
import { CookiesPolicyBanner } from './CookiesPolicyBanner'
import { useCookiesData } from '@Hooks'

type Props = {
	children: ReactElement
}

export function SharedDataManagement(props: Props) {
	const { children } = props
	const [{ user }, dispatch] = useSharedContext()
	const { cookiesAccepted } = useCookiesData()

	useEffect(() => {
		const localStorageUser = JSON.parse(localStorage.getItem('user') || '{}')
		if (!user) {
			dispatch(storeUserActionCreator(localStorageUser))
		}
	}, [user, dispatch])

	return (
		<Fragment>
			{children}
			{!cookiesAccepted && <CookiesPolicyBanner />}
		</Fragment>
	)
}
