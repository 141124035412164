import { useToken } from './useToken'
import { RolesEnum } from '@Enums'
import { TILDA_HOST } from '../../constants/AppConst'

export function useFooterData() {
	const user = useToken()

	const showInstruction = !!user?.role && user.role !== RolesEnum.Guest

	const page = user?.role ? RolesEnum[user.role].toLowerCase() : 'guest'

	const instructionLink = `${TILDA_HOST}/${page}`

	return {
		showInstruction,
		instructionLink,
	}
}
