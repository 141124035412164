import { Typography, useTheme } from '@mui/material'
import { StyledGrid } from './StyledGrid'

export function SubscriptionsTableEmpty() {
	const theme = useTheme()

	return (
		<StyledGrid py={4.5} px={3} width='100%'>
			<Typography variant='h6' fontSize='GilroySemiBold' color={theme.palette.text.secondary}>
				YOU HAVEN'T MADE ANY PURCHASES YET
			</Typography>
		</StyledGrid>
	)
}
