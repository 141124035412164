import { RequestService } from '@Services'
import { URLS } from '@Utils'
import { User } from '@Types'
import { StoreUser, storeUserActionCreator } from '@State'

export async function updateTokenEffect(): Promise<{ status: 'success' | 'error'; action: StoreUser }> {
	try {
		const response = await RequestService.get<undefined, User>(URLS.updateToken, undefined, true)

		if (response.status === 200) {
			const result = response.data
			localStorage.setItem('user', JSON.stringify(result))
			return { action: storeUserActionCreator(result), status: 'success' }
		}

		return { action: storeUserActionCreator(), status: 'error' }
	} catch (err) {
		return { action: storeUserActionCreator(), status: 'error' }
	}
}
