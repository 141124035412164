import { TableHeader } from './TableHeader'
import { TableFilters } from './TableFilters'
import { TableColumnsNames } from './TableColumnsNames'
import { TableRow } from './TableRow'
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import { Formik } from 'formik'
import { useData } from './hooks'
import { useFetchSubscriptions } from '../../hooks'
import { DisplayEnum } from 'shared/enums/display.enum'
import { StyledStack, StyledTableGrid } from 'features/subscriptions/components'
import type { SubscriptionUsersFilters } from '../../typings'
import { TableRowMobile } from './TableRowMobile'
import { EmptyStub } from './EmptyStub'
import { useFilterChange } from '../hooks/useFilterChange'
import { SharedPagination } from '@Components'
import { useEffect } from 'react'

type Props = {
	filters: SubscriptionUsersFilters
}

export function SubscriptionUsers(props: Props) {
	const { filters } = props
	const onChange = useFilterChange(filters)
	const { subscriptionUsers, pagesCount } = useData()
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const findUsers = !!subscriptionUsers.length

	useFetchSubscriptions()

	useEffect(() => {
		onChange(1, 'page')
	}, [])

	return (
		<Formik initialValues={{ email: '', role: 0, page: 0, limit: 10 }} onSubmit={() => {}}>
			<StyledTableGrid
				container
				maxWidth={xlDisplay ? 1180 : 'calc(100% - 60px)'}
				bgcolor='inherit'
				borderRadius='10px'
				mb={xlDisplay ? 10 : 3}
			>
				<TableHeader />
				<TableFilters {...props} />
				<Box width={'100%'} overflow={'auto'}>
					{!mobile && (
						<>
							<TableColumnsNames />
							{findUsers && subscriptionUsers.map((user, index) => <TableRow user={user} key={`user_${index}`} />)}
							{!findUsers && <EmptyStub />}
						</>
					)}
					{mobile && (
						<>
							{findUsers &&
								subscriptionUsers.map((user, index) => <TableRowMobile user={user} key={`user_${index}`} />)}
							{!findUsers && <EmptyStub />}
						</>
					)}
				</Box>
				<StyledStack width='100%'>
					<SharedPagination name='page' pagesCount={pagesCount} onChange={onChange} />
				</StyledStack>
			</StyledTableGrid>
		</Formik>
	)
}
