import { styled, Stack } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'

export const StyledStack = styled(Stack, { name: 'StyledStack' })({
	position: 'fixed',
  top: 0,
  transform: 'translateX(-50%)',
  width: '630px',
  backgroundColor: '#FF6943',
  borderRadius: '0 0 16px 16px',
  boxSizing: 'border-box',
  padding: '30px 38px',
  [`@media (max-width: ${DisplayEnum.mobile}px)`]: {
    width: '100%',
    padding: '24px',
  }
})