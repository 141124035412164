import type { ReportingState } from '../../typings'
import type { TrainingsReportingTableRow } from '../../../TrainingsReports/components/TrainingsTableContent'

export const fetchTrainingsProducer = (
	state: ReportingState,
	payload: { total: number; items: TrainingsReportingTableRow[] },
): ReportingState => ({
	...state,
	trainings: payload,
})
