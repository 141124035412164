import { IconButton, styled } from '@mui/material'

export const IconButtonSecondary = styled(IconButton, { name: 'IconButtonSecondary' })`
	background: rgba(255, 255, 255, 0.1);
	borderradius: 50%;
	min-width: 55px;
	box-shadow: 0px 12px 99px #00000029;
	border: 1px solid #ffffff57;
	color: #fff;
`
