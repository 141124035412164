import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { unsubscribeEffect } from '../../effects/unsubscribe.effect'
import { useSubscriptionsContext } from '../../context'

export function useHandlers() {
	const navigate = useNavigate()
	const [, dispatch] = useSubscriptionsContext()
	const { id } = useParams<{ id: string }>()

	const handleCancelClick = useCallback(() => {
		navigate(`/subscriptions/manage/${id}`)
	}, [id, navigate])

	const handleUnsubscribeClick = useCallback(async () => {
		let mount = true
		const response = await unsubscribeEffect()

		if (!mount || response.status === 'error') return

		dispatch(response.action)
		navigate('/subscriptions/unsubscribed')

		return () => {
			mount = false
		}
	}, [dispatch, navigate])

	const handleReturnClick = useCallback(() => {
		navigate('/subscriptions')
	}, [navigate])

	return {
		handleCancelClick,
		handleUnsubscribeClick,
		handleReturnClick,
	}
}
