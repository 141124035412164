import { ReportingTable } from '../../components/Table'
import {
	ReportingCards,
	ReportingFiltersWithTable,
	StyledReportingsGrid,
	StyledReportingsTableHeaderStack,
} from '../../components'
import { useData } from '../hooks'

export function TrainingsDetailsContent() {
	const { cards, filtersSubTitles, filtersTitles, searchPlaceholder, tableHeadingTitles, tableRows, totalPages } =
		useData()

	return (
		<StyledReportingsGrid>
			<StyledReportingsTableHeaderStack>
				<ReportingCards cards={cards} />
			</StyledReportingsTableHeaderStack>
			<ReportingFiltersWithTable
				filtersTitles={filtersTitles}
				searchPlaceholder={searchPlaceholder}
				heading='TRAINING PARTICIPANTS'
				filtersSubTitles={filtersSubTitles}
				totalPages={totalPages}
				entity='trainings'
			>
				<ReportingTable tableHeadingTitles={tableHeadingTitles} tableRows={tableRows} entity='trainings' />
			</ReportingFiltersWithTable>
		</StyledReportingsGrid>
	)
}
