
import Verified from '@Assets/verified.svg'
import { Stack, Typography, useTheme } from '@mui/material'
import { IconImage } from '@Components'
import { SuccessStyledBox } from './SuccessStyledBox'


export function SuccessBox() {
    const theme = useTheme()

    return (
        <SuccessStyledBox>
            
            <Stack mt={6} mb={4} width={90} mx='auto'>
                <IconImage src={Verified} alt='Verified' width='90px' height='90px'/>
            </Stack>

            <Typography
                fontSize={28}
                color='primary'
                mt={1}
                fontFamily='GilroyBold'
                textAlign='center'>
                Subscription successfully purchased
            </Typography>

            <Typography
                fontSize={16}
                color={theme.palette.text.secondary}
                mt={1}
                fontFamily='GilroyMedium'
                textAlign='center'>
                As soon as the payment is confirmed by the payment system, the subscription will be activated.
            </Typography>

        </SuccessStyledBox>
    )
}