import { Dispatch, useEffect } from 'react'
import { fetchTrainingsStatuses } from '../../effects'
import { useReportsContext } from '../../context'
import { fetchTrainingsReports } from '../../effects/fetchTrainingsReports'
import { TrainingsFilters } from '../typings'
import { Actions } from '../../state'

async function execute(trainingsFilters: TrainingsFilters, mounted: boolean, dispatch: Dispatch<Actions>) {
	const trainingsFiltersResult = await fetchTrainingsStatuses(trainingsFilters)
	const trainingsResult = await fetchTrainingsReports(trainingsFilters)

	if (mounted && trainingsFiltersResult.status === 'success') {
		dispatch(trainingsFiltersResult.action)
	}

	if (mounted && trainingsResult.status === 'success') {
		dispatch(trainingsResult.action)
	}
}

export function useFetchTrainingReporting() {
	const [{ trainingsFilters }, dispatch] = useReportsContext()
	let mounted = true

	useEffect(() => {
		execute(trainingsFilters, mounted, dispatch)

		return () => {
			mounted = false
		}
	}, [trainingsFilters, dispatch])
}
