import { RequestService } from '@Services'
import type { ResetPasswordData } from '../typings'
import type { EffectResponse } from '@Types'
import { URLS } from '@Utils'

export async function resetPasswordEffect(body: ResetPasswordData): Promise<EffectResponse> {
	const result = await RequestService.post<ResetPasswordData, null>(URLS.confirmReset, body)

	if (result.status === 200) {
		return { status: 'success' }
	}

	return { status: 'error' }
}
