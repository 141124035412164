import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Form, useFormikContext } from 'formik'
import type { ContactUs } from '../typings/ContactUs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useGoToMain } from '@Hooks'
import { DisplayEnum } from 'shared/enums/display.enum'
import { CustomTextField } from '@Components'
import { ContactUsStack } from './ContactUsStack'

type Props = {
	onMainPage: boolean
}

export function ContactUsForm(props: Props) {
	const { onMainPage } = props
	const theme = useTheme()
	const [showThankYou, setShowThankYou] = useState(false)
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const goToMain = useGoToMain(setShowThankYou)
	const { touched, errors, isSubmitting, isValid, values } = useFormikContext<ContactUs>()
	const enabled =
		(isValid && (touched.name || touched.message || touched.email)) ||
		(!touched.name && !touched.message && !touched.email)

	useEffect(() => {
		if (
			isValid &&
			isSubmitting &&
			Object.keys(touched).length &&
			!Object.keys(errors).length &&
			// TODO: Remove this and create better logic for ThankYou message showing
			Object.values(values).filter((value) => !!value).length > 2
		) {
			setShowThankYou(true)
		}
	}, [isSubmitting, isValid, errors, touched, values])

	const handleSendAgain = useCallback(() => {
		setShowThankYou(false)
	}, [setShowThankYou])

	return (
		<Form>
			<Stack width={mobile ? '100%' : 862} height={mobile ? '100%' : 665} p={mobile ? 0 : 6}>
				{/* Displayed when a message is sent */}
				{showThankYou && (
					<Stack alignItems='center' flex={1} justifyContent='center' boxShadow='0px 24px 76px #0000001C'>
						<Typography fontFamily='GilroyBold' fontSize={mobile ? 26 : 38} mb={2} color={theme.palette.text.primary}>
							Thank you.
						</Typography>
						<Typography
							fontFamily='GilroySemiBold'
							fontSize={18}
							mb={4}
							color={theme.palette.text.secondary}
							textAlign='center'
						>
							YOUR MESSAGE HAS BEEN SUCCESSFULLY SENT. <br /> WE'LL CONTACT YOU SOON
						</Typography>
						<Box mb={2}>
							<Button variant='contact_us_green_text' onClick={handleSendAgain}>
								SEND MESSAGES AGAIN
							</Button>
						</Box>
						{!onMainPage && (
							<Box>
								<Button variant='contact_us_white_text' onClick={goToMain}>
									GO TO MAIN PAGE
								</Button>
							</Box>
						)}
					</Stack>
				)}
				{/* Displayed by default */}
				{!showThankYou && (
					<Stack position='relative' py={mobile ? 2 : 7} px={mobile ? 2 : 12} boxShadow='0px 24px 76px #0000001C' borderRadius='30px'>
						<Typography
							color={theme.palette.text.primary}
							fontFamily='GilroyBold'
							fontSize={mobile ? 26 : 38}
							mb={3}
							lineHeight={1}
							zIndex={4}
						>
							Join the Community
						</Typography>
						<Typography
							color={theme.palette.text.secondary}
							fontFamily='GilroySemiBold'
							fontSize={18}
							mb={3}
							lineHeight={1}
							width={mobile ? '100%' : 625}
							mx={'auto'}
							zIndex={4}
						>
							To stay up to date with everything CleVR, hear what exciting VR lessons and AR teaching tools are on the
							horizon, sign up to our newsletter today.
						</Typography>
						<Stack width={mobile ? '100%' : 470} mx={'auto'} zIndex={4}>
							<Box mb={1} pb={2} textAlign='left'>
								<CustomTextField<ContactUs>
									id='email'
									name='email'
									label='Email'
									type='email'
									placeholder='ENTER CONTACT EMAIL ADDRESS'
								/>
							</Box>

							<Box mb={1} pb={2} textAlign='left'>
								<CustomTextField<ContactUs>
									id='name'
									name='name'
									label='Your Name'
									type='text'
									placeholder='ENTER YOUR FULL NAME'
								/>
							</Box>

							<Box mb={1} pb={2} textAlign='left'>
								<CustomTextField<ContactUs>
									id='organization'
									name='organization'
									label='Your School / Organization'
									type='text'
									placeholder='PLEASE, PROVIDE YOUR SCHOOL / ORGANIZATION'
								/>
							</Box>

							<Box mb={1} pb={2} textAlign='left'>
								<CustomTextField<ContactUs>
									id='message'
									name='message'
									label='Message'
									type='text'
									placeholder='Here you can write your message'
									textArea
								/>
							</Box>

							<Button variant='contact_us_purple' fullWidth type='submit' disabled={!enabled}>
								SUBSCRIBE
							</Button>
						</Stack>
						<ContactUsStack />
					</Stack>
				)}
			</Stack>
		</Form>
	)
}
