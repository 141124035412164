import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export function useHandlers() {
	const navigate = useNavigate()
	const handleBuyClick = useCallback(() => {
		navigate('/subscriptions/all')
	}, [navigate])

	return {
		handleBuyClick,
	}
}
