import type { Theme } from '@mui/material'
import { useMemo } from 'react'
import { TrainingAttemptsEnum, TrainingUsersTypes, TrainingStatusesEnum } from '../../../TrainingsReports/enums';

function getStatusColor(title: string, theme: Theme) {
	const colors: Record<string, string> = {
		[TrainingStatusesEnum.NotStarted]: theme.palette.primary.contrastText,
		[TrainingStatusesEnum.InProgress]: theme.palette.secondary.contrastText,
		[TrainingStatusesEnum.Completed]: theme.palette.success.main,
		[TrainingAttemptsEnum.averageAttempts]: theme.palette.error.contrastText,
		[TrainingAttemptsEnum.averageTime]: theme.palette.warning.light,
		[TrainingUsersTypes.Active]: theme.palette.info.contrastText,
		[TrainingUsersTypes.Inactive]: theme.palette.text.disabled,
	}

	return colors[title]
}

type Props = {
	title: string
	theme: Theme
}

export function useData(props: Props) {
	const { theme, title } = props
	const statusColor = useMemo(() => getStatusColor(title, theme), [title, theme])

	return {
		statusColor,
	}
}
