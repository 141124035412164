import { DisplayEnum } from '@Enums'
import { Stack, styled } from '@mui/material'

export const HowItWorksCard = styled(Stack, { name: 'HowItWorksCard' })({
	width: '1180px',
  borderRadius: '0 0 30px 30px',
  backgroundColor: '#fff',
  paddingTop: '40px',
  margin: '0 auto',
  textAlign: 'center',
  zIndex: '4',
  [`@media (max-width: ${DisplayEnum.tablet}px)`]: {
		width: '100%',
    padding: '40px 30px 0',
    boxSizing: 'border-box'
	},
  [`@media (max-width: ${DisplayEnum.mobile}px)`]: {
    padding: '40px 20px 0',
	}
})