import { Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { useData, useHandlers } from './hooks'
import { RolesService } from '../../../../shared/services/roles.service'
import { RolesEnum, SubscriptionPlansEnum } from '@Enums'

import { DividerGray, IconImage } from '@Components'
import infinity from '@Assets/clvr_infinity.png'
import { DisplayEnum } from '../../../../shared/enums/display.enum'

export function TableHeader() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { handleUpgradeClick, handleUnsubscribeClick } = useHandlers()
	const { period, total, name, price, type, description, isNotEnterprice, disableUnsubscribe } = useData()

	return (
		<Stack
			px={2}
			py={3}
			direction={xlDisplay ? 'row' : 'column'}
			width='100%'
			boxSizing={mobile ? 'border-box' : 'content-box'}
		>
			{!mobile && type !== SubscriptionPlansEnum.Enterprise && (
				<Stack mr={2.8}>
					<Stack direction='row' alignItems='baseline'>
						<Typography
							component='span'
							fontSize={mobile ? 70 : 120}
							color={theme.palette.text.primary}
							fontFamily='GilroyBold'
							lineHeight={1}
						>
							{price * 12}
						</Typography>{' '}
						<Typography color={theme.palette.text.primary} fontFamily='GilroyBold' fontSize={26} lineHeight={1}>
							GBP
						</Typography>
					</Stack>
					<Typography
						textAlign={xlDisplay ? 'center' : 'left'}
						fontSize={15}
						color={theme.palette.text.secondary}
						fontFamily='GilroySemiBold'
					>
						PER YEAR
					</Typography>
				</Stack>
			)}
			<Stack flexGrow={1} mb={mobile ? 2 : 0}>
				<Typography variant='h4' color={theme.palette.text.primary} fontFamily='GilroyBold' fontSize={28} mb={1}>
					{name === 'Standart' ? 'Standard' : name}
				</Typography>
				{isNotEnterprice && (
					<Typography variant='body1' color={theme.palette.text.secondary} mb={1.5} maxWidth={530}>
						{description}
					</Typography>
				)}
				{mobile && type !== SubscriptionPlansEnum.Enterprise && (
					<Stack mb={2}>
						<Stack direction='row' alignItems='baseline' justifyContent={'center'}>
							<Typography component='span' fontSize={60} color={theme.palette.text.primary} fontFamily='GilroyBold' lineHeight={1}>
								{price * 12}
							</Typography>{' '}
							<Typography color={theme.palette.text.primary} fontFamily='GilroyBold' fontSize={26} lineHeight={1}>
								GBP
							</Typography>
						</Stack>
						<Typography
							textAlign='center'
							fontSize={15}
							color={theme.palette.text.secondary}
							fontFamily='GilroySemiBold'
						>
							PER YEAR
						</Typography>
					</Stack>
				)}
				{type !== SubscriptionPlansEnum.Enterprise && (
					<Stack>
						<Typography fontSize={15} color={theme.palette.text.secondary} fontFamily='GilroySemiBold'>
							MAXIMUM USERS
						</Typography>
						<Typography mt={0.7} color={theme.palette.text.primary} fontFamily='GilroyBold' fontSize={26} lineHeight={1}>
							{total} SEATS
						</Typography>
					</Stack>
				)}
				{type === SubscriptionPlansEnum.Enterprise && (
					<Stack>
						{isNotEnterprice && (
							<Typography fontSize={15} color={theme.palette.text.secondary} fontFamily='GilroySemiBold'>
								MINIMUM USERS
							</Typography>
						)}
						<Typography
							color={theme.palette.text.primary}
							fontFamily='GilroyBold'
							fontSize={26}
							lineHeight={1}
							alignItems='center'
							display='flex'
						>
							<Stack mr={0.8} justifyContent='center'>
								<IconImage src={infinity} width='36px' height='53px' />
							</Stack>
							SEATS
						</Typography>
					</Stack>
				)}
			</Stack>
			{mobile && <DividerGray />}
			<Stack alignItems={mobile ? 'center' : 'end'} justifyContent='space-between' mt={xlDisplay ? 0 : mobile ? 2 : 1}>
				<Typography variant='h6' color={theme.palette.text.primary} fontFamily='GilroySemiBold' mb={mobile ? 2 : 0}>
					{period}
				</Typography>
				{RolesService.availableForRole([RolesEnum.SuperAdmin]) && isNotEnterprice && (
					<Stack spacing={2} direction={mobile ? 'column' : 'row'} width='100%'>
						<Button variant='rounded_contained__secondary_darken' fullWidth onClick={handleUpgradeClick}>
							<Stack lineHeight={1} direction='row' alignItems='center' spacing={1}>
								<FileUploadIcon sx={{ color: '#fff' }} fontSize='large' />
								<Typography color='#fff'>UPGRADE</Typography>
							</Stack>
						</Button>
						<Button
							variant='rounded_contained__alert'
							fullWidth
							onClick={handleUnsubscribeClick}
							disabled={disableUnsubscribe}
						>
							<Typography lineHeight={1} color={theme.palette.text.primary}>UNSUBSCRIBE</Typography>
						</Button>
					</Stack>
				)}
			</Stack>
		</Stack>
	)
}
