import { RequestService } from '@Services'
import { URLS } from '@Utils'

export async function fetchCodeEffect(id: string): Promise<{ code: string }> {
	const response = await RequestService.get<{ training: string }, { code: string }>(
		URLS.fetchCode,
		{ training: id },
		true,
	)

	if (response.status === 200) {
		return response.data
	}

	return { code: '' }
}
