import { IconImage } from '@Components'
import {Box, Stack, Typography, useMediaQuery, useTheme} from '@mui/material'
import { StyledContainer } from './StyledContainer'
import { StyledLineClamp } from './StyledLineClamp'
import { useHandlers } from './hooks'
import {TrainingStatusEnum, TrainingTypeEnum} from '../../utils/enums'
import { useSharedContext } from '@Context'
import { RolesEnum } from '@Enums'

import Background from '@Assets/CourseCard.svg'
import { DisplayEnum } from 'shared/enums/display.enum'
import {CourseStatus} from "./CourseStatus";
import {useMemo} from "react";
import {useToken} from "@Hooks";

type Props = {
	name: string
	description: string
	src: string
	status: TrainingStatusEnum
	type: TrainingTypeEnum
	time?: string
	id: string
	timeComplete?: string
}

export function CourseCard(props: Props) {
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { name, description, src, status, timeComplete, id, type } = props
	const { handleTrainingCardClick } = useHandlers(id)
	const user = useToken()
	const isGuest = (user?.role || RolesEnum.Guest) === RolesEnum.Guest
	const showStatus = !isGuest && user?.withOculus
	const addBackgoundImg = (elem: any) => elem.style.visibility = 'visible'

	const typeTitle = useMemo(() => {
		switch (type) {
			case TrainingTypeEnum.Physics:
				return 'Physics'
			case TrainingTypeEnum.Biology:
				return 'Biology'
			case TrainingTypeEnum.Chemistry:
				return 'Chemistry'
		}
	}, [type])

	return (
		<StyledContainer
			onClick={(e: any) => {
				if (mobile) {
					addBackgoundImg(e.target.parentNode.childNodes[0])
					return setTimeout(() => {
						handleTrainingCardClick()
					}, 1000)
				}
				handleTrainingCardClick()
			}}
			position='relative'
		>
			{/*<Box position='absolute' top={-30} left={-34} zIndex={1} visibility='hidden'>*/}
			{/*	<IconImage src={Background} alt='background' width='438px' />*/}
			{/*</Box>*/}
			<Box borderRadius='6px' overflow='hidden' boxSizing='border-box' zIndex={2} mb={2}>
				<IconImage src={src} alt={name} width='370px' height='203px' borderRadius='6px'/>
			</Box>

			<Stack direction='row' alignItems='center' spacing={1}>
				<Box py={.5} px={.7} borderRadius='4px' border='1px solid #D2D2D2'>
					<Typography fontFamily='GilroyMedium'
								color={theme.palette.text.secondary}
								fontSize={14}
								lineHeight='16px'
					>
						{typeTitle}
					</Typography>
				</Box>
				<CourseStatus showStatus={!!showStatus} status={status} timeComplete={timeComplete} />
			</Stack>

			<Typography fontSize={22}
						fontFamily='GilroySemiBold'
						color={theme.palette.text.primary}
						noWrap zIndex={2}
						lineHeight='27px'
						mb={.5}
						mt={1}
			>
				{name}
			</Typography>
			<StyledLineClamp color={theme.palette.text.secondary} zIndex={2} fontSize={18}>
				{description}
			</StyledLineClamp>
		</StyledContainer>
	)
}
