import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { RolesEnum } from '@Enums'
import { useToken } from './useToken'
import { useIsLoggedIn } from './useIsLoggedIn'

function getRedirectionUrl(role: RolesEnum = RolesEnum.Guest) {
	const startPages = {
		[RolesEnum.User]: '/trainings',
		[RolesEnum.Guest]: '/trainings',
		[RolesEnum.SuperUser]: '/trainings',
		[RolesEnum.SuperAdmin]: '/subscriptions',
		[RolesEnum.Admin]: '/subscriptions',
	}

	return startPages[role]
}

export function useGoToMain(setShowThankYou: any) {
	const navigate = useNavigate()
	const user = useToken()
	const isLoggedIn = useIsLoggedIn()

	return useCallback(() => {
		setShowThankYou(false)

		if (isLoggedIn) {
			const page = getRedirectionUrl(user.role)
			navigate(page)
		} else {
			navigate('/')
		}
	}, [navigate])
}
