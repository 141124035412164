import { Stack, styled } from '@mui/material'

export const InfoTextStack = styled(Stack, { name: 'InfoTextStack' })({
	width: '100%',
  height: '100%',
  borderRadius: '10px',
  backgroundColor: '#25CCAC',
  opacity: 0.23,
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 2
})