import { Footer, Header } from '@Components'
import { Box, Stack, useTheme } from '@mui/material'

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { OrderDetailsPageComponent, OrderDetailsPageStack } from './components'
import {useIsLoggedIn} from "@Hooks";

export function OrderDetailsPage() {
	const theme = useTheme()
	const navigate = useNavigate()
	const isLoggedIn = useIsLoggedIn()

	useEffect(() => {
		window.scrollTo(0, 0)

		const subscriptionPlan = JSON.parse(localStorage.getItem('selectedPlan') || '0')

		if (!subscriptionPlan) {
			navigate('/subscriptions/all')
		}
	}, [navigate])

	return (
		<Stack bgcolor={theme.palette.background.default}>
			<OrderDetailsPageStack>
				<Header hideNavigationMenu={isLoggedIn} />
				<OrderDetailsPageComponent />
				<Footer onMainPage={false}/>
			</OrderDetailsPageStack>
		</Stack>
	)
}
