import { Footer, Header } from '@Components'
import {Box, Stack, useTheme} from '@mui/material'
import { CourseContentComponent } from './components/CourseContentComponent'
import { CourseContentStack } from '../components'
import { DataManager } from '../DataManager'
import {useToken} from "@Hooks";
import {useEffect} from "react";

export function CourseContentPage() {
	const theme = useTheme()
	const user = useToken()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const isLoggedIn = Object.keys(user || {}).length

	return (
		<DataManager setLoading={(value) => {}}>
			<Stack bgcolor={theme.palette.background.default}>
				<CourseContentStack>
					<Box position='fixed' top={0} left={0} right={0} zIndex={999}>
						<Header hideNavigationMenu={!!isLoggedIn}/>
					</Box>
					<CourseContentComponent />
					<Footer onMainPage={false} />
				</CourseContentStack>
			</Stack>
		</DataManager>
	)
}
