import { Button } from '@mui/material'
import { useGoToHomePage } from 'shared/hooks/useGoToHomePage'
import { IconImage } from './IconImage'
import BackArrow from '@Assets/arrow_back_black.svg'

type Props = {
	path?: string
}

export function BackButton(props: Props) {
	const { path } = props
	const goToHomePage = useGoToHomePage(path)

	return (
		<Button variant='back_btn' onClick={goToHomePage}>
			<IconImage src={BackArrow} alt='go to home page' />
		</Button>
	)
}
