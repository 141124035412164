import { useFormikContext } from 'formik'

export function useButtonEnabled() {
	const { values, isValid, touched } = useFormikContext<{ email: string }>()

	const enabled = values?.email?.length && isValid
	const invalid = touched && !isValid

	return {
		enabled,
		invalid,
	}
}
