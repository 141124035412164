import { useMemo, useReducer } from 'react'
import { initialState, reducer } from '../../state'
import type { AsyncDispatch } from '@Utils'
import { wrapAsync } from '@Utils'
import type { Actions, PaymentState } from '../../state'

export function useData() {
	const [state, dispatch] = useReducer(reducer, initialState)

	const asyncDispatch = useMemo(() => wrapAsync(dispatch), [dispatch])

	const value: [PaymentState, AsyncDispatch<Actions>] = [state, asyncDispatch]

	return {
		value,
	}
}
