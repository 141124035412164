import {Box, Stack, useMediaQuery, useTheme} from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'
import { CourseList } from './CourseList'
import { CourseStatistic } from './CourseStatistic'
import {IconImage} from "@Components";
import MainImg from '@Assets/clvr_trainings_overview.png'
import {ImgBanner} from "../../../../shared/components/ImgBanner";

type Props = {
	loading: boolean
}

export function CourseWrapperComponent(props: Props) {
	const { loading } = props
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet))
	
	return (
		<>
			<Box position='absolute' top={0} left={0} right={0}>
				<ImgBanner img={MainImg} isMobile={mobile} />
			</Box>
			<Stack
				width={xlDisplay ? 1180 : '100%'}
				p={xlDisplay || mobile ? 0 : '0 30px'}
				mx='auto'
				mt={mobile ? 14 : 16.3}
				mb={mobile ? 3 : 14}
				direction={mobile ? 'column' : 'row'}
				alignItems={mobile ? 'center' : 'start'}
				spacing={mobile || tablet ? 1 : 4}
				boxSizing='border-box'
			>
				<CourseStatistic />
				<CourseList loading={loading} />
			</Stack>
		</>
	)
}
