import People from '@Assets/people.svg'
import Person from '@Assets/person.svg'
import PersonLine from '@Assets/person_line.svg'
import { TrainingStatusesEnum } from '../../TrainingsReports/enums'
import { useReportsContext } from '../../context'
import { useMemo } from 'react'

const totalCard = {
	title: 'PARTICIPANTS',
	icon: People,
	isPrimary: true,
	status: TrainingStatusesEnum.All,
}

const activeCard = {
	title: 'Active',
	icon: Person,
	status: TrainingStatusesEnum.InProgress,
}

const inactiveCard = {
	title: 'Inactive',
	icon: PersonLine,
	status: TrainingStatusesEnum.Completed,
}

const noTabs = true

const searchPlaceholder = 'SEARCH BY EMAIL OR NAME'

const tableHeadingTitles = [
	{
		title: 'NAME & EMAIL',
		hasFilters: true,
		color: '',
	},
	{
		title: 'Not Started',
		hasFilters: false,
		color: '',
	},
	{
		title: 'In Progress',
		hasFilters: false,
		color: '#D4823F',
	},
	{
		title: 'Completed',
		hasFilters: false,
		color: '#14C881',
	},
	{
		title: 'Average Attempts',
		hasFilters: false,
		color: '',
	},
	{
		title: 'Average Time',
		hasFilters: false,
		color: '',
	},
]

export function useData() {
	const [{ statuses, participants, participantsFilters }] = useReportsContext()

	const cards = useMemo(() => [totalCard, activeCard, inactiveCard].map((card) => ({ ...card, count: 0 })), [])

	return {
		cards,
		noTabs,
		tableHeadingTitles,
		tableRows: participants.items,
		searchPlaceholder,
		totalPages: Math.ceil(participants.total / participantsFilters.limit),
	}
}
