import { Box, Button, Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import StartArrow from '@Assets/clvr_start_black.png'

import { IconImage } from '@Components'
import { SuccessBox } from './SuccessBox'
import { useData, useHandlers } from '../hooks'
import { DisplayEnum } from 'shared/enums/display.enum'

export function SubscriptionPageComponent() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { description, buttonLabel } = useData()
	const { handleContinueClick } = useHandlers()

	return (
		<Container>
			<Stack maxWidth={mobile ? '100%' : 1440} flex={1}>
				<Typography
					fontSize={18}
					color={theme.palette.success.main}
					mt={7}
					fontFamily='GilroySemiBold'
					textAlign='center'
				>
					PAY DETAILS
				</Typography>

				<Typography fontSize={mobile ? 24 : 48} color='primary' mt={0.5} fontFamily='GilroyBold' textAlign='center'>
					Order details
				</Typography>

				<Stack mt={5} mx='auto'>
					<SuccessBox />
				</Stack>

				<Stack mx='auto' mt={6} mb={3} direction='row'>
					<Box mt={-0.5}>
						<IconImage src={StartArrow} alt='Start Arrow' />
					</Box>
					<Typography fontSize={28} color={theme.palette.text.primary} fontFamily='GilroyBold' textAlign='center' ml={2.4}>
						Your next steps
					</Typography>
				</Stack>

				<Typography fontSize={16} color={theme.palette.text.secondary} fontFamily='GilroyMedium' textAlign='center'>
					{description}
				</Typography>

				<Container>
					<Box mt={2} mb={5} px={xlDisplay ? 28 : mobile ? 3 : 18}>
						<Button variant='rounded_contained__secondary_darken' type='submit' fullWidth onClick={handleContinueClick}>
							{buttonLabel}
						</Button>
					</Box>
				</Container>
			</Stack>
		</Container>
	)
}
