import { ArrowBackButton, IconImage } from "@Components";
import { 
    Box, 
    Stack, 
    Typography, 
    useTheme 
} from "@mui/material";
import Chevron from '@Assets/chevron_left.svg'

export function ParticipantsDetailsHeader() {
    const theme = useTheme()

    return (
        <Stack direction='row' columnGap={2} mt={7.5}>
            <Box>
                <ArrowBackButton buttonVariant="courses_back" />
            </Box>
            <Stack>
                <Stack direction='row' mt={-0.4} columnGap={1}>
                    <Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.info.main}>
                        PARTICIPANTS
                    </Typography>
                    <Box>
                        <IconImage src={Chevron} alt='chevron' />
                    </Box>
                    <Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.info.main}>
                        PARTICIPANT'S DETAILS
                    </Typography>
                </Stack>
                <Typography fontFamily='GilroyBold' fontSize={48} mt={-0.4} color={theme.palette.primary.main}>
                    Amy Watson
                </Typography>
            </Stack>
        </Stack>
    )
}