import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useToken } from '@Hooks'
import { checkUserEffect } from '../../effects/checkUser.effect'

export function useHandlers() {
	const user = useToken()
	const navigate = useNavigate()

	const handleContinueClick = useCallback(async () => {
		const { status } = await checkUserEffect(localStorage.getItem('email') || '')
		const route = !!Object.keys(user).length ? '/subscriptions' : status === 'success' ? '/login' : '/register'

		navigate(route)
	}, [user, navigate])

	return {
		handleContinueClick,
	}
}
