import { Typography, useTheme } from '@mui/material'

export function EuropeanUsers() {
	const theme = useTheme()

	return (
		<>
			<Typography fontFamily='GilroyBold' variant='h4' color={theme.palette.text.primary}>
				<u>For European Union (EU) Users</u>
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country
				in which you are resident in.
			</Typography>
		</>
	)
}
