import { Routes, Route } from 'react-router-dom'
import { ProtectedRoute } from '../../shared/components/ProtectedRoute'
import { DashboardPage } from './DashboardPage'
import { useIsLoggedIn } from '../../shared/hooks'

export function DashboardRoutes() {
	const isLoggedIn = useIsLoggedIn()

	return (
		<Routes>
			<Route path='/dashboard' element={<DashboardPage />} />
		</Routes>
	)
}
