import { useMemo } from 'react'
import * as Yup from 'yup'

export function useContactUsValidationSchema() {
	return useMemo(
		() =>
			Yup.object().shape({
				email: Yup.string()
					.required('This field is required, please fill in the information')
					.min(3, 'Value is too short, please enter minimum 3 characters')
					.max(255, 'Value is too long, maximum length allowed is 255 characters')
					.email('Invalid email'),
				name: Yup.string()
					.required('This field is required, please fill in the information')
					.max(255, 'Value is too long, maximum length allowed is 255 characters'),
				organization: Yup.string()
					.required('This field is required, please fill in the information')
					.min(1, 'Value is too short, please enter minimum 1 characters')
					.max(255, 'Value is too long, maximum length allowed is 255 characters'),
				message: Yup.string()
					.min(3, 'Value is too short, please enter minimum 3 characters')
					.max(255, 'Value is too long, maximum length allowed is 3000 characters'),
			}),
		[],
	)
}
