import type { ReactElement } from 'react'
import { TrainingsContext } from './context'
import { useData } from './hooks'

type Props = {
	children: ReactElement
}

export function TrainingsContextProvider(props: Props) {
	const { children } = props
	const { value } = useData()

	return <TrainingsContext.Provider value={value}>{children}</TrainingsContext.Provider>
}
