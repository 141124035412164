import { TrainingStatusesEnum } from '../../../TrainingsReports/enums/trainingStatuses.enum';

import type { Theme } from '@mui/material'
import { useMemo } from 'react'

function getStatusColor(title: string, theme: Theme) {
	const colors: Record<string, string> = {
		[TrainingStatusesEnum.NotStarted]: theme.palette.primary.main,
		[TrainingStatusesEnum.InProgress]: theme.palette.secondary.contrastText,
		[TrainingStatusesEnum.Completed]: theme.palette.success.main,
		
	}

	return colors[title]
}

type Props = {
	title: string
	theme: Theme
}

export function useData(props: Props) {
	const { theme, title } = props
	const statusColor = useMemo(() => getStatusColor(title, theme), [title, theme])

	return {
		statusColor,
	}
}
