import { styled } from '@mui/material'


const StyledImg = styled('img')(( props: { height?: string, width?: string, borderRadius?: string} ) => ({
	height: props.height,
	width: props.width,
	borderRadius: props.borderRadius
}));

type Props = {
	src: string
	height?: string
	width?: string
	alt?: string
	borderRadius?: string
	key?:string
}

export function IconImage(props: Props) {
	const { src, height, width, alt, borderRadius, key } = props

	return (
		<StyledImg src={src} alt={alt} height={height} width={width} borderRadius={borderRadius} key={key}/>
	)
}
