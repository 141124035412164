import type { SubscriptionsEffectResponse, SubscriptionUser } from '../typings'
import { RequestService } from '@Services'
import { URLS } from '@Utils'
import { deleteSubscriptionUserActionCreator } from '../state/Actions/DeleteSubscriptionUser'

export async function deleteSubscriptionUserEffect(user: SubscriptionUser): Promise<SubscriptionsEffectResponse> {
	const response = await RequestService.remove(URLS.removeSubscriptionUser, user, true)

	try {
		if (response.status === 200) {
			return { status: 'success', action: deleteSubscriptionUserActionCreator(user.id) }
		}

		return { status: 'error', action: deleteSubscriptionUserActionCreator() }
	} catch (err) {
		return { status: 'error', action: deleteSubscriptionUserActionCreator() }
	}
}
