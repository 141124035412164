import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Footer, Header } from '@Components'
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove'
import { useHandlers } from '../UnsubscribePage/hooks'
import { useFetchSubscriptions } from '../hooks'
import { SubscriptionsPageStack } from '../components'
import { useEffect, useMemo } from 'react'
import { DisplayEnum } from 'shared/enums/display.enum'
import { StyledStackBG } from '../UnsubscribePage/components'
import { useToken } from '@Hooks'
import { format } from 'date-fns'

export function UnsubscribedSuccessfulyPage() {
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { handleReturnClick } = useHandlers()
	const user = useToken()

	const endDate = useMemo(
		() =>
			user.subscriptionEndDate
				? format(new Date(user.subscriptionEndDate), 'MM/dd/yyyy')
				: format(new Date(), 'MM/dd/yyyy'),
		[],
	)

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useFetchSubscriptions()

	return (
		<Stack bgcolor={theme.palette.background.default}>
			<SubscriptionsPageStack alignItems='center'>
				<Header hideNavigationMenu />
				<Stack width={mobile ? '100%' : 564} alignItems='center' px={mobile ? 3 : 0} boxSizing={'border-box'}>
					<Stack alignItems='center' flex={1} mb={mobile ? 3 : 7.5}>
						<Typography variant='h6' fontSize='18px' fontFamily='GilroySemiBold' mt={8} color={theme.palette.success.main}>
							PERSONAL SPACE
						</Typography>
						<Typography fontSize={mobile ? 30 : 48} fontFamily='GilroyBold' textAlign='center' color={theme.palette.text.primary} mt={1}>
							Manage Subscription
						</Typography>
					</Stack>

					<StyledStackBG
						mt={mobile ? 0 : 6}
						borderRadius='16px'
						overflow='hidden'
						p={mobile ? 3 : 6}
						alignItems='center'
						boxSizing='border-box'
						width={mobile ? '100%' : 564}
					>
						<Typography color={theme.palette.text.primary} mb={mobile ? -1 : 2.5} fontSize={mobile ? '50px' : '90px'}>
							<PlaylistRemoveIcon fontSize={'inherit'} />
						</Typography>
						<Typography fontFamily='GilroyBold' color={theme.palette.text.primary} variant='h4' mb={1.2} textAlign='center' fontSize={mobile ? 20 : 28}>
							You have successfully unsubscribed.
						</Typography>
						<Typography
							fontFamily='GilroyMedium'
							color={theme.palette.text.secondary}
							variant='body1'
							mb={0.5}
							textAlign='center'
						>
							The subscription will still be valid <br /> until the end of the paid period:
						</Typography>
						<Typography fontSize={mobile ? 16 : 18} pb={3} color={theme.palette.text.primary} fontFamily='GilroyBold'>
							{endDate}
						</Typography>
					</StyledStackBG>

					<Typography
						mt={3}
						fontFamily='GilroyMedium'
						variant='body1'
						color={theme.palette.text.secondary}
						textAlign='center'
					>
						After the end of the paid period and cancellation of the subscription, all users who participate in the
						subscription will lose access to the trainings.
					</Typography>

					<Box width={mobile ? '100%' : 434} mb={mobile ? 2.5 : 3.5} mt={mobile ? 1 : 6}>
						<Button variant='rounded_contained__alert' fullWidth onClick={handleReturnClick}>
							RETURN
						</Button>
					</Box>
				</Stack>
			</SubscriptionsPageStack>
			<Footer onMainPage={false} />
		</Stack>
	)
}
