import {TrainingStatusEnum} from "../../utils/enums";
import {Box, Typography, useTheme} from "@mui/material";
import {useMemo} from "react";

type Props = {
    showStatus: boolean,
    status: TrainingStatusEnum
    timeComplete?: string
}

export function CourseStatus(props: Props) {
    const {showStatus, status, timeComplete} = props
    const theme = useTheme()
    const { title, color} = useMemo(() => {
        switch (status) {
            case TrainingStatusEnum.New:
                return { title: 'New', color: theme.palette.info.main }
            case TrainingStatusEnum.InProgress:
                return {title: 'In Progress', color: theme.palette.warning.main}
            case TrainingStatusEnum.Complete:
                return { title: 'Completed:', color: theme.palette.success.main}
        }
    }, [status, theme.palette.info.main, theme.palette.success.main, theme.palette.warning.main])

    if (!showStatus) return null;

    return (
        <Box py={.5} px={.7} borderRadius='4px' border={`1px solid ${color}`}>
            <Typography fontFamily='GilroyMedium' fontSize={14} color={color} zIndex={2} lineHeight='16px'>
                {title}
                {timeComplete && (
                    <Typography component={'span'}
                                fontFamily='GilroySemiBold'
                                color={theme.palette.text.primary}
                                zIndex={2}
                    >
                        {' '}{timeComplete}
                    </Typography>
                )}
            </Typography>
        </Box>
    )
}
