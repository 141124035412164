import { Stack, styled } from '@mui/material'
import Background from '@Assets/PaymentPageBG.png'

export const OrderDetailsPageStack = styled(Stack, { name: 'OrderDetailsPageStack' })({
	//backgroundImage: `url('${Background}')`,
	//backgroundRepeat: 'no-repeat',
	//backgroundSize: '100%',
	maxHeight: '100vh',
	overflow: 'auto',
})
