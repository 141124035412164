import type { ReportingEffectResponse } from '../types'
import { RequestService } from '@Services'
import type { TrainingsFilters } from '../TrainingsReports/typings'
import { URLS } from '@Utils'
import { fetchTrainingsActionCreator } from '../state/Actions'
import type { TrainingsReportingTableRow } from '../TrainingsReports/components/TrainingsTableContent'

export async function fetchTrainingsReports(filters: TrainingsFilters): Promise<ReportingEffectResponse> {
	const preparedFilters = Object.fromEntries(Object.entries(filters).filter((entry) => !!entry[1])) as TrainingsFilters

	try {
		const response = await RequestService.get<TrainingsFilters, { total: number; items: TrainingsReportingTableRow[] }>(
			URLS.trainingsReports,
			preparedFilters,
			true,
		)

		if (response.status === 200) {
			return { status: 'success', action: fetchTrainingsActionCreator(response.data) }
		}

		return { status: 'error', action: fetchTrainingsActionCreator({ total: 0, items: [] }) }
	} catch (err) {
		return { status: 'error', action: fetchTrainingsActionCreator({ total: 0, items: [] }) }
	}
}
