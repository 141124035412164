import { StyledAlert } from '@Components'

type Props = {
	icon: any,
	text: any,
}

export function AlertMsg(props: Props) {
	const { icon, text } = props

	return (
		<StyledAlert
			icon={icon}
			severity='error'
		>
			{text}
		</StyledAlert>
	)
}
