import { useCallback } from 'react'
import { useSharedContext } from '@Context'
import { openBurgerMenuActionCreator } from 'shared/state/Actions/OpenBurgerMenu'

export function useCloseBurgerMenu() {
	const [, dispatch] = useSharedContext()

	return useCallback(() => {
		dispatch(openBurgerMenuActionCreator(false))
	}, [dispatch])
}
