import joinCommunity from '@Assets/joinCommunity.png'
import bridgeLg from '@Assets/bridge_lg.png'
import Yahoo from '@Assets/yahoo.svg'
import MarketWatch from '@Assets/marketwatch.svg'
import Fox from '@Assets/fox.svg'
import Abc from '@Assets/abc.svg'
import Finanzen from '@Assets/finanzen.svg'
import SeekingAlpha from '@Assets/alpha.svg'
import MarketsInsider from '@Assets/markets.svg'
import AP from '@Assets/ap.svg'
import MorningStar from '@Assets/morning.svg'
import USN from '@Assets/us_news.svg'

import { AlertMsg, IconImage } from '@Components'
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'

import { Formik } from 'formik'
import { useState } from 'react'
import { useContactUsValidationSchema } from '@Hooks'
import { ContactUsForm } from 'shared/components/ContactUsForm'
import { useHandlers } from 'features/contactUsPage/hooks/useHandlers'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { BridgeStack } from './BridgeCard'
import { ContactUsStack } from 'shared/components/ContactUsStack'
import { Buble2 } from './bubles/buble2'

const initialValues = { email: '', name: '', message: '', organization: '' }
type Props = {
	onMainPage: boolean
}

export function JoinCommunity() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const [serverError, setServerError] = useState(false)
	const { handleSubmit } = useHandlers(setServerError)
	const validationSchema = useContactUsValidationSchema()

	return (
		<Stack textAlign='center' mt={6} position='relative'>
			<Box zIndex={2}>
				<IconImage src={joinCommunity} alt='Oculus' width={xlDisplay ? '1180px' : '100%'} />
			</Box>
			<Stack
				id='join-community'
				alignItems='center'
				mt={xlDisplay ? -17 : mobile ? -3 : -9}
				zIndex={4}
				position='relative'
			>
				{serverError && <AlertMsg icon={<LockOpenIcon />} text='Email doesnt exist' />}
				<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
					<ContactUsForm onMainPage={true} />
				</Formik>
			</Stack>
			<BridgeStack>
				<Box zIndex={2}>
					<IconImage src={bridgeLg} alt='bridge' width='162px' />
				</Box>
				<Typography
					fontFamily='GilroyBold'
					fontSize={xlDisplay ? 30 : 26}
					color='#fff'
					textAlign={mobile ? 'center' : 'left'}
				>
					Innovative Virtual Reality Technology for Learning & Development
				</Typography>
			</BridgeStack>
			<Stack
				width={xlDisplay ? 862 : '100%'}
				mx='auto'
				mt='-50px'
				px={xlDisplay ? 8 : mobile ? 2 : 3}
				pb={xlDisplay ? 8 : 3}
				pt={10}
				boxSizing='border-box'
				position='relative'
			>
				<Typography
					fontFamily='GilroyBold'
					fontSize={30}
					color={theme.palette.text.primary}
					textAlign='left'
					mb={3}
					zIndex={4}
				>
					About the company
				</Typography>
				<Typography
					fontFamily='GilroySemiBold'
					fontSize={18}
					color={theme.palette.text.secondary}
					textAlign='left'
					mb={3}
					zIndex={4}
				>
					Welcome to CleVR, a team of educators and VR experts dedicated to providing immersive science education
					experiences for students. Our dream is to make learning more accessible and engaging for students while
					supporting teachers across the globe.
				</Typography>
				<Typography
					fontFamily='GilroySemiBold'
					fontSize={18}
					color={theme.palette.text.secondary}
					textAlign='left'
					mb={3}
					zIndex={4}
				>
					At CleVR, we believe that learning experiences should empower students to be inquisitive and active learners,
					and inspire students to pursue STEM careers in the future. We offer a range of interactive and immersive
					learning experiences that help students develop critical thinking, problem-solving, and collaboration skills.
					Our team is committed to creating high-quality educational content that aligns with the national curriculum
					and ensures that students are well-prepared for their exams.
				</Typography>
				<Typography
					fontFamily='GilroySemiBold'
					fontSize={18}
					color={theme.palette.text.secondary}
					textAlign='left'
					zIndex={4}
				>
					We are passionate about making a difference in education and empowering the next generation of scientists,
					engineers, and innovators. Join us in our mission to revolutionise science education and inspire the
					scientists of tomorrow.
				</Typography>
				{xlDisplay && (
					<>
						<Stack
							direction='row'
							alignItems='center'
							justifyContent='space-between'
							mt={6}
							zIndex={4}
							flexWrap='nowrap'
						>
							<Box zIndex={2}>
								<IconImage src={Yahoo} alt='Yahoo' />
							</Box>
							<Box zIndex={2}>
								<IconImage src={MarketWatch} alt='MarketWatch' />
							</Box>
							<Box zIndex={2}>
								<IconImage src={Fox} alt='Fox' />
							</Box>
							<Box zIndex={2}>
								<IconImage src={Abc} alt='Abc' />
							</Box>
							<Box zIndex={2}>
								<IconImage src={Finanzen} alt='Finanzen' />
							</Box>
						</Stack>
						<Stack
							direction='row'
							alignItems='center'
							justifyContent='space-between'
							mt={4}
							zIndex={4}
							flexWrap='nowrap'
						>
							<Box zIndex={2}>
								<IconImage src={MarketsInsider} alt='MarketsInsider' />
							</Box>
							<Box zIndex={2}>
								<IconImage src={MorningStar} alt='MorningStar' />
							</Box>
							<Box zIndex={2}>
								<IconImage src={AP} alt='AP' />
							</Box>
							<Box zIndex={2}>
								<IconImage src={USN} alt='USN' />
							</Box>
							<Box zIndex={2}>
								<IconImage src={SeekingAlpha} alt='SeekingAlpha' />
							</Box>
						</Stack>
					</>
				)}
				{!xlDisplay && (
					<Stack
						direction='row'
						alignItems='center'
						justifyContent='space-between'
						mt={3}
						zIndex={4}
						flexWrap='wrap'
					>
						<Box zIndex={2} mb={1.5} mr={1}>
							<IconImage src={Yahoo} alt='Yahoo' />
						</Box>
						<Box zIndex={2} mr={1}>
							<IconImage src={MarketWatch} alt='MarketWatch' />
						</Box>
						<Box zIndex={2} mr={1}>
							<IconImage src={Fox} alt='Fox' />
						</Box>
						<Box zIndex={2} mb={1.5} mr={1}>
							<IconImage src={Abc} alt='Abc' />
						</Box>
						<Box zIndex={2} mr={1}>
							<IconImage src={Finanzen} alt='Finanzen' />
						</Box>
						<Box zIndex={2} mr={1}>
							<IconImage src={MarketsInsider} alt='MarketsInsider' />
						</Box>
						<Box zIndex={2} mb={1.5} mr={1}>
							<IconImage src={MorningStar} alt='MorningStar' />
						</Box>
						<Box zIndex={2} mr={1}>
							<IconImage src={AP} alt='AP' />
						</Box>
						<Box zIndex={2} mr={1}>
							<IconImage src={USN} alt='USN' />
						</Box>
						<Box zIndex={2}>
							<IconImage src={SeekingAlpha} alt='SeekingAlpha' />
						</Box>
					</Stack>
				)}
				<ContactUsStack />
			</Stack>
			<Buble2 top='20%' left='15%' />
			<Buble2 top='70%' left='70%' />
		</Stack>
	)
}
