import { Stack, styled } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'

export const StyledStack = styled(Stack, { name: 'StyledStack' })({
  backgroundColor: '#F4F4F3',
  border: '1px solid #FFFFFF57',
  borderBottom: '0',
  borderRadius: '10px 10px 0 0',
  boxSizing: 'border-box',
  marginTop: '88px',
  maxWidth: '1180px',
	padding: '20px 20px 20px 30px',
  width: '100%',
  [`@media (max-width: ${DisplayEnum.mobile}px)`]: {
    marginTop: '30px',
    padding: '30px',
  }
})
