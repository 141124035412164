import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { PriceCard } from './PriceCard'
import { useData } from './hooks'
import { BlockHeader } from '../BlockHeader'
import { DisplayEnum } from 'shared/enums/display.enum'
import { PricingBackStack } from './PricingBackStack'
import { InfoTextStack } from './InfoTextStack'
import { Buble2 } from '../bubles/buble2'

export function Pricing() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { cards, currentSubscription } = useData()

	return (
		<Stack pt={mobile ? 2 : 6} id='pricing' width={xlDisplay ? 1180 : '100%'} mx={'auto'} position='relative' px={xlDisplay ? 0 : mobile ? 2 : 3} boxSizing='border-box'>
			<Typography fontSize={38} fontFamily='GilroyBold' color={theme.palette.text.primary} textAlign='left' mb={mobile ? 1 : 3}>
				Pricing
			</Typography>
			<Stack mt={3} direction={xlDisplay ? 'row' : 'column'} alignItems='center' justifyContent='space-between'>
				<Box position='relative'>
					<PriceCard options={cards.standard} disabled={currentSubscription >= 1} />
					<PricingBackStack />
				</Box>
				<Box position='relative'>
					<PriceCard options={cards.pro} disabled={currentSubscription >= 2} />
					<PricingBackStack />
				</Box>
				<Box position='relative'>
					<PriceCard options={cards.enterprise} disabled={currentSubscription >= 3} />
					<PricingBackStack />
				</Box>
			</Stack>
			<Stack mt={1.5} py={mobile ? 2 : 3} px={xlDisplay ? 18 : mobile ? 2 : 3} textAlign='center' position='relative'>
				<Typography fontFamily='GilroyMedium' color={theme.palette.text.primary} zIndex={4}>
					If you want to <strong>join the future</strong> of learning, don't let finances stand in the way.
					<br />
					<strong>Feel free to contact us</strong> for more information on spreading out the cost of purchasing VR
					headsets.
				</Typography>
				<InfoTextStack />
			</Stack>
			<Buble2 top='-35%' left='-67%'/>
		</Stack>
	)
}
