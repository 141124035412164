import Physics from '@Assets/physics.png'
import Biology from '@Assets/biology.png'
import Chemistry from '@Assets/chemistry.png'
import ArrowRightCircle from '@Assets/arrow-right-circle.svg'
import Oculus from '@Assets/oculus.svg'

import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { IconImage } from '@Components'

import { useHandlers } from './hooks'
import { DisplayEnum } from 'shared/enums/display.enum'
import { StyledBiologyCard, StyledChemistryCard, StyledPhysicsCard } from './CourseCardsCategory'
import { Buble1 } from './bubles/buble1'
import { Buble2 } from './bubles/buble2'

export function Description() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { handleTrainings, handleTrainingsTypeChange } = useHandlers()

	return (
		<Stack
			mt={xlDisplay ? 9 : 3}
			borderRadius='0 0 30px 30px'
			bgcolor='#F4F4F3'
			position='relative'
			zIndex={4}
			px={xlDisplay ? 0 : mobile ? 2 : 3}
			id='courses'
		>
			<Stack textAlign='center'>
				<Typography fontSize={mobile ? 24 : xlDisplay ? 44 : 38} fontFamily='GilroyBold' variant='h3' color='#000'>
					Welcome to CleVR.
				</Typography>
				<Typography fontSize={mobile ? 24 : xlDisplay ? 44 : 38} fontFamily='GilroyBold' variant='h3' color='#000'>
					Where Education Meets Virtual Reality
				</Typography>
				<Typography
					fontFamily='GilroySemiBold'
					fontSize={mobile ? 14 : xlDisplay ? 18 : 16}
					mt={2}
					color='rgba(0, 0, 0, 0.7)'
					maxWidth='740px'
					mx='auto'
				>
					Our mission is to revolutionise the way students learn by leveraging the power of virtual reality. In CleVR’s
					virtual lessons, we aim to provide a fully immersive environment where students can visualise and interact
					across all disciplines of science.
				</Typography>
			</Stack>
			<Stack
				direction={mobile ? 'column' : 'row'}
				alignItems={mobile ? 'center' : 'flex-start'}
				justifyContent={'space-between'}
				width={xlDisplay ? 1180 : '100%'}
				mt='45px'
				mx='auto'
				id='courses'
			>
				<StyledPhysicsCard onClick={() => handleTrainingsTypeChange(0)}>
					<IconImage src={Physics} alt='Physics' width='159px'/>
					<Typography fontSize={24} fontFamily='GilroyBold' variant='h3' color='rgba(30, 28, 48, 1)' mt='20px'>
						Physics
					</Typography>
					<Typography
						fontFamily='GilroySemiBold'
						fontSize={14}
						mt={1}
						color='rgba(0, 0, 0, 0.7)'
						maxWidth='230px'
						mx='auto'
					>
						From black holes to quantum mechanics and all of Newton’s laws inbetween.
					</Typography>
				</StyledPhysicsCard>
				<StyledBiologyCard onClick={() => handleTrainingsTypeChange(1)}>
					<IconImage src={Biology} alt='Biology' width='159px'/>
					<Typography fontSize={24} fontFamily='GilroyBold' variant='h3' color='rgba(30, 28, 48, 1)' mt='20px'>
						Biology
					</Typography>
					<Typography
						fontFamily='GilroySemiBold'
						fontSize={14}
						mt={1}
						color='rgba(0, 0, 0, 0.7)'
						maxWidth='235px'
						mx='auto'
					>
						Unleash your inner biologist! Explore life from the tiniest microbe to the mightiest whale in VR!
					</Typography>
				</StyledBiologyCard>
				<StyledChemistryCard onClick={() => handleTrainingsTypeChange(2)}>
					<IconImage src={Chemistry} alt='Chemistry' width='159px'/>
					<Typography fontSize={24} fontFamily='GilroyBold' variant='h3' color='rgba(30, 28, 48, 1)' mt='20px'>
						Chemistry
					</Typography>
					<Typography
						fontFamily='GilroySemiBold'
						fontSize={14}
						mt={1}
						color='rgba(0, 0, 0, 0.7)'
						maxWidth='235px'
						mx='auto'
					>
						Explore life-size atoms, travel through time, and dive into experiments, literally!
					</Typography>
				</StyledChemistryCard>
			</Stack>
			<Stack direction={mobile ? 'column-reverse' : 'row'} justifyContent='center' mt='30px' position='relative'>
				<Button variant='primary_btn' onClick={handleTrainings}>
					<Box mr={1} display='flex' alignItems='center' justifyContent='center'>
						<IconImage src={ArrowRightCircle} alt='View all cources' width='20px' />
					</Box>
					View all courses
				</Button>
				<Box
					position={mobile ? 'static' : 'absolute'}
					top='16px'
					left={'calc(50% + 140px)'}
					margin={mobile ? '0 auto 20px' : 0}
				>
					<IconImage src={Oculus} alt='oculus' width='140px' />
				</Box>
			</Stack>
			<Buble1 top='-113%' left='76%' />
			<Buble2 top='-60%' left='-56%' />
		</Stack>
	)
}
