import { styled } from '@mui/material'

export const StyledTextArea = styled('textarea')({
	padding: '15px 18px',
	borderRadius: '4px',
	outline: 'none',
	boxShadow: 'none',
	border: '1px solid #D2D2D2',
	fontFamily: 'GilroySemiBold',
	resize: 'none',
	height: '107px',
})
