import {Box, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import {DisplayEnum} from "@Enums";
import {useState} from "react";
import {useOculusCode} from "../hooks";

// function formatTimeLeft(time: number) {
// 	const minutes = Math.floor(time / 60)
//
// 	let seconds = time % 60
//
// 	return (
// 		<Typography variant='caption' fontSize={16} component='div' color='primary' fontFamily='GilroyRegular'>
// 			{`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
// 		</Typography>
// 	)
// }

export function OculusCard(props: { timer: number, canGenerateCode: boolean }) {
    const {timer, canGenerateCode} = props
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
    const [code, setCode] = useState('')

    useOculusCode(canGenerateCode, setCode, timer)

    return (
        <Stack>
            <Typography fontFamily='gilroySemiBold' fontSize={22} color={theme.palette.text.primary} mb={1}>
                Code for Oculus
            </Typography>
            <Typography fontFamily='gilroySemiBold' fontSize={18} color={theme.palette.text.secondary}>
                Go to the oculus and enter the code there to gain access to the training.
            </Typography>
            <Stack direction='row' alignItems='center' spacing={2} mt={3} zIndex={1}>
                <CountdownCircleTimer
                    size={40}
                    strokeWidth={6}
                    isPlaying
                    duration={900}
                    trailColor='#9945FF'
                    colors='#CBC3E3'
                    onComplete={() => {
                        return { shouldRepeat: true }
                    }}
                >
                    {({ remainingTime }) => ''}
                </CountdownCircleTimer>
                <Typography fontSize={44} fontFamily='GilroyBold' color='#000'>
                    {code}
                </Typography>
            </Stack>
            <Box border='1px solid rgba(30, 28, 48, .3)' width='100%' my={4}></Box>
        </Stack>
    )
}
