import MailForward from '@Assets/email_forward_mail.svg'

import { Box, Stack, Typography, Button, useTheme, useMediaQuery } from '@mui/material'
import { DividerGray, IconImage } from '@Components'
import type { CardOptions } from '@Types'
import { useCardDisabled, useHandlers } from './hooks'
import { SubscriptionPlansEnum } from '@Enums'
import { useToken } from '@Hooks'
import { DisplayEnum } from 'shared/enums/display.enum'

type Props = {
	options: CardOptions
}

export function Card(props: Props) {
	const { options } = props
	const theme = useTheme()
	const showBestsellerLabel = options.showLabel
	const showContactLabel = options.showContactInfo
	const showPrices = !options.showContactInfo
	const enterpriseCard = options.plan === SubscriptionPlansEnum.Enterprise

	const { handleBuyClick } = useHandlers(options.plan)
	const user = useToken()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const disabled = user.subscriptionPlan >= options.plan

	return (
		<Stack
			bgcolor='#F4F4F3'
			width={mobile ? '100%' : 381}
			height={mobile ? '100%' : 541}
			borderRadius='16px'
			boxSizing='border-box'
			position='relative'
			mb={xlDisplay ? 0 : 2}
		>
			{disabled && (
				<Stack
					position='absolute'
					top={0}
					right={0}
					left={0}
					bottom={0}
					zIndex={999}
					bgcolor={theme.palette.common.black}
					borderRadius='16px'
				></Stack>
			)}
			{showBestsellerLabel && (
				<Stack position='absolute' top={-18} left={mobile ? 100 : 122} zIndex={555}>
					<Box
						pt={0.6}
						height={36}
						width={130}
						boxSizing='border-box'
						bgcolor={theme.palette.primary.main}
						borderRadius='48px'
						boxShadow='0px 24px 76px #00000057'
					>
						<Typography
							fontSize={16}
							textAlign='center'
							fontFamily='GilroyBold'
							color={theme.palette.info.main}
						>
							BEST CHOICE
						</Typography>
					</Box>
				</Stack>
			)}

			<Stack px={2.5} py={2.2}>
				<Stack direction='column' justifyContent='space-between' height='100%'>
					<Stack height={140} alignItems='center'>
						<Typography fontSize={28} fontFamily='GilroyBold' color={theme.palette.text.primary} mb={1}>
							{options.title}
						</Typography>
						<Typography
							fontSize={16}
							fontFamily='GilroyRegular'
							color={theme.palette.text.secondary}
							textAlign='center'
							lineHeight={1.2}
						>
							{options.description}
						</Typography>
						{enterpriseCard && (
							<Typography
							fontSize={16}
							fontFamily='GilroyRegular'
							color={theme.palette.text.secondary}
							textAlign='center'
							mt={1}
							lineHeight={1.2}
						>
							{options.subDescription}
						</Typography>
						)}
					</Stack>

					{showContactLabel && (
						<Stack mt={9.8} mb={0.8} alignItems='center'>
							<IconImage src={MailForward} height='60px' width='75px' />
							<Typography
								fontSize={15}
								mt={1}
								fontFamily='GilroySemiBold'
								color={theme.palette.text.primary}
								textAlign='center'
							>
								CONTACT US TO DISCUSS YOUR REQUIREMENTS AND PRICING
							</Typography>
						</Stack>
					)}
					{showPrices && (
						<Stack>
							<Stack mt={1.5} mb={4}>
								<Stack mx='auto' direction='row' alignItems='baseline'>
									<Typography fontSize={26} mr={0.5} fontFamily='GilroyBold' color={theme.palette.text.primary}>
										£
									</Typography>
									<Typography fontSize={100} fontFamily='GilroyBold' color={theme.palette.text.primary}>
										{options.price}
									</Typography>
									<Typography fontSize={26} fontFamily='GilroyBold' color={theme.palette.text.primary}>
										GBP
									</Typography>
								</Stack>
								<Typography
									fontSize={15}
									mt={-3}
									fontFamily='GilroySemiBold'
									color={theme.palette.text.secondary}
									textAlign='center'>
									PER MONTH
								</Typography>
							</Stack>

							<Stack direction='row' mx='auto' spacing={2}>
								<Stack justifyContent='center'>
									<Typography fontSize={15} fontFamily='GilroySemiBold' color={theme.palette.text.secondary}>
										SEAT PRICE
									</Typography>

									<Typography fontSize={26} fontFamily='GilroyBold' color={theme.palette.text.primary}>
										£ {options.seatPrice} GBP
									</Typography>
								</Stack>
								<DividerGray orientation='vertical' />
								<Stack>
									<Typography fontSize={15} fontFamily='GilroySemiBold' color={theme.palette.text.secondary}>
										OCULUS USERS
									</Typography>

									<Typography fontSize={26} fontFamily='GilroyBold' color={theme.palette.text.primary}>
										{options.seats} SEATS
									</Typography>
								</Stack>
							</Stack>
						</Stack>

					)}
				</Stack>

				<Stack mt={1.8}>
					<Button variant='price_card_green' onClick={handleBuyClick} fullWidth>
						{enterpriseCard ? 'CONTACT US' : 'SELECT & BUY'}
					</Button>
					<Box >
						<Typography
							fontSize={15}
							mt={1.5}
							
							fontFamily='GilroySemiBold'
							color={theme.palette.text.secondary}
							textAlign='center'
							lineHeight={1.2}
						>
							{options.bottomText}
						</Typography>
					</Box>
				</Stack>
			</Stack>
		</Stack>
	)
}
