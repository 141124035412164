import { useContext } from 'react'
import { ReportingContext } from '../context'

export function useReportsContext() {
	const context = useContext(ReportingContext)

	if (context === undefined) throw Error('Must be descendant of ReportingContextProvider')

	return context
}
