import { useEffect } from 'react'
import { fetchSubscriptionsEffect } from '../effects'
import { useSubscriptionsContext } from '../context'

export function useFetchSubscriptions() {
	const [, dispatch] = useSubscriptionsContext()

	useEffect(() => {
		let mounted = true

		const execute = async () => {
			const response = await fetchSubscriptionsEffect()

			if (!mounted) return

			dispatch(response.action)
		}

		execute()

		return () => {
			mounted = false
		}
	}, [dispatch])
}
