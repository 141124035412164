import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSharedContext } from '@Context'
import { logoutActionCreator } from '../state/Actions/Logout'
import {useMediaQuery, useTheme} from "@mui/material";
import {DisplayEnum} from "@Enums";
import {useMsalInstance} from "./useMsalInstance";
import {useMsalLogout} from "./useMsalLogout";

export function useLogout() {
	const navigate = useNavigate()
	const theme = useTheme()
	const [, dispatch] = useSharedContext()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const msalInstance = useMsalInstance();
	const msalLogout = useMsalLogout(mobile, msalInstance)

	return useCallback(async () => {
		const localStorageUser = JSON.parse(localStorage.getItem('user') || '{}')
		const loginType = localStorage.getItem('login')

		if (loginType && loginType === 'msal') {
			await msalLogout()
		}

		if (localStorageUser.token) {
			localStorage.clear()
			dispatch(logoutActionCreator())
			navigate('/')
		}
	}, [dispatch, msalLogout, navigate])
}
