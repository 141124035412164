import {Box, Stack, Typography, useMediaQuery, useTheme} from '@mui/material'
import { Form, useFormikContext } from 'formik'
import Button from '@mui/material/Button'
import type { DownloadInvoiceContext } from './DownloadInvoiceDialog'
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled'
import { AddressField, CityField, CompanyNameField, EmailField, TelephoneField, ZipcodeField } from './fields'
import {DisplayEnum} from "@Enums";

type Props = {
	handleCancel: () => void
	showError: any
}

export function DownloadInvoiceForm(props: Props) {
	const { handleCancel, showError } = props
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	return (
		<Form>
			<Stack>
				{!!Object.entries(showError).length && (
					<Stack
						spacing={2}
						p={3}
						bgcolor='#AA1D1D42'
						border='1px solid #FD2C2C'
						borderRadius='6px'
						direction='row'
						alignItems='center'
					>
						<Box color='#FD2C2C'>
							<PersonAddDisabledIcon />
						</Box>
						<Typography variant='body1' color={theme.palette.text.primary}>
							{Object.entries(showError)[0][1] as any}
						</Typography>
					</Stack>
				)}
				<CompanyNameField />
				<AddressField />

				<Stack direction={mobile ? 'column' : 'row' } spacing={2}>
					<CityField />
					<ZipcodeField />
				</Stack>

				<Stack direction={mobile ? 'column' : 'row' } spacing={2}>
					<TelephoneField />
					<EmailField />
				</Stack>
				<Box mb={3} width='256px' marginX='auto'>
					<Button type='submit' variant='rounded_success' sx={{ width: '100%' }}>
						SUBMIT
					</Button>
				</Box>
				<Button autoFocus onClick={handleCancel} variant='text'>
					Cancel
				</Button>
			</Stack>
		</Form>
	)
}
