import Logo from '@Assets/clvr_logo1.png'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import Video from '@Assets/clvr_login_video.mp4'

import {Box, Button, Stack, Typography, useMediaQuery, useTheme} from '@mui/material'
import { Formik } from 'formik'
import { AlertMsg, IconImage } from '@Components'
import { useHandlers } from './hooks'
import { LoginForm } from './LoginForm'
import { LoginValidationSchema } from './validationSchema'
import { useEffect, useState } from 'react'
import { BackgroundStackLogin, BoxBackgroundStack } from '../components'
import { DisplayEnum } from 'shared/enums/display.enum'
import Microsoft from '@Assets/Microsoft_logo.png'
import {useMsalLogin} from "../../../shared/hooks/useMsalLogin";
import { useMsalInstance } from 'shared/hooks/useMsalInstance'

export function LoginPage() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { handleSubmit, handleMainClick } = useHandlers()
	const [error, setError] = useState(false)
	const initialValues = { email: localStorage.getItem('email') || '', password: '' }
	const msalInstance = useMsalInstance();
	const msalLogin = useMsalLogin(mobile, msalInstance);

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Stack overflow='hidden' height='100vh'>
			<Box position='absolute' zIndex={-1} width='100%' height='100%' overflow='hidden'>
				<video src={Video} controls={false}
					   autoPlay
					   loop
					   muted
					   style={{
						   position: 'absolute',
						   objectFit: 'cover',
						   width: '100%',
						   height: '100%',
						   top: '50%',
						   left: '50%',
						   transform: 'translate(-50%, -50%)'
					   }}
				></video>
			</Box>
			<BackgroundStackLogin
				width='100%'
				height='100%'
				minHeight='100vh'
				pb={xlDisplay ? 14.5 : 0}
				px={mobile ? 3 : 0}
				boxSizing={mobile ? 'border-box' : 'content-box'}
				overflow='auto'
			>
				<Stack>
					<BoxBackgroundStack
						pb={mobile ? 3 : 2.4}
						borderRadius='30px'
						mt={mobile ? 5 : 7}
						ml={xlDisplay ?  7 : mobile ? 0 : 'auto'}
						mr={xlDisplay || mobile ? 0 : 'auto'}
						width={mobile ? '100%' : 530}
						boxSizing='border-box'
						zIndex={10}
					>
						<Box boxShadow='0px 24px 76px #0000001C' borderRadius='30px' bgcolor='#FFFFFF' pb={3} pt={mobile ? 3 : 5} px={mobile ? 3 : 5}>
							<Stack direction='row' spacing={2.5} mb={2}>
								<Box onClick={handleMainClick} sx={{ cursor: 'pointer'}}>
									<IconImage src={Logo} alt='logo' width='60.5px' />
								</Box>
								<Box>
									<Typography fontSize={mobile ? 24 : 28} variant='h4' color={theme.palette.text.primary} fontFamily='GilroyBold'>
										Welcome to CleVR
									</Typography>
									<Typography fontFamily='GilroySemiBold' color='rgba(0, 0, 0, .7)' lineHeight='19px' mt={1}>
										Use your Microsoft account for access our immersive virtual reality experiences.
									</Typography>
								</Box>
							</Stack>

							<Button variant='rounded_contained__microsoft' fullWidth onClick={msalLogin}>
								<Stack direction='row' spacing={1} alignItems='center'>
									<IconImage src={Microsoft} width='21px' />
									<Typography fontSize={mobile ? 12 : 16}>SIGN IN WITH MICROSOFT</Typography>
								</Stack>
							</Button>
						</Box>

						{error && <Box mt={1} height='70px' px={mobile ? 3 : 5}>
							<AlertMsg icon={<LockOpenIcon />} text='Email or password is incorrect. Correct or add new ones.' />
						</Box>}

						<Formik
							initialValues={initialValues}
							onSubmit={(values) => handleSubmit(values, setError)}
							validationSchema={LoginValidationSchema}
						>
							<LoginForm />
						</Formik>
					</BoxBackgroundStack>
				</Stack>
			</BackgroundStackLogin>
			)
		</Stack>)
}
