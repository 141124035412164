import { useEffect } from 'react'
import { fetchCodeEffect } from '../../effects'
import { useParams } from 'react-router-dom'

export function useOculusCode(canGenerateCode: boolean, setCode: (code: string) => void, timer: number) {
	const { id } = useParams<{ id: string }>()
	useEffect(() => {
		if (!canGenerateCode) return

		let mounted = true

		async function execute() {
			const response = await fetchCodeEffect(id || '')

			if (!mounted) return

			setCode(response.code)
		}

		execute()

		return () => {
			mounted = false
		}
	}, [canGenerateCode, id, setCode, timer])
}
