
import { Typography, useTheme } from '@mui/material'

export function InterpretationAndDefinitions() {
	const theme = useTheme()

	return (
		<>
			<Typography fontFamily='GilroyBold' variant='h4' color={theme.palette.text.primary}>
				<u>Interpretation and Definitions</u>
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Interpretation
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Definitions
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				For the purposes of this Privacy Policy:
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Account</strong>
				</Typography>{' '}
				means a unique account created for You to access our Service or parts of our Service.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Affiliate</strong>
				</Typography>{' '}
				means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Application</strong>
				</Typography>{' '}
				refers to CleVR, the software program provided by the Company.

			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Company</strong>
				</Typography>{' '}
				(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Bridge Learning Tech, Cambridge House, Bath, BA11BT.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Country</strong>
				</Typography>{' '}
				refers to: United Kingdom
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Device</strong>
				</Typography>{' '}
				means any device that can access the Service such as a computer, a cellphone or a digital tablet.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Personal Data</strong>
				</Typography>{' '}
				is any information that relates to an identified or identifiable individual.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Service</strong>
				</Typography>{' '}
				refers to the Application.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Service Provider</strong>
				</Typography>{' '}
				means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Usage Data</strong>
				</Typography>{' '}
				refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>You</strong>
				</Typography>{' '}
				means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
			</Typography>
		</>
	)
}
