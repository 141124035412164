import type { TrainingStatuses } from '../../../TrainingsReports/typings'
import { ReportingState } from '../../typings'

export const fetchTrainingsStatusesReportingProducer = (
	state: ReportingState,
	payload: TrainingStatuses,
): ReportingState => ({
	...state,
	statuses: payload,
})
