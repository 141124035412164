import { Box, Button, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import ArrowLeft from '@Assets/left-arrow.svg'

import { DividerGray, IconImage } from '@Components'

import { DetailedBox } from './DetailedBox'
import { useGoToSubscriptions, useGoToPreviousPage } from '@Hooks'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DisplayEnum } from 'shared/enums/display.enum'
import { StoreUser } from '@State'
import { User } from '@Types'
import { CardBox } from './CardBox'

export function OrderDetailsPageComponent() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const goToSubscriptions = useGoToSubscriptions()
	const goToPrevious = useGoToPreviousPage()
	const navigate = useNavigate()

	useEffect(() => {
		const selectedPlan = JSON.parse(localStorage.getItem('selectedPlan') || '0')
		localStorage.removeItem('email')

		if (!selectedPlan) {
			navigate('/subscriptions/all')
		}
	}, [navigate])

	return (
		<Stack mx='auto' my={xlDisplay ? 7 : 2} maxWidth={mobile ? '100%' : 400} p={mobile ? '0 30px' : 0} boxSizing='border-box'>
			{mobile ?
				(
					<Stack direction='row' width='100%' position='relative' mb={1}>
						<Box >
							<Button variant='courses_back_small' onClick={goToPrevious}>
								<IconImage src={ArrowLeft} alt='arrow' height='16px' width='16px' />
							</Button>
						</Box>

						<Stack ml={2} >
							<Typography fontSize={14} color={theme.palette.success.main} fontFamily='GilroySemiBold' textAlign='start'>
								PAY DETAILS
							</Typography>

							<Typography fontSize={24} color='primary' mt={0.2} fontFamily='GilroyBold' textAlign='start'>
								Order details
							</Typography>
						</Stack>

					</Stack>
				) : (
					<Stack direction='row' width='100%' position='relative'>
						<Box position='absolute' left={-95}>
							<Button variant='courses_back' onClick={goToPrevious}>
								<IconImage src={ArrowLeft} alt='arrow' height='16px' width='16px' />
							</Button>
						</Box>

					</Stack>
				)}

			<Stack mb={3} justifyContent='center'>
				<DetailedBox />
			</Stack>
			<Stack>
				<CardBox />
			</Stack>

			<DividerGray />

			<Box mt={4.5}>
				<Typography fontSize={mobile ? 14 : 16} mb={2} color={theme.palette.text.secondary} fontFamily='GilroyMedium' textAlign='center'>
					We will process your personal data to perform our contractual obligations, to improve our products.
				</Typography>
				<Typography fontSize={mobile ? 14 : 16} mb={0.6} color={theme.palette.text.secondary} fontFamily='GilroyMedium' textAlign='center'>
					For more details please refer to:
				</Typography>
				<Typography fontSize={mobile ? 14 : 16} mb={1} fontFamily='GilroyMedium' textAlign='center'>
					<Link target='_blank' href='/terms-and-conditions' color={theme.palette.info.contrastText}>CleVR Purchase Terms</Link>
				</Typography>
				<Typography fontSize={mobile ? 14 : 16} fontFamily='GilroyMedium' textAlign='center'>
					<Link target='_blank' href='/privacy-policy' color={theme.palette.info.contrastText} >CleVR Privacy Policy</Link>
				</Typography>
			</Box>
		</Stack>
	)
}
