import { Field, useFormikContext } from 'formik'
import { TextField } from '@Components'
import { Box, Typography } from '@mui/material'
import type { DownloadInvoiceContext } from '../DownloadInvoiceDialog'

export function EmailField() {
	const { errors, touched, values } = useFormikContext<DownloadInvoiceContext>()

	return (
		<Box flex={1} position='relative' pb={4}>
			<Field
				id='email'
				name='email'
				label='EMAIL'
				type='text'
				placeholder=''
				component={TextField}
				defaultValue={values.email}
			/>
			{errors.email && touched.email ? (
				<Typography variant='caption' color='red' position='absolute' left={0} bottom={0}>
					{errors.email}
				</Typography>
			) : null}
		</Box>
	)
}
