import { Box, Button, Container, Link, Stack, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import Logo from '@Assets/clvr_logo1.png'
import BurgerMenuImg from '@Assets/burgerMenu.svg'
import { HashLink, IconImage, StyledAvatar, StyledBox } from '@Components'
import { useGoToLoginPage, useGoToMain, useIsLoggedIn, useLogout, useOpenBurgerMenu, useToken } from '@Hooks'
import { StyledBar } from './StyledBar'
import { RolesService } from '../services/roles.service'
import { RolesEnum } from '@Enums'
import { DisplayEnum } from 'shared/enums/display.enum'
import { BurgerMenu } from './BurgerMenu'
import {useCallback, useState} from 'react'
import CoursesIco from '@Assets/clvr_menu_courses.svg'
import HIVIco from '@Assets/clvr_menu_hiv.svg'
import PricingIco from '@Assets/clvr_menu_pricing.svg'
import ContactIco from '@Assets/clvr_menu_contact.svg'
import SigninIco from '@Assets/clvr_menu_signin.svg'
import LogoutIco from '@Assets/clvr_menu_signin.svg'
import SubscriptionsIco from '@Assets/clvr_menu_info.svg'
import {useNavigate} from "react-router-dom";

type Props = {
	hideNavigationMenu?: boolean
	showReportingMenu?: boolean
}

export function Header(props: Props) {
	const isLoggedIn = useIsLoggedIn()
	const user = useToken()
	const { hideNavigationMenu, showReportingMenu } = props
	const [showThankYou, setShowThankYou] = useState(false)
	const goToMain = useGoToMain(setShowThankYou)
	const handleLogout = useLogout()
	const handleOpenBurgerMenu = useOpenBurgerMenu()
	const signIn = useGoToLoginPage()
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const navigate = useNavigate()

	const showSubscriptions = RolesService.availableForRole([RolesEnum.SuperAdmin, RolesEnum.Admin])
	const showTrainings = RolesService.availableForRole([
		RolesEnum.Admin,
		RolesEnum.SuperUser,
		RolesEnum.User,
		RolesEnum.SuperAdmin,
		RolesEnum.Guest,
	])
	const showReports = false //RolesService.availableForRole([
	// 	RolesEnum.SuperAdmin,
	// 	RolesEnum.SuperUser,
	// 	RolesEnum.Admin,
	// 	RolesEnum.Guest,
	// ])

	return (
		<StyledBox width='100%' zIndex={1000} boxSizing='border-box' borderRadius='0 0 30px 30px'>
			<StyledBar>
				<Container maxWidth='xl' disableGutters={!xlDisplay}>
					<Toolbar>
						<Stack
							alignItems='center'
							justifyContent='space-between'
							flexDirection={mobile || !xlDisplay ? 'row-reverse' : 'row'}
							direction='row'
							height='100px'
							width='100%'
							mx={xlDisplay ? 7 : 0}
						>
							{!xlDisplay && (
								<Box mr={1} onClick={handleOpenBurgerMenu}>
									<IconImage src={BurgerMenuImg} alt='menu' width='24px' />
								</Box>
							)}

							{xlDisplay && !hideNavigationMenu && (
								<Stack direction='row' fontFamily='GilroyRegular' spacing={2.4}>
									<HashLink to='/#courses'>
										<img src={CoursesIco} width={12} /> Courses
									</HashLink>
									<HashLink to='/#about'>
										<img src={HIVIco} width={15} /> How it works
									</HashLink>
									<HashLink to='/#pricing'>
										<img src={PricingIco} width={15} /> Pricing
									</HashLink>
								</Stack>
							)}

							{xlDisplay && hideNavigationMenu && isLoggedIn && (
								<Stack direction='row' fontFamily='GilroyRegular' spacing={4}>
									{showTrainings && !showReportingMenu && (
										<HashLink to='/trainings'>
											<img src={CoursesIco} width={12} /> Courses
										</HashLink>
									)}
									{showSubscriptions && (
										<HashLink to='/subscriptions'>
											<img src={SubscriptionsIco} width={12} /> Subscription
										</HashLink>
									)}
								</Stack>
							)}

							<img src={Logo} alt='logo' onClick={goToMain} width={60.5} />

							{xlDisplay && !hideNavigationMenu && (
								<Stack direction='row' fontFamily='GilroyRegular' spacing={2.4} alignItems='center'>
									<HashLink to='/#contacts'>
										<Stack direction='row' flexWrap='nowrap' alignItems='center' spacing={0.5}>
											<img src={ContactIco} width={17.5} />
											<Typography
												color='rgba(0, 0, 0, .7)'
												fontFamily='GilroySemiBold'
												textTransform='none'
												whiteSpace='nowrap'
											>
												Contact Us
											</Typography>
										</Stack>
									</HashLink>
									{!isLoggedIn && (
										<Button onClick={signIn}>
											<Stack direction='row' flexWrap='nowrap' alignItems='center' spacing={0.5}>
												<img src={SigninIco} width={14} />
												<Typography
													color='rgba(0, 0, 0, .7)'
													fontFamily='GilroySemiBold'
													textTransform='none'
													whiteSpace='nowrap'
												>
													Sign In
												</Typography>
											</Stack>
										</Button>
									)}
									<Box bgcolor='rgba(37, 204, 172, 1)'
										 borderRadius='6px'
										 padding='12px 20px'
										 sx={{ cursor: 'pointer' }}
									>
										<HashLink to='/#pricing'>
											<Typography color='#fff'>Get started</Typography>
										</HashLink>
									</Box>
								</Stack>
							)}

							{xlDisplay && hideNavigationMenu && isLoggedIn && (
								<Stack direction='row' fontFamily='GilroyRegular' spacing={4}>
									<Stack spacing={1} direction='row' alignItems='center'>
										<StyledAvatar>
											<Typography color='primary'>{user.name && user.name[0].toUpperCase()}</Typography>
										</StyledAvatar>
										<Typography
											color='rgba(0, 0, 0, .7)'
											fontFamily='GilroySemiBold'
											textOverflow='ellipsis'
											maxWidth={114}
											overflow='hidden'
										>
											{user.name}
										</Typography>
									</Stack>

									<Button variant='text' onClick={handleLogout}>
										<Stack direction='row' alignItems='center' spacing={1}>
											<img src={LogoutIco} width={14.5} />
											<Typography color='rgba(0, 0, 0, .7)' fontFamily='GilroySemiBold' textTransform='none'>
												Log out
											</Typography>
										</Stack>
									</Button>
								</Stack>
							)}
						</Stack>
					</Toolbar>
				</Container>
			</StyledBar>
			<BurgerMenu hideNavigationMenu={hideNavigationMenu} />
		</StyledBox>
	)
}
