import { Box, styled, Tab, Typography, useTheme } from '@mui/material'
import { useData } from './hooks'

type Props = {
	subtitle: string
	title: string
	titleColor?: string
	isTrainingsReportPage?: boolean
	onClick: () => void
}

export const StyledTrainingTab = styled(Tab, { name: 'StyledTrainingTab' })({
	'&.MuiTab-root': {
		opacity: 0.5,
		padding: '0 60px 20px 0',
	},
	'&.Mui-selected': {
		opacity: 1,
	},
})

export function ReportingFilter(props: Props) {
	const { subtitle, title, titleColor, isTrainingsReportPage, onClick, ...other } = props
	const theme = useTheme()
	const { statusColor } = useData({ theme, title })

	return (
		<StyledTrainingTab
			icon={
				<Box>
					<Typography
						fontFamily='GilroySemiBold'
						textAlign={isTrainingsReportPage ? 'center' : 'left'}
						textTransform='uppercase'
						fontSize={14}
						color={isTrainingsReportPage ? theme.palette.info.main : titleColor}
					>
						{subtitle}
					</Typography>
					<Typography fontFamily='GilroyBold' fontSize={20} color={statusColor}>
						{title}
					</Typography>
				</Box>
			}
			iconPosition='start'
			onClick={onClick}
			{...other}
		/>
	)
}
