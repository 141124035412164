import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfigKeys } from 'config';

export function useMsalInstance() {
    const msalConfig = {
        auth: msalConfigKeys,
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: false,
        },
    };
    return new PublicClientApplication(msalConfig);
}
