import { useSubscriptionsContext } from '../../../../context'
import { useMemo } from 'react'

export function useInitialValues() {
	const [{ pdfInfo }] = useSubscriptionsContext()

	return useMemo(
		() => ({
			companyName: pdfInfo.companyName || '',
			address: pdfInfo.address || '',
			city: pdfInfo.city || '',
			zipcode: pdfInfo.zipcode || '',
			phoneNumber: pdfInfo.phoneNumber || '',
			email: pdfInfo.email || '',
		}),
		[pdfInfo],
	)
}
