import { SharedFiltersArrows } from "@Components"
import { 
    Stack, 
    TableHead, 
    TableRow, 
    Typography, 
    useTheme 
} from "@mui/material"
import { StyledTableCell } from "../StyledTable"

type Props = {
    tableHeadingTitles:
        {
            title: string
            hasFilters?: boolean
            color?: string
        }[]
}

export function ReportingCategoriesRow(props: Props) {
    const { tableHeadingTitles } = props
    const theme = useTheme()
    
    
    return (
        <TableHead>
            <TableRow>
                {tableHeadingTitles.map((headingTitle) => (
                    <StyledTableCell align='left'>
                        <Stack direction='row' columnGap={1} alignItems='center' >
                            <Stack color={theme.palette.text.disabled}>
                                <Typography py={1.5} fontFamily='GilroySemiBold' textTransform='uppercase' fontSize={14} color={headingTitle.color}>
                                    {headingTitle.title}
                                </Typography>
                            </Stack>
                            {headingTitle.hasFilters && (<SharedFiltersArrows up={() => { }} down={() => { }} />)}
                        </Stack>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}