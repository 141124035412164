import { Box, styled } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'

export const SuccessStyledBox = styled(Box, { name: 'SuccessStyledBox' })({
	backgroundImage: `linear-gradient(143deg, #00FF95, #CB6CF4, #A013F0)`,
    width: '554px',
    height: '330px',
    borderRadius: '16px',
    boxShadow: '0px 21px 99px #0000004F',
    [`@media (max-width: ${DisplayEnum.mobile}px)`]: {
        width: '100%',
        height: '100%',
    }
})
