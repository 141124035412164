import { Box, Button, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Form, useFormikContext } from 'formik'
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled'
import { DisplayEnum } from 'shared/enums/display.enum'
import { SignUp } from '../components'
import { AlertMsg, CommonErrorMessage, CustomTextField } from '@Components'
import { TFormRegistration } from './typings'
import { useMemo } from 'react'

type Props = {
	showError: any
	initialValues: any
}

export function RegisterForm(props: Props) {
	const { showError, initialValues } = props
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const errorExist = showError && !!Object.entries(showError).length

	const errorText = useMemo(() => Object.entries(showError)[0] && Object.entries(showError)[0][1], [showError])

	return (
		<Form>
			<Box px={mobile ? 3 : 5}>
				{errorExist && <AlertMsg icon={<PersonAddDisabledIcon />} text={errorText} />}
			</Box>

			<Box mt={3} px={mobile ? 3 : 5}>
				<CustomTextField<TFormRegistration>
					id='email'
					name='email'
					label='EMAIL'
					type='email'
					placeholder='Please enter your e-mail'
					disabled={!!initialValues.email}
					defaultValue={initialValues.email}
				/>
			</Box>

			<Box mt={mobile? 3.5 : 3} px={mobile ? 3 : 5}>
				<CustomTextField<TFormRegistration>
					id='name'
					name='name'
					label='NAME'
					placeholder='Please enter your name'
					withTopPadding={true}
				/>
			</Box>

			<Stack px={mobile ? 3 : 5}>
				<Typography color={theme.palette.text.secondary} variant='caption' fontFamily='GilroyMedium' mt={0.5} letterSpacing={mobile? -0.2 : ''} lineHeight={mobile? 1 : ''}>
					Ideally - your full name, but you can introduce yourself as you like.
				</Typography>
			</Stack>
			<Box mt={mobile? 3.5 : 3} px={mobile ? 3 : 5}>
				<CustomTextField<TFormRegistration>
					id='company'
					name='company'
					label='COMPANY'
					placeholder='Please enter your company'
					disabled={!!initialValues.company}
					defaultValue={initialValues.company}
				/>
			</Box>
			<Box mt={mobile? 3.5 : 3} px={mobile ? 3 : 5}>
				<CustomTextField<TFormRegistration>
					id='password'
					name='password'
					label='PASSWORD'
					type='password'
					placeholder='Please enter your password'
				/>
			</Box>

			<Box my={mobile? 4.2 : 3} px={mobile ? 3 : 5}>
				<Button variant='rounded_contained__auth_success' type='submit' fullWidth>
					REGISTER
				</Button>
			</Box>

			<Stack direction='row' alignItems='center' mt={3.5} justifyContent='center' px={mobile ? 3 : 5}>
				<Typography fontSize={mobile ? 10 : 16} color={theme.palette.text.primary} fontFamily='GilroySemiBold' variant='body1'>
					Already have an account?
				</Typography>
				<SignUp
					label={
						<Link href='/login' underline='none'>
							<Typography color={theme.palette.text.primary} fontFamily='GilroySemiBold' variant='body1'>
								Sign In
							</Typography>
						</Link>
					}
				/>
			</Stack>
		</Form>
	)
}
