import { IconImage } from "@Components";
import { useGoToPreviousPage } from "@Hooks";
import { Button } from "@mui/material";

import ArrowLeft from '@Assets/arrow_back_black.svg'

type Props = {
    buttonVariant: 'courses_back_small' | 'courses_back'
}

export function ArrowBackButton(props: Props) {
    const { buttonVariant } = props
    const goToPrevious = useGoToPreviousPage()

    return (
        <Button variant={buttonVariant} onClick={goToPrevious}>
            <IconImage src={ArrowLeft} alt='arrow' height='25px' width='25px' />
        </Button>
    )
}