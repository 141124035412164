import { ReportingState } from '../../typings'
import { ParticipantTableRow } from '../../../ParticipantsReports/typings'

export const fetchParticipantsProducer = (
	state: ReportingState,
	payload: { total: number; items: ParticipantTableRow[] },
): ReportingState => ({
	...state,
	participants: payload,
})
