import { useCallback } from 'react'
import { useSubscriptionsContext } from '../../../context'
import {
	confirmationDeleteUserActionCreator,
	setDeleteUserCandidateActionCreator,
	toggleAddUserActionCreator,
} from '../../../state'
import { deleteSubscriptionUserEffect } from '../../../effects'
import { useNavigate, useParams } from 'react-router-dom'
import type { SubscriptionUser } from '../../../typings'
import { updateTokenEffect } from '../../../../../shared/effects'
import { useSharedContext } from '@Context'
import { useToken } from '@Hooks'

export function useHandlers() {
	const [, dispatch] = useSubscriptionsContext()
	const navigate = useNavigate()
	const { id } = useParams<{ id: string }>()
	const [, sharedDispatch] = useSharedContext()
	const { email, subscriptionPlan } = useToken()

	const handleAddUser = useCallback(() => {
		dispatch(toggleAddUserActionCreator(true))
	}, [dispatch])

	const handleOpenConfirmationDeleteUser = useCallback(
		(user: SubscriptionUser | null) => {
			dispatch(setDeleteUserCandidateActionCreator(user))
			dispatch(confirmationDeleteUserActionCreator(true))
		},
		[dispatch],
	)

	const handleCloseConfirmationDeleteUser = useCallback(() => {
		dispatch(setDeleteUserCandidateActionCreator(null))
		dispatch(confirmationDeleteUserActionCreator(false))
	}, [dispatch])

	const handleDeleteUser = useCallback(
		async (user: SubscriptionUser | null) => {
			let mounted = true

			if (!user) {
				return;
			}

			try {
				const response = await deleteSubscriptionUserEffect(user)

				if (!mounted) return

				if (response.status === 'success') {
					dispatch(response.action)

					if (user.withOculus && user.email === email) {
						const { action, status } = await updateTokenEffect()

						if (status === 'success') {
							sharedDispatch(action)
						}
					}

					handleCloseConfirmationDeleteUser()
				}
			} catch (err) {}

			return () => {
				mounted = false
			}
		},
		[dispatch],
	)

	const handleUnsubscribeClick = useCallback(() => {
		navigate(`/subscriptions/unsubscribe/${id}`)
	}, [navigate, id])

	const handleUpgradeClick = useCallback(() => {
		switch (subscriptionPlan) {
			case 1:
				navigate('/subscriptions/all')
				return
			case 2:
			case 3:
				navigate('/contact-us')
				return
		}
	}, [navigate, subscriptionPlan])

	return {
		handleAddUser,
		handleDeleteUser,
		handleUnsubscribeClick,
		handleUpgradeClick,
		handleOpenConfirmationDeleteUser,
		handleCloseConfirmationDeleteUser,
	}
}
