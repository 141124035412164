import { styled, AppBar } from '@mui/material'

export const HeaderStyledBar = styled(AppBar, { name: 'HeaderStyledBar' })({
	position: 'absolute',
	top: 0,
	left: 0,
	zIndex: 2,
	width: '100%',
	height: '100%',
	boxShadow: 'none',
	backgroundColor: '#F4F4F3',
	opacity: 0.6,
	
})
