import { useReportsContext } from '../../context'
import { Dispatch, useEffect } from 'react'
import { Actions } from '../../state'
import { fetchParticipants, fetchParticipantTypes } from '../../effects'
import { ParticipantsFilters } from '../typings'

async function execute(participantsFilters: ParticipantsFilters, mounted: boolean, dispatch: Dispatch<Actions>) {
	const participantsResult = await fetchParticipants(participantsFilters)
	const participantTypesResult = await fetchParticipantTypes(participantsFilters)

	if (mounted && participantsResult.status === 'success') {
		dispatch(participantsResult.action)
	}

	if (mounted && participantTypesResult.status === 'success') {
		dispatch(participantTypesResult.action)
	}
}

export function useFetchParticipants() {
	const [{ participantsFilters }, dispatch] = useReportsContext()
	let mounted = true

	useEffect(() => {
		execute(participantsFilters, mounted, dispatch)

		return () => {
			mounted = false
		}
	}, [participantsFilters])
}
