import { RequestService } from '@Services'
import type { ResetEmailData } from '../typings'
import type { EffectResponse } from '@Types'
import { URLS } from '@Utils'

export async function sendEmail(body: ResetEmailData): Promise<EffectResponse> {
	try {
		const result = await RequestService.post<ResetEmailData, null>(URLS.resetPassword, body)

		if (result.status === 200) {
			return { status: 'success' }
		}

		return { status: 'error', error: result.data || '' }
	} catch (err: any) {
		return { status: 'error', error: err }
	}
}
