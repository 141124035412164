import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

export function useGoToContactUs() {
	const navigate = useNavigate()

	return useCallback(() => {
		navigate('/contact-us')
	}, [navigate])
}
