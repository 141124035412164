import useScrollTrigger from '@mui/material/useScrollTrigger'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import ToTop from '@Assets/toTop.svg'
import { IconImage } from '@Components'

export function ScrollToTop() {
	const trigger = useScrollTrigger({
		target: window,
		disableHysteresis: true,
		threshold: window.innerHeight * 2,
	})

	const handleClick = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	}

	return (
		<Fade in={trigger}>
			<Box
				onClick={handleClick}
				role='presentation'
				sx={{ position: 'fixed', bottom: 45, right: 45, cursor: 'pointer' }}
				bgcolor='#fff'
				border='4px solid #7A6CF438'
				borderRadius='50%'
				boxShadow='0px 9px 16px #0000001A'
				boxSizing='border-box'
				width={50}
				height={50}
				zIndex={99}
				display='flex'
				alignItems='center'
				justifyContent='center'
			>
				<IconImage src={ToTop} alt='to top' width='18px' />
			</Box>
		</Fade>
	)
}
