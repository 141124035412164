import { Stack, styled } from '@mui/material'
import Background from '@Assets/CoursesBG.svg'
import { DisplayEnum } from 'shared/enums/display.enum'

export const AboutStack = styled(Stack, { name: 'AboutStack' })({
	backgroundImage: `url('${Background}')`,
	backgroundSize: '100% 140%',
	backgroundPositionY: '0%',
	[`@media (max-width: ${DisplayEnum.laptop}px)`]: {
		backgroundPositionY: '0'
	},
	[`@media (max-width: ${DisplayEnum.tablet}px)`]: {
		backgroundPositionY: '-990%',
		backgroundSize: '100% 250%',
	},
	[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
		backgroundPositionY: '-150%',
		backgroundSize: '100% 250%',
	}
})
