import { Typography, useTheme } from '@mui/material'

export function InterpretationAndDefinitions() {
	const theme = useTheme()

	return (
		<>
			<Typography fontFamily='GilroyBold' variant='h4' color={theme.palette.text.primary}>
				<u>Interpretation and Definitions</u>
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Interpretation
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				The words of which the initial letter is capitalized have meanings defined under the following conditions. The
				following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Definitions
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				For the purposes of these Terms and Conditions:
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Affiliate</strong>
				</Typography>{' '}
				means an entity that controls, is controlled by or is under common control with a party, where "control" means
				ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of
				directors or other managing authority.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Country</strong>
				</Typography>{' '}
				refers to: United Kingdom
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Company</strong>
				</Typography>{' '}
				(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Bridge Learning Tech,
				Cambridge House, Henry Street Bath BA1 1BT, United Kingdom.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Device</strong>
				</Typography>{' '}
				means any device that can access the Service such as a computer, a cellphone or a digital tablet.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Service</strong>
				</Typography>{' '}
				refers to the Website.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Terms and Conditions</strong>
				</Typography>{' '}
				(also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the
				Company regarding the use of the Service.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Third-party Social Media Service</strong>
				</Typography>{' '}
				means any services or content (including data, information, products or services) provided by a third-party that
				may be displayed, included or made available by the Service.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Website</strong>
				</Typography>{' '}
				refers to JustCleVR, accessible from https://justclevr.com/
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>You</strong>
				</Typography>{' '}
				means the individual accessing or using the Service, or the company, or other legal entity on behalf of which
				such individual is accessing or using the Service, as applicable.
			</Typography>
		</>
	)
}
