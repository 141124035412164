import { Route, Routes } from 'react-router-dom'

import { LoginPage } from './LoginPage'
import { RegisterPage } from './RegisterPage'
import { ResetPage } from './ResetPage'
import { CodeVerificationPage } from './CodeVerificationPage'
import { CodeSentPage } from './CodeSentPage'
import { CreatePasswordPage } from './CreatePasswordPage'
import { PasswordCreatedPage } from './PasswordCreatedPage'

export function AuthRoutes() {
	return (
		<Routes>
			<Route path='/login' element={<LoginPage />} />
			<Route path='/register' element={<RegisterPage />} />
			<Route path='/register/:email' element={<RegisterPage />} />
			<Route path='/reset' element={<ResetPage />} />
			<Route path='/verification' element={<CodeVerificationPage />} />
			<Route path='/codesent' element={<CodeSentPage />} />
			<Route path='/createpass/:code' element={<CreatePasswordPage />} />
			<Route path='/passcreated' element={<PasswordCreatedPage />} />
		</Routes>
	)
}
