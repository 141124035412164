import { RequestService } from '@Services'
import { URLS } from '@Utils'

export async function checkUserEffect(email: string): Promise<{ status: 'success' | 'error' }> {
	const response = await RequestService.get<{ email: string }, { existed: boolean }>(URLS.checkUser, { email })

	if (response.status === 200) {
		return { status: response.data.existed ? 'success' : 'error' }
	}

	return { status: 'error' }
}
