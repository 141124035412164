import { Box, Button, Container, Link, Stack, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import clevrLogo from '@Assets/clevrLogo.png'
import LogoMob from '@Assets/clvr_logo1.png'
import BookImg from '@Assets/book.svg'
import HelpСircle from '@Assets/helpСircle.svg'
import Percent from '@Assets/percent.svg'
import Mail from '@Assets/mail.svg'
import LogIn from '@Assets/logIn.svg'
import LogoutIco from '@Assets/clvr_menu_signin.svg'
import BurgerMenuImg from '@Assets/burgerMenu.svg'
import { HashLink, IconImage, StyledAvatar } from '@Components'
import { useGoToLoginPage, useGoToMain, useIsLoggedIn, useLogout, useOpenBurgerMenu, useToken } from '@Hooks'
import { RolesEnum } from '@Enums'
import { DisplayEnum } from 'shared/enums/display.enum'
import {useCallback, useState} from 'react'
import { RolesService } from 'shared/services/roles.service'
import { HeaderStyledBar } from './HeaderStyledBar'
import { HeaderHashLink } from './HeaderHashLink'
import { BurgerMenu } from 'shared/components/BurgerMenu'
import {useNavigate} from "react-router-dom";

type Props = {
	hideNavigationMenu?: boolean
	showReportingMenu?: boolean
}

export function HeaderHomePage(props: Props) {
	const isLoggedIn = useIsLoggedIn()
	const user = useToken()
	const { hideNavigationMenu, showReportingMenu } = props
	const [showThankYou, setShowThankYou] = useState(false)
	const goToMain = useGoToMain(setShowThankYou)
	const handleLogout = useLogout()
	const handleOpenBurgerMenu = useOpenBurgerMenu()
	const signIn = useGoToLoginPage()
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	const showSubscriptions = RolesService.availableForRole([RolesEnum.SuperAdmin, RolesEnum.Admin])
	const showTrainings = RolesService.availableForRole([
		RolesEnum.Admin,
		RolesEnum.SuperUser,
		RolesEnum.User,
		RolesEnum.SuperAdmin,
		RolesEnum.Guest,
	])
	const showReports = false //RolesService.availableForRole([
	// 	RolesEnum.SuperAdmin,
	// 	RolesEnum.SuperUser,
	// 	RolesEnum.Admin,
	// 	RolesEnum.Guest,
	// ])

	return (
		<Box width='100%' boxSizing='border-box' position='relative'>
			<Stack position='relative' zIndex={5}>
				<Container maxWidth='xl' disableGutters={!xlDisplay}>
					<Toolbar>
						<Stack
							alignItems='center'
							justifyContent='space-between'
							flexDirection={mobile || !xlDisplay ? 'row-reverse' : 'row'}
							width='1180px'
							height={106.5}
							mx={xlDisplay ? 'auto' : 0}
							mt={xlDisplay ? '30px' : 0}
							overflow='hidden'
						>
							{!xlDisplay && (
								<Box mr={1} onClick={handleOpenBurgerMenu}>
									<IconImage src={BurgerMenuImg} alt='menu' width='24px' />
								</Box>
							)}
							{xlDisplay && (
								<Stack direction='row' fontFamily='GilroySemiBold' flex='1' justifyContent='flex-start' spacing={2.4}>
									<HeaderHashLink to='/#courses'>
										<Box display='flex' alignItems='center'>
											<Box mr={0.8} lineHeight='1'>
												<IconImage src={BookImg} alt='courses' width='14px' />
											</Box>
											Courses
										</Box>
									</HeaderHashLink>
									<HeaderHashLink to='#how-it-works'>
										<Box display='flex' alignItems='center'>
											<Box mr={0.8} lineHeight='1'>
												<IconImage src={HelpСircle} alt='how it works' width='14px' />
											</Box>
											How it works
										</Box>
									</HeaderHashLink>
									<HeaderHashLink to='#pricing'>
										<Box display='flex' alignItems='center'>
											<Box mr={0.8} lineHeight='1'>
												<IconImage src={Percent} alt='pricing' width='14px' />
											</Box>
											Pricing
										</Box>
									</HeaderHashLink>
								</Stack>
							)}
							<Stack flex={xlDisplay ? 1 : 0}>
								<Box textAlign='center' onClick={goToMain}>
									<IconImage src={xlDisplay ? clevrLogo : LogoMob} alt='logo' width={xlDisplay ? '183px' : '60.5px'} />
								</Box>
							</Stack>
							{xlDisplay && (
								<Stack
									direction='row'
									alignItems='center'
									flex='1'
									justifyContent='flex-end'
									fontFamily='GilroySemiBold'
									spacing={2.4}
								>
									<HeaderHashLink to='#contacts'>
										<Box display='flex' alignItems='center'>
											<Box mr={0.8} lineHeight='1'>
												<IconImage src={Mail} alt='courses' width='14px' />
											</Box>
											Contact Us
										</Box>
									</HeaderHashLink>
									{!isLoggedIn && (
										<>
											<HeaderHashLink to='/login'>
												<Box display='flex' alignItems='center'>
													<Box mr={0.8} lineHeight='1'>
														<IconImage src={LogIn} alt='how it works' width='14px' />
													</Box>
													Sign In
												</Box>
											</HeaderHashLink>
											<Box bgcolor='rgba(37, 204, 172, 1)'
												 borderRadius='6px'
												 padding='12px 20px'
												 sx={{ cursor: 'pointer' }}
											>
												<HashLink to='/#pricing'>
													<Typography color='#fff'>Get started</Typography>
												</HashLink>
											</Box>
										</>
									)}
									{isLoggedIn && (
										<>
											<Stack direction='row' alignItems='center'>
												<StyledAvatar>
													<Typography color='primary'>{user.name && user.name[0].toUpperCase()}</Typography>
												</StyledAvatar>
												<Typography
													color='rgba(0, 0, 0, .7)'
													fontFamily='GilroySemiBold'
													textOverflow='ellipsis'
													maxWidth={114}
													overflow='hidden'
													ml={0.8}
												>
													{user.name}
												</Typography>
											</Stack>

											<HeaderHashLink to='' onClick={handleLogout}>
												<Box display='flex' alignItems='center'>
													<Box mr={0.8} lineHeight='1'>
														<IconImage src={LogoutIco} alt='LogoutIco' width='14px' />
													</Box>
													Log out
												</Box>
											</HeaderHashLink>
										</>
									)}
								</Stack>
							)}
						</Stack>
					</Toolbar>
				</Container>
			</Stack>
			<HeaderStyledBar></HeaderStyledBar>
			<BurgerMenu hideNavigationMenu={hideNavigationMenu} />
		</Box>
	)
}
