import {Box, styled} from "@mui/material";

export const ImgBanner = styled(Box, { name: 'ImgBanner' })<{ img: any, height?: string, isMobile?: boolean }>(( { img, height, isMobile }) => ({
    background: `url('${img}')`,
    backgroundPosition: 'center',
    backgroundSize: '100vw',
    backgroundRepeate: 'no-repeat',
    borderRadius: isMobile ? '0 0 10px 10px' : '0 0 30px 30px',
    height: height || '255px',
    overflow: 'hidden',
    width: '100%'
}))
