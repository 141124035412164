import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

export function useHandlers() {
	const navigate = useNavigate()

	const handleMainPageClick = useCallback(() => {
		navigate('/login')
	}, [navigate])

	return {
		handleMainPageClick,
	}
}
