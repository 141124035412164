import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

export function useGoToLoginPage() {
	const navigate = useNavigate()

	return useCallback(() => {
		navigate('/login')
	}, [navigate])
}
