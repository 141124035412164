import { Box, Button, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import { TableFiltersForm } from './TableFiltersForm'
import { useHandlers } from './hooks'
import { DisplayEnum } from 'shared/enums/display.enum'
import { DividerGray } from '@Components'
import { StyledStack } from 'features/subscriptions/components'
import type { SubscriptionUsersFilters } from '../../typings'

type Props = {
	filters: SubscriptionUsersFilters
}

export function TableFilters(props: Props) {
	const { handleAddUser } = useHandlers()
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	return (
		<StyledStack direction={xlDisplay ? 'row' : 'column'} width='100%' alignItems='center'>
			<Stack direction={mobile ? 'column' : 'row'} alignItems={xlDisplay ? 'center' : 'start'} p={2} flex={1} spacing={2}>
				<Box flex={1}>
					<Typography variant='h6' fontFamily='GilroySemiBold' color={theme.palette.text.primary}>
						USERS
					</Typography>
				</Box>
				<TableFiltersForm {...props} />
			</Stack>
			{xlDisplay && <DividerGray orientation='vertical' />}
			<Box m={2} width={mobile ? 300 : 160}>
				<Button variant='rounded_contained__success' fullWidth onClick={handleAddUser}>
					<Stack direction='row' alignItems='center' spacing={1}>
						<GroupAddIcon color='success' /> <Typography color={theme.palette.success.main}>ADD USER</Typography>
					</Stack>
				</Button>
			</Box>
		</StyledStack>
	)
}
