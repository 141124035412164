import { Route, Routes } from 'react-router-dom'
import { CourseContentPage } from './ContentPage/CourseContentPage'
import { CourseListPage } from './CoursesListPage/CourseListPage'
import { TrainingsContextProvider } from './context'

export function CoursesRoutes() {
	return (
		<TrainingsContextProvider>
			<Routes>
				<Route path='/trainings' element={<CourseListPage />} />
				<Route path='/trainings/:id' element={<CourseContentPage />} />
			</Routes>
		</TrainingsContextProvider>
	)
}
