import { Footer, Header } from '@Components'
import { Box, Stack, Typography, useTheme } from '@mui/material'

export function CookiePage() {
	const theme = useTheme()

	return (
		<Box bgcolor={theme.palette.background.default}>
			<Header hideNavigationMenu={false} />
			<Box maxWidth={1020} marginX='auto'>
				<Stack padding={4.2} spacing={2} justifyContent='center'>
					<Typography fontFamily='GilroyBold' variant='h3' color={theme.palette.text.primary}>
						Cookie Policy for JustCleVR
					</Typography>
					<Typography variant='body1' color={theme.palette.text.secondary}>
						This is the Cookie Policy for JustCleVR, accessible from https://justclevr.com/.
					</Typography>
					<Typography variant='h5' color={theme.palette.text.primary}>
						What Are Cookies
					</Typography>
					<Typography variant='body1' color={theme.palette.text.secondary}>
						As is common practice with almost all professional websites, this site uses cookies, which are tiny files
						that are downloaded to your computer, to improve your experience. This page describes what information they
						gather, how we use it and why we sometimes need to store these cookies. We will also share how you can
						prevent these cookies from being stored, however this may downgrade or 'break' certain elements of the sites
						functionality.
					</Typography>
					<Typography variant='h5' color={theme.palette.text.primary}>
						How We Use Cookies
					</Typography>
					<Typography variant='body1' color={theme.palette.text.secondary}>
						We use cookies for a variety of reasons detailed below. Unfortunately, in most cases there are no industry
						standard options for disabling cookies without completely disabling the functionality and features they add
						to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or
						not in case, they are used to provide a service that you use.
					</Typography>
					<Typography variant='h5' color={theme.palette.text.primary}>
						Disabling Cookies
					</Typography>
					<Typography variant='body1' color={theme.palette.text.secondary}>
						You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for
						how to do this). Be aware that disabling cookies will affect the functionality of this and many other
						websites that you visit. Disabling cookies will usually result in also disabling certain functionality and
						features of this site. Therefore it is recommended that you do not disable cookies.
					</Typography>
					<Typography variant='h5' color={theme.palette.text.primary}>
						The Cookies We Set
					</Typography>
					<Typography component='li' color={theme.palette.text.primary}>
						Account related cookies
						<Typography variant='body1' color={theme.palette.text.secondary} paddingLeft='22px'>
							If you create an account with us, then we will use cookies for the management of the signup process and
							general administration. These cookies will usually be deleted when you log out, however in some cases they
							may remain afterwards to remember your site preferences when logged out.
						</Typography>
					</Typography>
					<Typography component='li' color={theme.palette.text.primary}>
						Login related cookies
						<Typography variant='body1' color={theme.palette.text.secondary} paddingLeft='22px'>
							We use cookies when you are logged in so that we can remember this fact. This prevents you from having to
							log in every single time you visit a new page. These cookies are typically removed or cleared when you log
							out to ensure that you can only access restricted features and areas when logged in.
						</Typography>
					</Typography>
					<Typography component='li' color={theme.palette.text.primary}>
						Forms related cookies.
						<Typography variant='body1' color={theme.palette.text.secondary} paddingLeft='22px'>
							When you submit data through a form such as those found on contact pages or comment forms cookies may be
							set to remember your user details for future correspondence.
						</Typography>
					</Typography>
					<Typography variant='h5' color={theme.palette.text.primary}>
						Third Party Cookies
					</Typography>
					<Typography variant='body1' color={theme.palette.text.secondary}>
						In some special cases we also use cookies provided by trusted third parties. The following section details
						which third party cookies you might encounter through this site.
					</Typography>
					<Typography component='li' color={theme.palette.text.primary}>
						This site uses Google Analytics which is one of the most widespread and trusted analytics solutions on the
						web for helping us to understand how you use the site and ways that we can improve your experience. These
						cookies may track things such as how long you spend on the site and the pages that you visit so we can
						continue to produce engaging content.
						<br />
						<br />
						For more information on Google Analytics cookies, see the official Google Analytics page.
					</Typography>
					<Typography variant='h5' color={theme.palette.text.primary}>
						More Information
					</Typography>
					<Typography variant='body1' color={theme.palette.text.secondary}>
						However, if you are still looking for more information than you can contact us through one of our preferred
						contact methods:
					</Typography>
					<Typography component='li' color={theme.palette.text.primary}>
						Email: info@bridgeLT.com
					</Typography>
					<Typography component='li' color={theme.palette.text.primary}>
						Phone: +44 (0) 3330 119 683
					</Typography>
				</Stack>
			</Box>
			<Footer onMainPage={false} />
		</Box>
	)
}
