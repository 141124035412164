import { Link, Stack, Typography, useTheme } from '@mui/material'
import { StyledTableCell, StyledTableRow } from '../../components/StyledTable'
import type { ParticipantTableRow } from '../../ParticipantsReports/typings'
import { TrainingStatusesEnum } from '../../TrainingsReports/enums'

type Props = {
	tableRow: ParticipantTableRow
}

export function ParticipantsTableRow(props: Props) {
	const { tableRow } = props
	const theme = useTheme()

	return (
		<Link href={''} underline='none' display='contents'>
			<StyledTableRow key={tableRow.name}>
				<StyledTableCell scope='row'>
					<Stack direction='row' alignItems='left'>
						<Stack>
							<Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.text.secondary}>
								{tableRow.name}
							</Typography>
							{tableRow.email && (
								<Typography fontFamily='GilroyMedium' fontSize={14} color={theme.palette.text.secondary}>
									{tableRow.email}
								</Typography>
							)}
						</Stack>
					</Stack>
				</StyledTableCell>
				<StyledTableCell align='left'>
					<Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.text.secondary}>
						{tableRow[TrainingStatusesEnum.NotStarted]}
					</Typography>
				</StyledTableCell>
				<StyledTableCell align='left'>
					<Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.text.secondary}>
						{tableRow[TrainingStatusesEnum.InProgress]}
					</Typography>
				</StyledTableCell>
				<StyledTableCell align='left'>
					<Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.text.secondary}>
						{tableRow[TrainingStatusesEnum.Completed]}
					</Typography>
				</StyledTableCell>
				<StyledTableCell align='left'>
					<Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.text.secondary}>
						{tableRow.averageAttempts}
					</Typography>
				</StyledTableCell>
				<StyledTableCell align='left'>
					<Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.text.secondary}>
						{tableRow.averageAttemptsTime}
					</Typography>
				</StyledTableCell>
			</StyledTableRow>
		</Link>
	)
}
