import { SubscriptionUser } from 'features/subscriptions/typings'
import type { State } from '../../typings'

export const confirmationDeleteUserProducer = (state: State, payload: boolean): State => ({
	...state,
	deleteUserIsOpen: payload,
})

export const setDeleteUserCandidateProducer = (state: State, payload: SubscriptionUser | null): State => ({
	...state,
	deleteUserCandidate: payload,
})