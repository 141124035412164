import { Stack, styled } from '@mui/material'
import Background from '@Assets/bridge_bg.png'
import { DisplayEnum } from '@Enums'

export const BridgeStack = styled(Stack, { name: 'BridgeStack' })({
	backgroundImage: `url('${Background}')`,
	backgroundSize: '100% 140%',
	backgroundPositionY: '0%',
	borderRadius: '30px',
  boxSizing: 'border-box',
  padding: '127px 60px 45px',
  width: '862px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '-140px auto 0',
  zIndex: 3,
  [`@media (max-width: ${DisplayEnum.tablet}px)`]: {
		width: '100%',
    padding: '127px 30px 45px',
	},
  [`@media (max-width: ${DisplayEnum.mobile}px)`]: {
    padding: '150px 20px 45px',
    flexDirection: 'column',
	}
})
