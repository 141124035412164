import { Box, Stack, Typography, useTheme } from '@mui/material'

import { RightPartModalStack } from './RightPartModalStack'
import { StyledRoundIcon } from './StyledRoundIcon'
import { RolesEnum } from '@Enums'
import { useToken } from '@Hooks'

export function UserRoleDescription() {
	const theme = useTheme()
	const user = useToken()
	const isSuperAdministrator = user.role === RolesEnum.SuperAdmin

	return (
		<RightPartModalStack direction='column'>
			<Stack direction='row' mt={1.7} mb={3} alignItems='center'>
				<Typography variant='h5' color={theme.palette.text.primary} fontFamily='GilroyBold' flex={1}>
					There are {isSuperAdministrator ? 5 : 4} possible roles:
				</Typography>
			</Stack>

			{isSuperAdministrator && (
				<Box mb={1.2}>
					<Stack direction='row'>
						<Typography variant='body1' color={theme.palette.text.primary} lineHeight={1.2} fontFamily='GilroySemiBold'>
							Super Administrator
						</Typography>
						<Typography
							ml={1}
							mb={0.4}
							variant='body1'
							color={theme.palette.text.secondary}
							lineHeight={1.2}
							fontFamily='GilroyMedium'
						>
							is a power-user that can:
						</Typography>
					</Stack>
					<Stack direction='row'>
						<Stack color={theme.palette.success.contrastText} alignItems='center' justifyContent='center'>
							<StyledRoundIcon color='secondary'/>
						</Stack>
						<Typography
							ml={1.4}
							variant='body1'
							color={theme.palette.text.secondary}
							lineHeight={1.2}
							fontFamily='GilroyMedium'
						>
							Manage the Subscription View extended Reports at bridgevr.io
						</Typography>
					</Stack>
					<Stack direction='row'>
						<Stack color={theme.palette.success.contrastText} alignItems='center' justifyContent='center'>
							<StyledRoundIcon color='secondary'/>
						</Stack>
						<Typography
							ml={1.4}
							variant='body1'
							color={theme.palette.text.secondary}
							lineHeight={1.2}
							fontFamily='GilroyMedium'
						>
							Register or Delete new Super Admins, Admins, Super Users, Users, Guests
						</Typography>
					</Stack>
					<Stack direction='row'>
						<Stack color={theme.palette.success.contrastText} alignItems='center' justifyContent='center'>
							<StyledRoundIcon color='secondary'/>
						</Stack>
						<Typography
							ml={1.4}
							variant='body1'
							color={theme.palette.text.secondary}
							lineHeight={1.2}
							fontFamily='GilroyMedium'
						>
							Set up Super Admins or Admins to use the Oculus app
						</Typography>
					</Stack>
					<Stack direction='row'>
						<Stack color={theme.palette.success.contrastText} alignItems='center' justifyContent='center'>
							<StyledRoundIcon color='secondary'/>
						</Stack>
						<Typography
							ml={1.4}
							variant='body1'
							color={theme.palette.text.secondary}
							lineHeight={1.2}
							fontFamily='GilroyMedium'
						>
							View the list of Trainings at bridgevr.io
						</Typography>
					</Stack>
					<Stack direction='row'>
						<Stack color={theme.palette.success.contrastText} alignItems='center' justifyContent='center'>
							<StyledRoundIcon color='secondary'/>
						</Stack>
						<Typography
							ml={1.4}
							variant='body1'
							color={theme.palette.text.secondary}
							lineHeight={1.2}
							fontFamily='GilroyMedium'
						>
							View extended Reports at bridgevr.io
						</Typography>
					</Stack>
				</Box>
			)}

			<Box mb={1.2}>
				<Stack direction='row'>
					<Typography variant='body1' color={theme.palette.text.primary} lineHeight={1.2} fontFamily='GilroySemiBold'>
						Administrator
					</Typography>
					<Typography
						ml={1}
						mb={0.7}
						variant='body1'
						lineHeight={1.2}
						color={theme.palette.text.secondary}
						fontFamily='GilroyMedium'
					>
						is a user that can manage Users, view the list of Trainings and reports and can also use the Oculus app.
					</Typography>
				</Stack>
				<Stack direction='row'>
					<Stack color={theme.palette.success.contrastText} alignItems='center' justifyContent='center'>
						<StyledRoundIcon color='secondary'/>
					</Stack>
					<Typography
						ml={1.4}
						variant='body1'
						color={theme.palette.text.secondary}
						lineHeight={1.2}
						fontFamily='GilroyMedium'
					>
						Register or Delete new Admins, Super Users, Users, Guests
					</Typography>
				</Stack>
				<Stack direction='row'>
					<Stack color={theme.palette.success.contrastText} alignItems='center' justifyContent='center'>
						<StyledRoundIcon color='secondary'/>
					</Stack>
					<Typography
						ml={1.4}
						variant='body1'
						color={theme.palette.text.secondary}
						lineHeight={1.2}
						fontFamily='GilroyMedium'
					>
						Set up Admins to use the Oculus app
					</Typography>
				</Stack>
				<Stack direction='row'>
					<Stack color={theme.palette.success.contrastText} alignItems='center' justifyContent='center'>
						<StyledRoundIcon color='secondary'/>
					</Stack>
					<Typography
						ml={1.4}
						variant='body1'
						color={theme.palette.text.secondary}
						lineHeight={1.2}
						fontFamily='GilroyMedium'
					>
						View the list of Trainings at bridgevr.io
					</Typography>
				</Stack>
				<Stack direction='row'>
					<Stack color={theme.palette.success.contrastText} alignItems='center' justifyContent='center'>
						<StyledRoundIcon color='secondary'/>
					</Stack>
					<Typography
						ml={1.4}
						variant='body1'
						color={theme.palette.text.secondary}
						lineHeight={1.2}
						fontFamily='GilroyMedium'
					>
						View extended Reports at bridgevr.io
					</Typography>
				</Stack>
			</Box>

			<Box mb={1.2}>
				<Stack direction='row'>
					<Typography
						variant='body1'
						color={theme.palette.text.primary}
						lineHeight={1.2}
						fontFamily='GilroySemiBold'
						noWrap
						minWidth={85}
					>
						Super User
					</Typography>
					<Typography
						ml={1}
						mb={0.7}
						variant='body1'
						color={theme.palette.text.secondary}
						lineHeight={1.2}
						fontFamily='GilroyMedium'
					>
						is a user that can with Oculus App, pass VR trainings and will be able to view the extended
						Reports at bridgevr.io
					</Typography>
				</Stack>
				<Stack direction='row'>
					<Stack color={theme.palette.success.contrastText} alignItems='center' justifyContent='center'>
						<StyledRoundIcon color='secondary'/>
					</Stack>
					<Typography
						ml={1.4}
						variant='body1'
						color={theme.palette.text.secondary}
						lineHeight={1.2}
						fontFamily='GilroyMedium'
					>
						Use the Oculus app
					</Typography>
				</Stack>
				<Stack direction='row'>
					<Stack color={theme.palette.success.contrastText} alignItems='center' justifyContent='center'>
						<StyledRoundIcon color='secondary'/>
					</Stack>
					<Typography
						ml={1.4}
						variant='body1'
						color={theme.palette.text.secondary}
						lineHeight={1.2}
						fontFamily='GilroyMedium'
					>
						View the list of Trainings at bridgevr.io
					</Typography>
				</Stack>
				<Stack direction='row'>
					<Stack color={theme.palette.success.contrastText} alignItems='center' justifyContent='center'>
						<StyledRoundIcon color='secondary'/>
					</Stack>
					<Typography
						ml={1.4}
						variant='body1'
						color={theme.palette.text.secondary}
						lineHeight={1.2}
						fontFamily='GilroyMedium'
					>
						View extended Reports at bridgevr.io
					</Typography>
				</Stack>
			</Box>

			<Box mb={1.2}>
				<Stack direction='row'>
					<Typography variant='body1' color={theme.palette.text.primary} lineHeight={1.2} fontFamily='GilroySemiBold'>
						User
					</Typography>
					<Typography
						ml={1}
						mb={0.7}
						variant='body1'
						color={theme.palette.text.secondary}
						lineHeight={1.2}
						fontFamily='GilroyMedium'
					>
						can with Oculus App, pass VR trainings but will be not able to view the extended reports at bridgevr.io
					</Typography>
				</Stack>
				<Stack direction='row'>
					<Stack color={theme.palette.success.contrastText} alignItems='center' justifyContent='center'>
						<StyledRoundIcon color='secondary'/>
					</Stack>
					<Typography
						ml={1.4}
						variant='body1'
						color={theme.palette.text.secondary}
						lineHeight={1.2}
						fontFamily='GilroyMedium'
					>
						Use the Oculus app
					</Typography>
				</Stack>
				<Stack direction='row'>
					<Stack color={theme.palette.success.contrastText} alignItems='center' justifyContent='center'>
						<StyledRoundIcon color='secondary'/>
					</Stack>
					<Typography
						ml={1.4}
						variant='body1'
						color={theme.palette.text.secondary}
						lineHeight={1.2}
						fontFamily='GilroyMedium'
					>
						View the list of Trainings at bridgevr.io
					</Typography>
				</Stack>
			</Box>

			<Box mb={1.2}>
				<Stack direction='row'>
					<Typography variant='body1' color={theme.palette.text.primary} lineHeight={1.2} fontFamily='GilroySemiBold'>
						Guest
					</Typography>
					<Typography
						ml={1}
						mb={0.7}
						variant='body1'
						color={theme.palette.text.secondary}
						lineHeight={1.2}
						fontFamily='GilroyMedium'
					>
						will be able to work with VR Platform only.
					</Typography>
				</Stack>
				<Stack direction='row'>
					<Stack color={theme.palette.success.contrastText} alignItems='center' justifyContent='center'>
						<StyledRoundIcon color='secondary'/>
					</Stack>
					<Typography
						ml={1.4}
						variant='body1'
						color={theme.palette.text.secondary}
						lineHeight={1.2}
						fontFamily='GilroyMedium'
					>
						View the list of Trainings at bridgevr.io
					</Typography>
				</Stack>
				<Stack direction='row'>
					<Stack color={theme.palette.success.contrastText} alignItems='center' justifyContent='center'>
						<StyledRoundIcon color='secondary'/>
					</Stack>
					<Typography
						ml={1.4}
						variant='body1'
						lineHeight={1.2}
						color={theme.palette.text.secondary}
						fontFamily='GilroyMedium'
					>
						View extended Reports at bridgevr.io
					</Typography>
				</Stack>
			</Box>
		</RightPartModalStack>
	)
}
