import { matchPath, Route, Routes, useLocation } from 'react-router-dom'
import { ReactElement, useMemo } from 'react'
import { knownRoutes } from '../../constants/routes'
import { MissingPage } from '../../features/errors/missing/MissingPage'

type Props = {
	children: ReactElement
}

function Fragment() {
	return null
}

export function RouterErrorsHandler(props: Props) {
	const { children } = props
	let location = useLocation()
	const isUnknownRoutes = useMemo(() => !knownRoutes.find((route) => matchPath(route, location.pathname)), [location])

	return (
		<>
			{!isUnknownRoutes && children}
			{isUnknownRoutes && (
				<Routes>
					<Route path='*' element={<MissingPage />} />
				</Routes>
			)}
		</>
	)
}
