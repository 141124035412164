import { DisplayEnum } from "@Enums"
import { Stack, useMediaQuery, useTheme } from "@mui/material"
import { ParticipantsReportsContent, ParticipantsReportsHeader } from "."

export function ParticipantsReportsComponent() {
    const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	return (
		<Stack minWidth={mobile ? 'calc(100% - 60px)' : 1180} mx='auto' mb={9.7}>
			<ParticipantsReportsHeader />
            <ParticipantsReportsContent />
		</Stack>
	)
}
