import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { sendEmail } from '../../effects/sendEmail.effect'
import type { ResetEmailData } from '../../typings'

export function useHandlers(setServerError: (value: string) => void, setOpen: (value: boolean) => void) {
	const navigate = useNavigate()

	const handleCancelClick = useCallback(() => {
		navigate('/login')
	}, [navigate])

	const handleSubmit = useCallback(async (value: ResetEmailData) => {
		let mounted = true

		const result = await sendEmail(value)

		if (!mounted) return

		if (result.status === 'error') setServerError(result.error || '')
		else setOpen(true)

		// if (result.status === 'success') {
		// 	navigate('/verification')
		// }

		return () => {
			mounted = false
		}
	}, [])

	return {
		handleCancelClick,
		handleSubmit,
	}
}
