import type { Actions, State } from './typings'
import {
	createSubscriptionUserProducer,
	deleteSubscriptionUserProducer,
	fetchPaymentsProducer,
	fetchSubscriptionsProducer,
	fetchSubscriptionUsersProducer,
	toggleAddUserProducer,
	confirmationDeleteUserProducer,
	setDeleteUserCandidateProducer,
	fetchPdfInfoProducer,
} from './Actions'

export function subscriptionsReducer(state: State, action: Actions) {
	switch (action.type) {
		case 'subscriptions/fetchSubscriptions':
			return fetchSubscriptionsProducer(state, action.payload)
		case 'subscriptions/fetchPayments':
			return fetchPaymentsProducer(state, action.payload)
		case 'subscriptions/toggleAddUser':
			return toggleAddUserProducer(state, action.payload)
		case 'subscriptions/confirmationDeleteUser':
			return confirmationDeleteUserProducer(state, action.payload)
		case 'subscriptions/fetchSubscriptionUsers':
			return fetchSubscriptionUsersProducer(state, action.payload)
		case 'subscriptions/createSubscriptionUser':
			return createSubscriptionUserProducer(state, action.payload)
		case 'subscriptions/deleteSubscriptionUser':
			return deleteSubscriptionUserProducer(state, action.payload)
		case 'subscriptions/deleteUserCandidate':
			return setDeleteUserCandidateProducer(state, action.payload)
		case 'subscriptions/fetchPdfInfo':
			return fetchPdfInfoProducer(state, action.payload)
		default:
			return { ...state }
	}
}
