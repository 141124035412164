import type { Dispatch } from 'react'

export type AsyncDispatch<T> = Dispatch<T | Promise<T>>

export function wrapAsync<T>(dispatch: Dispatch<T>): AsyncDispatch<T> {
  return (action: T | Promise<T>) => {
    if (action instanceof Promise) {
      return action.then(dispatch)
    }
    return dispatch(action)
  }
}
