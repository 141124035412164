import { IconImage } from '@Components'
import { Stack, Typography, useTheme } from '@mui/material'
import Icon from '@Assets/emptyUsers.svg'

export function EmptyStub() {
	const theme = useTheme()

	return (
		<Stack borderTop={'1px solid #FFFFFF57'} py={5} alignItems='center'>
			<IconImage src={Icon} alt='icon' width='54px' />
			<Typography fontSize={24} color={theme.palette.text.secondary} fontFamily='GilroyBold'>
				User not found
			</Typography>
			<Typography
				fontSize={16}
				color={theme.palette.text.secondary}
				fontFamily='GilroyMedium'
				maxWidth={324}
				textAlign='center'
			>
				Change the search conditions or check that the user exists in the system.
			</Typography>
		</Stack>
	)
}
