import { Box, styled } from '@mui/material'


export const StyledCardBoxBG = styled(Box, { name: 'StyledCardBoxBG' })({
	background: 'transparent linear-gradient(146deg, #9945FF, #13F094) 0% 0% no-repeat padding-box',
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	zIndex: 1,
})
