import Feature1 from '@Assets/feature_1.png'
import Feature2 from '@Assets/feature_2.png'
import Feature3 from '@Assets/feature_3.png'
import Feature4 from '@Assets/feature_4.png'
import Feature5 from '@Assets/feature_5.png'
import Feature6 from '@Assets/feature_6.png'

import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { IconImage } from '@Components'
import { useHandlers } from './hooks'
import { DisplayEnum } from 'shared/enums/display.enum'
import { MoreFeaturesStack } from './MoreFeaturesStack'

export function MoreFeatures() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { handleSubscribe, handleContactUs } = useHandlers()

	return (
		<Stack
			width={xlDisplay ? '1180px' : '100%'}
			position='relative'
			mx='auto'
			textAlign='center'
			px={xlDisplay ? 13 : mobile ? 2 : 3}
			boxSizing='border-box'
		>
			<Typography
				fontSize={30}
				fontFamily='GilroyBold'
				variant='h3'
				color='rgba(30, 28, 48, 1)'
				mt='60px'
				mb='20px'
				zIndex={2}
			>
				More Features
			</Typography>
			<Stack
				direction={mobile ? 'column' : 'row'}
				justifyContent='space-between'
				alignItems={xlDisplay || mobile ? 'center' : 'start'}
				mb='30px'
				zIndex={2}
			>
				<Box width={mobile ? '100%' : '236px'} textAlign='center'>
					<IconImage src={Feature1} alt='img' width='128px'/>
					<Typography fontFamily='GilroyMedium' fontSize={18} color='rgba(0, 0, 0, 0.7)'>
						Storylines to engage students with learning
					</Typography>
				</Box>
				<Box width={mobile ? '100%' : '266px'} textAlign='center'>
					<IconImage src={Feature2} alt='img' width='128px'/>
					<Typography fontFamily='GilroyMedium' fontSize={18} color='rgba(0, 0, 0, 0.7)'>
						Earn collectables by progressing through our lessons
					</Typography>
				</Box>
				<Box width={mobile ? '100%' : '247px'} textAlign='center'>
					<IconImage src={Feature3} alt='img' width='128px'/>
					<Typography fontFamily='GilroyMedium' fontSize={18} color='rgba(0, 0, 0, 0.7)'>
						Interactive 3D animations to visualise complex concepts
					</Typography>
				</Box>
			</Stack>
			<Stack
				direction={mobile ? 'column' : 'row'}
				justifyContent='space-between'
				alignItems={xlDisplay || mobile ? 'center' : 'start'}
				mb={xlDisplay ? 8 : 3}
				zIndex={2}
			>
				<Box width={mobile ? '100%' : '236px'} textAlign='center'>
					<IconImage src={Feature4} alt='img' width='128px'/>
					<Typography fontFamily='GilroyMedium' fontSize={18} color='rgba(0, 0, 0, 0.7)'>
						Formative assessments with each lesson
					</Typography>
				</Box>
				<Box width={mobile ? '100%' : '281px'} textAlign='center'>
					<IconImage src={Feature5} alt='img' width='128px'/>
					<Typography fontFamily='GilroyMedium' fontSize={18} color='rgba(0, 0, 0, 0.7)'>
						Guided-learning through step by step support and personalised feedback throughout our lessons.
					</Typography>
				</Box>
				<Box width={mobile ? '100%' : '247px'} textAlign='center'>
					<IconImage src={Feature6} alt='img' width='128px' />
					<Typography fontFamily='GilroyMedium' fontSize={18} color='rgba(0, 0, 0, 0.7)'>
						Unlimited re-plays of simulations for optimal learning
					</Typography>
				</Box>
			</Stack>
			<MoreFeaturesStack></MoreFeaturesStack>
		</Stack>
	)
}
