import { IconImage } from '@Components'
import { Box, Link, Stack, Typography, useTheme } from '@mui/material'
import { StyledTableCell, StyledTableRow } from '../../../components/StyledTable'
import type { TrainingsReportingTableRow } from './typings'

type Props = {
	tableRow: TrainingsReportingTableRow
}

export function TrainingsReportingRow(props: Props) {
	const { tableRow } = props
	const theme = useTheme()

	return (
		<StyledTableRow key={tableRow.name}>
			<StyledTableCell scope='row'>
				<Link href={''} underline='none' display='contents'>
					<Stack direction='row' alignItems='center'>
						<Box borderRadius='6px' overflow='hidden' mr={1}>
							<IconImage src={tableRow.img} alt={tableRow.name} height='57px' width='80px' />
						</Box>
						<Stack>
							<Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.text.secondary}>
								{tableRow.name}
							</Typography>
						</Stack>
					</Stack>
				</Link>
			</StyledTableCell>
			<StyledTableCell align='left'>
				<Link href={''} underline='none' display='contents'>
					<Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.text.secondary}>
						{tableRow.active}
					</Typography>
				</Link >
			</StyledTableCell>
			<StyledTableCell align='left'>
				<Link href={''} underline='none' display='contents'>
					<Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.text.secondary}>
						{tableRow.inactive}
					</Typography>
				</Link >
			</StyledTableCell>
		</StyledTableRow>
	)
}
