import { Grid, Typography, useTheme } from '@mui/material'
import { StyledGrid } from './StyledGrid'

export function SubscriptionsTableHeader() {
	const theme = useTheme()

	return (
		<StyledGrid container width={'100%'}>
			<Grid item xs={2} px={3} py={2}>
				<Typography variant='body1' color={theme.palette.text.secondary} fontFamily='GilroyRegular'>
					Subscription
				</Typography>
			</Grid>
			<Grid item xs={2} px={3} py={2}>
				<Typography variant='body1' color={theme.palette.text.secondary} fontFamily='GilroyRegular'>
					Status:
				</Typography>
			</Grid>
			<Grid item xs={3} px={3} py={2}>
				<Typography variant='body1' color={theme.palette.text.secondary} fontFamily='GilroyRegular'>
					Period:
				</Typography>
			</Grid>
			<Grid item xs={2} px={3} py={2}>
				<Typography variant='body1' color={theme.palette.text.secondary} fontFamily='GilroyRegular'>
					Payment amount
				</Typography>
			</Grid>
			<Grid item xs={3} px={3} py={2}>
				<Typography variant='body1' color={theme.palette.text.secondary} fontFamily='GilroyRegular'>
					Payment date
				</Typography>
			</Grid>
		</StyledGrid>
	)
}
