import { Grid, Typography, useTheme } from '@mui/material'
import type { Payment } from '../../typings'
import { StyledGrid } from '../StyledGrid'

import { useData } from './hooks'

type Props = {
	payment: Payment
}

export function SubscriptionsTableRow(props: Props) {
	const { payment } = props
	const { name, period, price, payDate, currentSubscriptionStatus } = useData(payment)
	const theme = useTheme()

	return (
		<StyledGrid container width='100%'>
			<Grid item xs={2} px={3} py={2}>
				<Typography fontSize='18px' fontFamily='GilroySemiBold' color={theme.palette.text.primary}>
					{name}
				</Typography>
			</Grid>
			<Grid item xs={2} pl={3} py={2}>
				<Typography fontWeight={700} color={theme.palette.text.primary}>
					{currentSubscriptionStatus}
				</Typography>
			</Grid>
			<Grid item xs={3} px={3} py={2}>
				<Typography fontSize='18px' fontFamily='GilroyRegular' color={theme.palette.text.primary}>
					{period}
				</Typography>
			</Grid>
			<Grid item xs={2} px={3} py={2}>
				<Typography fontSize='18px' fontFamily='GilroyRegular' color={theme.palette.text.primary}>
					{price}
				</Typography>
			</Grid>
			<Grid item xs={3} px={3} py={2}>
				<Typography variant='h6' fontFamily='GilroyRegular' color={theme.palette.text.primary}>
					{payDate}
				</Typography>
			</Grid>
		</StyledGrid>
	)
}
