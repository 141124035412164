import { Footer, Header } from '@Components'
import { Stack, useTheme } from '@mui/material'
import { ReportingStackBG } from '../components'
import { ParticipantsReportsComponent } from './components'
import { useFetchParticipants } from './hooks/useFetchParticipants'

export function ParticipantsReportsPage() {
	const theme = useTheme()

	useFetchParticipants()

	return (
		<Stack bgcolor={theme.palette.background.default}>
			<ReportingStackBG>
				<Header hideNavigationMenu showReportingMenu />
				<ParticipantsReportsComponent />
				<Footer onMainPage={false} />
			</ReportingStackBG>
		</Stack>
	)
}
