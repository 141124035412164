import { Box, styled } from '@mui/material'

export const StyledPhysicsCard = styled(Box, { name: 'StyledPhysicsCard' })({
	width: '295px',
  cursor: 'pointer',
  textAlign: 'center',
  padding: '30px',
  boxSizing: 'border-box',
  '&:hover': {
    borderRadius: '30px',
		background: 'rgba(238, 128, 101, 0.2)',
	}
})

export const StyledBiologyCard = styled(Box, { name: 'StyledBiologyCard' })({
	width: '295px',
  cursor: 'pointer',
  textAlign: 'center',
  padding: '30px',
  boxSizing: 'border-box',
  '&:hover': {
    borderRadius: '30px',
		background: 'rgba(171, 206, 106, 0.2)',
	}
})

export const StyledChemistryCard = styled(Box, { name: 'StyledChemistryCard' })({
	width: '295px',
  cursor: 'pointer',
  textAlign: 'center',
  padding: '30px',
  boxSizing: 'border-box',
  '&:hover': {
    borderRadius: '30px',
		background: 'rgba(164, 131, 236, 0.2)',
	}
})