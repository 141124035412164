import { Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'
import { useButtonEnabled } from './hooks/useButtonEnabled'
import { Fragment } from 'react'

export function VerifyButton() {
	const { enabled, invalid } = useButtonEnabled()
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	return (
		<Fragment>
			<Stack mt={1.5} alignItems='flex-end' width='100%'>
				<Button variant='rounded_success' fullWidth type='submit' disabled={invalid}>
					<Typography color='primary' fontSize={mobile ? 14 : 16} fontFamily='GilroySemiBold'>
						VERIFY
					</Typography>
				</Button>
			</Stack>
		</Fragment>
	)
}
