import { Stack, styled } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'

export const CardBoxStack = styled(Stack, { name: 'CardBoxStack' })({
	minWidth: '506px',
	backgroundSize: 'cover',
	[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
		minWidth: '100%'
	}
})
