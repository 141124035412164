
import { styled, TableCell, TableRow } from '@mui/material'

export const StyledTableCell = styled(TableCell, { name: 'StyledTableCell' })({
	borderTop: '1px solid #FFFFFF57',
	borderBottom: '1px solid #FFFFFF57',
	opacity: 0.7,
	padding: '10px 20px', 
})
export const StyledTableRow = styled(TableRow, { name: 'StyledTableRow' })({
	borderBottom: '1px solid #FFFFFF57',
	'&:hover': {
		background: '#2B293C',
		'p' : {color: '#fff'},
	}
})

export const StyledTableCellRightBorder = styled(TableCell, { name: 'StyledTableCell' })({
	borderBottom: '1px solid #FFFFFF57',
	borderRight: '1px solid #FFFFFF57',
	opacity: 0.7,
	padding: '10px 20px', 
})