import { URLS } from '@Utils'
import { RequestService } from '@Services'
import type { FetchIntentBody } from '../typings'

export async function fetchPaymentSecretEffect(
	body: FetchIntentBody,
): Promise<{ action?: any; status: 'success' | 'error'; error?: string; data?: any }> {
	try {
		const response = await RequestService.post<FetchIntentBody, any>(URLS.fetchPaymentSession, body, true)

		if (response.status === 200) {
			return { status: 'success', data: response.data }
		}

		return { status: 'error' }
	} catch (err: any) {
		return {
			status: 'error',
			error: Object.entries(err.response.data.errors || {})[0][1] as string,
		}
	}
}
