import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'
import { DetailedBoxStack } from './DetailedBoxStack'
import { useSubscriptionData } from './hooks/useSubscriptionData'

export function DetailedBox() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const data = useSubscriptionData()

	return (
		<DetailedBoxStack
			p={3}
			borderRadius='10px'
			boxSizing='border-box'
			boxShadow='0px 24px 40px #00000057'
			width={xlDisplay ? 400 : '100%'}
			height={mobile ? '100%' : ''}
		>
			<Typography fontSize={mobile ? 22 : 28} color={theme.palette.text.primary} fontFamily='GilroyBold' textAlign='center'>
				{data.title}
			</Typography>
			<Typography
				fontSize={mobile ? 14 : 16}
				color={theme.palette.text.secondary}
				mt={1}
				fontFamily='GilroyRegular'
				textAlign='center'
			>
				{data.description}
			</Typography>
			<Stack mt={1} direction='column'>
				<Stack>
					<Stack mx='auto' direction='row' alignItems='baseline'>
						<Typography fontSize={26} mr={0.5} fontFamily='GilroyBold' color={theme.palette.text.primary}>
							£
						</Typography>
						<Typography fontSize={100} fontFamily='GilroyBold' color={theme.palette.text.primary}>
							{data.price * 12}
						</Typography>
						<Typography fontSize={26} ml={0.5} fontFamily='GilroyBold' color={theme.palette.text.primary}>
							GBP
						</Typography>
					</Stack>
					<Typography
						fontSize={15}
						mt={-2.8}
						fontFamily='GilroySemiBold'
						color={theme.palette.text.secondary}
						textAlign='center'
					>
						ANNUAL PAYMENT
					</Typography>
					<Typography
						fontSize={mobile ? 14 : 15}
						mt={3}
						fontFamily='GilroySemiBold'
						color={theme.palette.text.primary}
						textAlign='center'
					>
						PRICE DETAILS
					</Typography>
					<Typography
						fontSize={mobile ? 22 : 26}
						fontFamily='GilroyBold'
						color={mobile ? theme.palette.text.primary : theme.palette.text.secondary}
						textAlign='center'
					>
						£{data.price} GBP * 12 MONTHS
					</Typography>
				</Stack>
			</Stack>
		</DetailedBoxStack>
	)
}
