import { TrainingTypesEnum } from './../TrainingsReports/enums/trainingTypes.enum'
import type { ReportingState } from './typings'
import { TrainingStatusesEnum } from '../TrainingsReports/enums/trainingStatuses.enum'
import { TrainingsFilters } from '../TrainingsReports/typings'
import { ParticipantTypesEnum } from '../ParticipantsReports/enum'
import { ParticipantsFilters } from '../ParticipantsReports/typings'

export const initialStatuses = {
	[TrainingStatusesEnum.All]: 0,
	[TrainingStatusesEnum.NotStarted]: 0,
	[TrainingStatusesEnum.InProgress]: 0,
	[TrainingStatusesEnum.Completed]: 0,
	[TrainingStatusesEnum.Users]: 0,
}

export const initialParticipantTypes = {
	[ParticipantTypesEnum.All]: 0,
	[ParticipantTypesEnum.Active]: 0,
	[ParticipantTypesEnum.Inactive]: 0,
}

export const initialTrainingsFilters: TrainingsFilters = {
	type: TrainingTypesEnum.All,
	name: '',
	page: 1,
	limit: 10,
	sorting: 'asc',
}

export const initialParticipantsFilters: ParticipantsFilters = {
	page: 1,
	name: '',
	sorting: 'asc',
	limit: 10,
}

export const initialState: ReportingState = {
	statuses: initialStatuses,
	trainings: { total: 0, items: [] },
	trainingsFilters: initialTrainingsFilters,
	participantsTypes: initialParticipantTypes,
	participants: { total: 0, items: [] },
	participantsFilters: initialParticipantsFilters,
}
