import { Box, Link, Stack, Typography, useTheme } from "@mui/material"

export function TrainingsPageHeader() {
    const theme = useTheme()

    return (
        <Stack mt={6} ml={2} >
            <Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.info.main}>
                STATISTIC
            </Typography>
            <Stack direction='row' columnGap={6}>
                <Box borderBottom='3px solid' borderColor={theme.palette.primary.main}>
                    <Typography fontFamily='GilroyBold' mb={3} fontSize={48} color={theme.palette.primary.main}>
                        Trainings
                    </Typography>
                </Box>
                <Link href='/reporting/participants' underline="none">
                    <Typography fontFamily='GilroyBold' fontSize={48} color={theme.palette.text.disabled}>
                        Participants
                    </Typography>
                </Link>
            </Stack>
        </Stack>
    )
}