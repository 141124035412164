import type { ParticipantsFilters, ParticipantTableRow } from '../ParticipantsReports/typings'
import { RequestService } from '@Services'
import { URLS } from '@Utils'
import { fetchParticipantsActionCreator } from '../state/Actions/FetchParticipants'

export async function fetchParticipants(filters: ParticipantsFilters) {
	const preparedFilters = Object.fromEntries(
		Object.entries(filters).filter((entry) => !!entry[1]),
	) as ParticipantsFilters

	try {
		const response = await RequestService.get<ParticipantsFilters, { total: number; items: ParticipantTableRow[] }>(
			URLS.participants,
			preparedFilters,
			true,
		)

		if (response.status === 200) {
			return { status: 'success', action: fetchParticipantsActionCreator(response.data) }
		}

		return { status: 'error', action: fetchParticipantsActionCreator({ total: 0, items: [] }) }
	} catch (err) {
		return { status: 'error', action: fetchParticipantsActionCreator({ total: 0, items: [] }) }
	}
}
