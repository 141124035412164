import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

export function useGoToHomePage(path: string = '/') {
	const navigate = useNavigate()

	return useCallback(() => {
		navigate(path)
	}, [navigate])
}
