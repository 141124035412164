import { Stack, styled } from '@mui/material'
import Background from '@Assets/buble3.svg'

export const Buble3 = styled(Stack, { name: 'Buble3' })({
	backgroundImage: `url('${Background}')`,
	backgroundSize: '100% 100%',
	position: 'absolute',
  width: '1000px',
  height: '1000px'
})
