import { Footer } from '@Components'
import { Stack, useTheme } from '@mui/material'
import { MissingPageComponent } from './components'

export function MissingPage() {
	const theme = useTheme()
	
	return (
		<Stack bgcolor={theme.palette.background.default}>
				<MissingPageComponent />
				<Footer onMainPage={false}/>
			
		</Stack>
	)
}
