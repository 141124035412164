import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Footer, Header } from '@Components'
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove'
import { useHandlers } from './hooks'
import { useFetchSubscriptions } from '../hooks'
import { SubscriptionsPageStack } from '../components'
import { useEffect } from 'react'
import { DisplayEnum } from 'shared/enums/display.enum'
import { StyledStackBG } from './components'

export function UnsubscribePage() {
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { handleCancelClick, handleUnsubscribeClick } = useHandlers()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useFetchSubscriptions()

	return (
		<Stack bgcolor={theme.palette.background.default}>
			<SubscriptionsPageStack alignItems='center'>
				<Header hideNavigationMenu />
				<Stack width={mobile ? '100%' : 564} alignItems='center' px={mobile ? 3 : 0} boxSizing={'border-box'}>
					<Stack alignItems='center' flex={1} mb={mobile ? 3 : 7.5}>
						<Typography variant='h6' fontSize='18px' fontFamily='GilroySemiBold' mt={8} color={theme.palette.info.main}>
							PERSONAL SPACE
						</Typography>
						<Typography fontSize={mobile ? 30 : 48} fontFamily='GilroyBold' textAlign='center' color={theme.palette.text.primary} mt={1}>
							Manage Subscription
						</Typography>
					</Stack>

					<StyledStackBG
						mt={mobile ? 0 : 6}
						borderRadius='16px'
						overflow='hidden'
						p={mobile ? 3 : 6}
						alignItems='center'
						boxSizing='border-box'
						width={mobile ? '100%' : 564}
					>
						<Typography color={theme.palette.text.primary} mb={mobile ? -1 : 4} fontSize={mobile ? '50px' : '90px'}>
							<PlaylistRemoveIcon fontSize={'inherit'} />
						</Typography>
						<Typography fontFamily='GilroyBold' color={theme.palette.text.primary} variant='h4' mb={1.5} textAlign='center' fontSize={mobile ? 20 : 28}>
							Are you sure you want to unsubscribe?
						</Typography>
					</StyledStackBG>

					<Box width={mobile ? '100%' : 434} mb={mobile ? 2.5 : 3.5} mt={mobile ? 1 : 6}>
						<Button variant='rounded_contained__alert' fullWidth onClick={handleUnsubscribeClick}>
							UNSUBSCRIBE
						</Button>
					</Box>

					<Box mb={mobile ? 0 : 13}>
						<Button variant='text' onClick={handleCancelClick} color='secondary'>
							RETURN ON PREVIOUS STEP AND STAY SUBSCRIBED
						</Button>
					</Box>
				</Stack>
			</SubscriptionsPageStack>
			<Footer onMainPage={false} />
		</Stack>
	)
}
