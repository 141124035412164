import type { ReportingState, Actions } from './typings'
import { initialState } from './initialState'
import {
	fetchParticipantsProducer,
	fetchParticipantTypesProducer,
	fetchTrainingsProducer,
	fetchTrainingsStatusesReportingProducer,
	setParticipantsFilterProducer,
	setTrainingsFilterProducer,
} from './Actions'

export function reducer(state: ReportingState = initialState, action: Actions): ReportingState {
	switch (action.type) {
		case 'reporting/fetchTrainings':
			return fetchTrainingsProducer(state, action.payload)
		case 'reporting/FetchTrainingsStatuses':
			return fetchTrainingsStatusesReportingProducer(state, action.payload)
		case 'reporting/setTrainingsFilter':
			return setTrainingsFilterProducer(state, action.payload)
		case 'reporting/fetchParticipants':
			return fetchParticipantsProducer(state, action.payload)
		case 'reporting/fetchParticipantTypes':
			return fetchParticipantTypesProducer(state, action.payload)
		case 'reporting/setParticipantsFilter':
			return setParticipantsFilterProducer(state, action.payload)
		default:
			return { ...state }
	}
}
