import { useMemo } from 'react'
import { RolesEnum } from '@Enums'
import { RolesService } from '../../../../../shared/services/roles.service'

export function useCanDelete(userRowRole: RolesEnum) {
	return useMemo(
		() =>
			RolesService.availableForRole([RolesEnum.SuperAdmin]) ||
			((userRowRole === RolesEnum.Admin ||
				userRowRole === RolesEnum.Guest ||
				userRowRole === RolesEnum.User ||
				userRowRole === RolesEnum.SuperUser) &&
				RolesService.availableForRole([RolesEnum.Admin])),
		[userRowRole],
	)
}
