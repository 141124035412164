import { Field, useFormikContext } from 'formik'
import { TextField } from '@Components'
import { Box, Typography } from '@mui/material'
import type { DownloadInvoiceContext } from '../DownloadInvoiceDialog'

export function CompanyNameField() {
	const { errors, touched, values } = useFormikContext<DownloadInvoiceContext>()

	return (
		<Box position='relative' pb={2} mb={1}>
			<Field
				id='companyName'
				name='companyName'
				label='NAME OF COMPANY'
				placeholder=''
				component={TextField}
				defaultValue={values.companyName}
			/>
			{errors.companyName && touched.companyName ? (
				<Typography variant='caption' color='red' position='absolute' left={0} bottom={0}>
					{errors.companyName}
				</Typography>
			) : null}
		</Box>
	)
}
