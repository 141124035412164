import { styled, Box } from '@mui/material'

export const StyledOverviewMenuItem = styled(Box, { name: 'StyledOverviewMenuItem' })({
    background: 'rgba(189, 182, 255, .3)',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '13px 20px',
    cursor: 'pointer',
})
