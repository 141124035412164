import { Box } from '@mui/material'
import { Description, Pricing } from './components'
import { Footer } from '@Components'
import { HeaderHomePage } from './components/HeaderHomePage'
import { HowItWorks } from './components/HowItWorksComponent'
import { MoreFeatures } from './components/MoreFeatureComponent'
import { JoinCommunity } from './components/JoinCommunityComponent'
import { ContactUsHome } from './components/ContactUsHome'
import { ScrollToTop } from './components/ScrollToTop'

export function HomePageComponent() {

	return (
		<Box position='relative' bgcolor={'#F4F4F3'} overflow='hidden'>
			<HeaderHomePage />
			<Description />
			<HowItWorks />
			<MoreFeatures />
			<Pricing />
			<JoinCommunity />
			<ContactUsHome />
			<Footer showAdditionalInfo={true} onMainPage={true} />
			<ScrollToTop />
		</Box>
	)
}

export default HomePageComponent
