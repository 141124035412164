import { useTrainingsContext } from '../../context'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useToken } from '@Hooks'

export function useData() {
	const { id } = useParams<{ id: string }>()
	const user = useToken()
	const [
		{
			trainingsInfo: { trainings },
		},
	] = useTrainingsContext()

	const training = useMemo(() => trainings.find((training) => training.id === id), [id, trainings])
	const canGenerateCode = user.withOculus

	return {
		training,
		canGenerateCode,
		withOculus: user.withOculus,
	}
}
