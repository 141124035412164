import { IconImage } from '@Components'
import { Link, Stack, Typography, useTheme } from '@mui/material'
import { StyledTableCell, StyledTableRow } from '../StyledTable'
import type { ReportingTableRow } from './typings'

type Props = {
	tableRow: ReportingTableRow
}

export function ReportingRow(props: Props) {
	const { tableRow } = props
	const theme = useTheme()

	return (

		<StyledTableRow key={tableRow.name}>
			<StyledTableCell scope='row'>
				<Link href={''} underline='none' display='contents'>
					<Stack direction='row' alignItems='left'>
						{tableRow.trainingImage && (
							<IconImage src={tableRow.trainingImage} alt={tableRow.name} height='57px' width='80px' />
						)}
						<Stack>
							<Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.text.secondary}>
								{tableRow.name}
							</Typography>
							{tableRow.email && (
								<Typography fontFamily='GilroyMedium' fontSize={14} color={theme.palette.text.secondary}>
									{tableRow.email}
								</Typography>
							)}
						</Stack>
					</Stack>
				</Link>
			</StyledTableCell>
			{Object.values(tableRow)
				.slice(3)
				.map((tableCell) => (
					<StyledTableCell align='left'>
						<Link href={''} underline='none' display='contents'>
							<Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.text.secondary}>
								{tableCell}
							</Typography>
						</Link>
					</StyledTableCell>
				))}
		</StyledTableRow>

	)
}
