import type { SubscriptionUser } from '../../../typings'
import type { State } from '../../typings'

export const createSubscriptionUserProducer = (state: State, payload?: SubscriptionUser): State => {
	if (state.subscriptionUsers.length >= 10 && payload) state.subscriptionUsers.pop()

	return {
		...state,
		subscriptionUsers: payload ? [payload, ...state.subscriptionUsers] : [...state.subscriptionUsers],
	}
}
