import { ParticipantTableRow } from '../../../ParticipantsReports/typings'
import { FetchParticipants } from './typings'

export const fetchParticipantsActionCreator = (payload: {
	total: number
	items: ParticipantTableRow[]
}): FetchParticipants => ({
	type: 'reporting/fetchParticipants',
	payload,
})
