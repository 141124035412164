import { useSharedContext } from '@Context'

export function useData() {
	const [{ user }] = useSharedContext()

	const description = user
		? 'Go to your account - to view and manage your subscription. Adding users and so on.'
		: 'Continue the registration process - you have received an email with a link to the registration process - or you can use the link:'

	const buttonLabel = user ? 'MANAGE SUBSCRIPTION' : 'CONTINUE REGISTRATION'

	return {
		buttonLabel,
		description,
	}
}
