import { Field, useFormikContext } from 'formik'
import { TextField } from '@Components'
import { Box, Typography } from '@mui/material'
import type { DownloadInvoiceContext } from '../DownloadInvoiceDialog'

export function ZipcodeField() {
	const { errors, touched, values } = useFormikContext<DownloadInvoiceContext>()

	return (
		<Box flex={1} position='relative' pb={4}>
			<Field
				id='zipcode'
				name='zipcode'
				label='ZIP CODE'
				type='text'
				placeholder=''
				component={TextField}
				defaultValue={values.zipcode}
			/>
			{errors.zipcode && touched.zipcode ? (
				<Typography variant='caption' color='red' position='absolute' left={0} bottom={0}>
					{errors.zipcode}
				</Typography>
			) : null}
		</Box>
	)
}
